import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const FilterModal = () => {
  const [promise, setPromise] = useState(null);
   const [status, setStatus] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [kyc, setKyc] = useState([]);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve({
       status: status,
      subscription: subscription,
      kyc: kyc,
    });
    handleClose();
  };

 
  const handleStatus = (element) => {
    const arr = toggleElement(status, element);
    setStatus(arr);
  };
  const handleSubscription = (element) => {
    const arr = toggleElement(subscription, element);
    setSubscription(arr);
  };
  const handleKyc = (element) => {
    const arr = toggleElement(kyc, element);
    setKyc(arr);
  };
  function toggleElement(array, element) {
    const index = array.indexOf(element);

    if (index === -1) {
      // Element is not present, so insert it
      array.push(element);
    } else {
      // Element is present, so remove it
      array.splice(index, 1);
    }

    return [...array]; // Make sure to return a new array to trigger re-render
  }

  const ConfirmationDialog = () => (
    <Dialog open={promise !== null} onClose={handleClose} fullWidth>
      <DialogTitle style={{ fontWeight: "700" }}>Apply Filters</DialogTitle>
      <DialogContent>
       
        <div style={{ marginTop: 10 }}>
          <h6>User Status</h6>
          <div style={{ display: "flex", fontSize: 15 }}>
            <div
              style={{
                backgroundColor: status.includes("1") ? "black" : "white",
                color: status.includes("1") ? "white" : "black",
                borderRadius: 10,
                border: status.includes("1")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={() => handleStatus("1")}
            >
              Active
            </div>
            <div
              style={{
                backgroundColor: status.includes("0") ? "black" : "white",
                color: status.includes("0") ? "white" : "black",
                borderRadius: 10,
                border: status.includes("0")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                cursor: "pointer",
              }}
              onClick={() => handleStatus("0")}
            >
              Blocked
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6>User Subscription</h6>
          <div style={{ display: "flex", fontSize: 15 }}>
            <div
              style={{
                backgroundColor: subscription.includes("1")
                  ? "black"
                  : "white",
                color: subscription.includes("1") ? "white" : "black",
                borderRadius: 10,
                border: subscription.includes("1")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={() => handleSubscription("1")}
            >
              Active
            </div>
            <div
              style={{
                backgroundColor: subscription.includes("0")
                  ? "black"
                  : "white",
                color: subscription.includes("0") ? "white" : "black",
                borderRadius: 10,
                border: subscription.includes("0")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                cursor: "pointer",
              }}
              onClick={() => handleSubscription("0")}
            >
              Expired
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6>User KYC</h6>
          <div style={{ display: "flex", fontSize: 15 }}>
            <div
              style={{
                backgroundColor: kyc.includes("1") ? "black" : "white",
                color: kyc.includes("1") ? "white" : "black",
                borderRadius: 10,
                border: kyc.includes("1")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={() => handleKyc("1")}
            >
              Verified
            </div>
            <div
              style={{
                backgroundColor: kyc.includes("0")
                  ? "black"
                  : "white",
                color: kyc.includes("0") ? "white" : "black",
                borderRadius: 10,
                border: kyc.includes("0")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                cursor: "pointer",
              }}
              onClick={() => handleKyc("0")}
            >
              Not Verified
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Apply</Button>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};

export default FilterModal;
