// NotFoundPage.js

import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.animationContainer}>
          <div style={styles.animationCircle}></div>
        </div>
        <div style={styles.icon}>🚀</div>
        <div style={styles.title}>404 Not Found</div>
        <div style={styles.description}>Oops! The page you are looking for could not be found.</div>
        <Link to="/" style={styles.link}>Go back to homepage</Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    fontSize: '3rem',
    marginBottom: '20px',
  },
  description: {
    fontSize: '1.2rem',
    marginBottom: '20px',
  },
  icon: {
    fontSize: '6rem',
    marginBottom: '20px',
  },
  animationContainer: {
    position: 'relative',
    width: '150px',
    height: '150px',
  },
  animationCircle: {
    position: 'absolute',
    border: '8px solid transparent',
    borderTopColor: '#ff5722',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  link: {
    textDecoration: 'none',
    color: '#ff5722',
  },
};

export default NotFoundPage;
