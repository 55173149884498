import React, { useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import ErrorImage from "../../../src/assets/images/error.png"; // Path to your image file
import useConfirm from "../AlertDialog";
import { FaRegEdit } from "react-icons/fa";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoFilter } from "react-icons/io5";
import leadsContext from "../../context/LeadsContext/LeadsContext";
import Pagination from "../.././components/Pagination";
import FilterModal from "./FiltersModal";

import { CiSearch } from "react-icons/ci";
import { AiFillDelete, AiOutlineStock } from "react-icons/ai";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";

const AllLeads = () => {
  const [showLead, setShowLead] = useState(null);

  const handleShowLead = (lead) => {
    console.log(lead.id);
    setShowLead(lead);

    // You can add logic here to fetch data for the selected page
  };
  const followups = [
    {
      id: 1,
      name: "Sidhartha",
      message:
        "Looking for 3 bhk apartment in hoskote. Asked to follow up on weeekend. Might be agree to visit this weekend.",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 2,
      name: "Kiran",
      message:
        "Also looking in south bengaluru. Cant come for site visit this weekend(25 or 26 feb). Asked to call again",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 3,
      name: "Amogh",
      message: "Not picking up call. Called 3 times today",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 4,
      name: "Sekhar",
      message: "Not respoding to call",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 5,
      name: "Kishore",
      message:
        "Did not respond to call in morning 10am but Picked up call 4pm and agreed for site visits. ALso pitched for SOBHA GREEN ACRE PHASE 2 WHICH IS 3 CR",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 6,
      name: "Tejas",
      message:
        "Made a check up/confirmation call. Coming for site visits today(2/3/24)",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 7,
      name: "Mahanta",
      message:
        "Did not like any project show far after site visits. Asking if there is any other projects avilable or future projects. Might be a futur lead/client",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 8,
      name: "Mahanta",
      message:
        "Did not like any project show far after site visits. Asking if there is any other projects avilable or future projects. Might be a futur lead/client",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 9,
      name: "Mahanta",
      message:
        "Did not like any project show far after site visits. Asking if there is any other projects avilable or future projects. Might be a futur lead/client",
      date: "21-02-2024 11:50AM",
    },
    {
      id: 10,
      name: "Mahanta",
      message:
        "Did not like any project show far after site visits. Asking if there is any other projects avilable or future projects. Might be a futur lead/client",
      date: "21-02-2024 11:50AM",
    },
  ];

  const handleStatusChange = async (event, index) => {
    // console.log(checked);
    // setUserStatus(event.target.checked);

    const stat = await UpdateLeadStatus({
      id: leads[index].id,
      status: leads[index].status === 1 ? 2 : 1,
    });
    if (stat) {
      // window.location.reload();
      //  console.log(stat);
    }
  };

  const [checked, setChecked] = useState([]);
  const [query, setQuery] = useState("");

  const [filters, setFilters] = useState(null);

  // let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(leadsContext);
  const {
    leads,
    totalLeads,
    statistics,

    getLeads,
    getLeadStatistics,
    getSearchLeads,
    deleteLeads,
    UpdateLeadStatus,
  } = context;

  const [FilterDialog, ApplyFilters] = FilterModal();

  const handleFilters = async () => {
    const filtersx = await ApplyFilters();
    if (filtersx === null) {
    } else {
      setCurrentPage(1);
      getSearchLeads({
        page: 1,
        lead_type: filtersx.lead_type,
        status: filtersx.status,

        query_string: query,
      });
      setFilters({
        lead_type: filtersx.lead_type,
        status: filtersx.status,
      });
      // console.log(filters);
    }
  };
  
  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Leads!",
    `Are you sure you want to delete ${
      checked.length > 1 ? "these Leads" : "this user"
    } : ${checked}? `
  );


  const handleDelete = async () => {
    const ans = await confirmDelete();
    if (ans) {
      handleDeleteUsers();
    } else {
      /* ... */
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (localStorage.getItem("token")) {
      query.length > 0 || filters !== null
        ? getSearchLeads({
            page: newPage,
            lead_type: filters !== null ? filters.lead_type : [],
            status: filters !== null ? filters.status : [],

            query_string: query,
          })
        : getLeads({ page: newPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const handleDeleteUsers = async () => {
    if (localStorage.getItem("token")) {
      await deleteLeads({ ids: checked, page: currentPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const assetData = [
    {
      key: "Total Leads in DB",
      value: statistics !== null ? statistics.total_leads : 0,
    },
    {
      key: "New Home Leads",
      value: statistics !== null ? statistics.new_home : 0,
    },
    {
      key: "Home Loan Load",
      value: statistics !== null ? statistics.home_loan : 0,
    },
    {
      key: "Resale/Rental Leads",
      value: statistics !== null ? statistics.resale_rental : 0,
    },
    {
      key: "Interiors Leads",
      value: statistics !== null ? statistics.interior : 0,
    },
    {
      key: "Construction Leads",
      value: statistics !== null ? statistics.construction : 0,
    },
  ];

  const logData = [
    {
      key: "Total Active leads",
      value: statistics !== null ? statistics.active_leads : 0,
    },
    {
      key: "Total Closed leads",
      value: statistics !== null ? statistics.closed_leads : 0,
    },
  ];

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    //console.log(updatedList);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);

    getSearchLeads({
      page: currentPage,
      lead_type: filters !== null ? filters.lead_type : [],
      status: filters !== null ? filters.status : [],

      query_string: query,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getLeads({ page: currentPage });
      getLeadStatistics();
    } else {
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            margin: 0,
            maxWidth: "100%",
          }}
        >
          <ToastContainer position="top-center" />
          {/* <Modal isOpen={isOpen} onClose={closeModal} user={selectedUser} /> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 15,
            }}
          >
            <div
              style={{
                fontSize: 18,
              }}
            >
              All Leads({totalLeads})
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <form
                className="form_search"
                onSubmit={handleSubmit}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  className="serach_asset"
                  variant="outlined"
                  placeholder="Search leads..."
                  value={query}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton type="submit" edge="start">
                        <CiSearch />
                      </IconButton>
                    ),
                    style: {
                      height: "32px",
                      minWidth: "30vw", // Adjust the height as needed
                      flexGrow: 1, // Allow the input to grow to fill available space
                    },
                  }}
                  fullWidth
                />
              </form>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  //disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleFilters}
                >
                  <IoFilter color="grey" />
                </IconButton>
                <FilterDialog />
              </div>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  marginRight: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleDelete}
                >
                  <AiFillDelete color={checked.length === 0 ? "grey" : "red"} />
                </IconButton>
                <Dialog />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 15,
              fontFamily: "monospace",
              fontWeight: 550,
              textAlign: "left",
              maxWidth: "100%",
              paddingTop: 10,
              paddingLeft: 20,
              color: "grey",
              alignItems: "center",
              justifySelf: "start",
              height: 35,
            }}
          >
            <div
              style={{
                width: "3vw",
                height: 50,
                fontWeight: 600,
                marginLeft: 15,
               // alignContent: "center",
                // backgroundColor:"black"
              }}
            >
              ID
            </div>
            <div
              style={{
                width: "9vw",
                height: 50,
                maxLines: 1,
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "10vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Email
            </div>
            <div
              style={{
                width: "6vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Phone
            </div>

            <div
              style={{
                width: "12vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Message
            </div>

            <div
              style={{
                width: "7vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Lead Type
            </div>
            <div
              style={{
                width: "4vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Status
            </div>
            <div
              style={{
                width: "5vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Action
            </div>
          </div>
          <div
            style={{
              minHeight: "78vh",
              // justifyContent: "center",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
            }}
          >
            {leads.length > 0 ? (
              leads.map((item, index) => (
                <div
                  key={item.id}
                  // onClick={() => handleShowLead(item)}
                  style={{
                    maxWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                    backgroundColor: "transparent",
                    fontWeight: 530,
                    textAlign: "left",
                    fontFamily: "Montserrat",
                    fontSize: 12,

                    maxLines: 1,
                    // backgroundColor: "black",
                    // borderRadius: 10 /* Adjust border radius as needed */,
                    borderBottom: ".5px solid #d8d0d0",
                    // paddingTop: 10,
                    marginTop: 5,
                  }}
                >
                  <input
                    value={item.id}
                    type="checkbox"
                    onChange={handleCheck}
                    style={{
                      alignContent: "start",
                      backgroundColor: "#000",
                      marginLeft: "10px",
                      // marginBottom: "30px",
                    }}
                  />

                  <div
                    style={{
                      width: "3vw",
                      height: 50,
                      fontWeight: 600,
                      marginLeft: 10,
                      alignContent: "center",
                      // /paddingTop: "15px",
                    }}
                  >
                    {item.id}
                  </div>

                  <div
                    style={{
                      width: "9vw",
                      height: 50,
                      //  marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.name}
                  </div>

                  <div
                    style={{
                      width: "10vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.email}
                  </div>

                  <div
                    style={{
                      width: "6vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    (+91) {item.phone}
                  </div>

                  <div
                    style={{
                      width: "12vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.message}
                  </div>

                  <div
                    style={{
                      width: "7vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.lead_type === 1
                      ? "New Home"
                      : item.lead_type === 2
                      ? "Home Loan"
                      : item.lead_type === 3
                      ? "Resale/Rental"
                      : item.lead_type === 4
                      ? "Interior"
                      : item.lead_type === 5
                      ? "Construction"
                      : ""}
                  </div>
                  <div
                    style={{
                      width: "4vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      paddingTop: "15px",

                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    <Switch
                      onChange={(e) => handleStatusChange(e, index)}
                      checked={item.status === 2 ? false : true}
                    />
                    {/* {item.is_manager === 1 ? "Yes" : "No"} */}
                  </div>
                  <div
                    style={{
                      width: "5vw",
                      height: 50,
                      marginRight: 10,
                      marginLeft: 15,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      //backgroundColor: "white",
                      // overflow: "hidden",
                    }}
                  >
                    <IconButton
                      // onClick={() => openModal(users[index])}
                      size="small"
                      type="submit"
                      edge="start"
                    >
                      <FaRegEdit />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  marginTop: "20vh",
                  justifyContent: "center",
                  //backgroundColor: "black",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    marginTop: 15,
                    width: "10vw",
                  }}
                  alt=""
                  src={ErrorImage}
                />
                <div
                  style={{
                    marginTop: 30,
                    fontSize: 18,

                    fontFamily: "Montserrat",
                  }}
                >
                  Zero record not found in database
                </div>
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 15,

                    fontFamily: "Montserrat",
                  }}
                >
                  Try a different query or filter
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div
              style={{
                marginTop: 15,
                marginLeft: 15,
                marginBottom: 10,
              }}
            >
              Showing {leads.length} out of {totalLeads}
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalLeads / 12)}
                onPageChange={(e) => handlePageChange(e)}
              />
            </div>
          </div>
        </div>

        {showLead === null ? (
          <div>
            <div>
              <Card
                style={{
                  minWidth: "20vw",
                  maxWidth: "22vw",
                  margin: "auto",
                  marginTop: 10,
                  marginLeft: 15,
                  backgroundColor: "#00073D",
                  borderRadius: 15,
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ color: "white", fontWeight: 600 }}
                    >
                      Lead Statistics
                    </Typography>
                    <IconButton aria-label="edit">
                      <AiOutlineStock color="white" />
                    </IconButton>
                  </div>
                  {assetData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                        color: "white",
                      }}
                    >
                      <Typography>{item.key}</Typography>
                      <Typography>{item.value}</Typography>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </div>
            <div>
              <Card
                style={{
                  minWidth: "20vw",
                  maxWidth: "22vw",
                  margin: "auto",
                  marginTop: 10,
                  marginLeft: 15,
                  backgroundColor: "#00073D",
                  borderRadius: 15,
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ color: "white", fontWeight: 600 }}
                    >
                      Lead Status
                    </Typography>
                  </div>
                  {logData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                        color: "white",
                      }}
                    >
                      <Typography>{item.key}</Typography>
                      <Typography>{item.value}</Typography>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </div>
            {/* <div style={{  marginTop:10, marginLeft:10,  textAlign: 'center' }}>
            <img src={CyberImage} alt="banner" style={{ margin: 'auto',  width:"250px", height: '360px' }} />
          </div> */}
          </div>
        ) : (
          <div>
            <div>
              <Card
                style={{
                  minWidth: "20vw",
                  maxWidth: "22vw",
                  margin: "auto",
                  marginTop: 10,
                  marginLeft: 15,
                  backgroundColor: "#00073D",
                  borderRadius: 15,
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ color: "white", fontWeight: 600 }}
                    >
                      ID: #{showLead.id}
                    </Typography>
                    <Typography
                      variant="h7"
                      style={{ color: "white", fontWeight: 600 }}
                    >
                      {showLead.type}
                    </Typography>
                  </div>
                  <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Total Follow-up</Typography>
                    <Typography>5</Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card
                style={{
                  minWidth: "20vw",
                  maxWidth: "22vw",
                  maxHeight: "82vh",
                  margin: "auto",
                  marginTop: 10,
                  marginLeft: 15,
                  backgroundColor: "#00073D",
                  borderRadius: 15,
                }}
              >
                <CardContent>
                  <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="h7"
                      style={{ color: "white", fontWeight: 600 }}
                    >
                      Showing all 5 followups
                    </Typography>
                  </div>
                  <div
                    style={{
                      height: "82vh",
                      overflowY: "scroll",
                      marginBottom: 10,
                    }}
                  >
                    {followups.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          // display: "flex",
                          justifyContent: "space-between",
                          marginTop: 10,
                          marginBottom:
                            index === followups.length - 1 ? 60 : 10,
                          color: "white",
                        }}
                      >
                        <Typography
                          variant="h7"
                          style={{ color: "white", fontWeight: 600 }}
                        >
                          {item.name}
                        </Typography>
                        <Typography>{item.message}</Typography>
                        <Typography variant="h9">{item.date}</Typography>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllLeads;
