import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Card } from "@mui/material";

const DonutChart = (props) => {
  const chartRef = useRef(null);
  const { title, value, data, colors, labels } = props.data;

  const datax = {
    //  labels:   labels,
    datasets: [
      {
        label: title,
        data: data,
        backgroundColor: colors,
        borderRadius: 5,
      },
    ],
  };

  const optionsx = {
    // Specify options as needed
  };
  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      new Chart(ctx, {
        type: "doughnut",
        data: datax,
        options: optionsx,
      });
    }
  }, []);

  return (
    <Card
      elevation={5}
      style={{
        borderRadius: 20,
      }}
    >
      <div
        style={{
          borderRadius: 20,
          border: "1px solid #6F6F6F33",
          backgroundColor: "white",
          minWidth: "20vw",
          maxWidth: "20vw",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Montserrat",
          fontWeight: 600,
          padding: 15,
        }}
      >
        <label style={{ fontSize: 20 }}>{title}</label>
        <label style={{ fontSize: 25, fontWeight: 700 }}>{value}</label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            maxHeight: "20vh",
            alignItems: "center",
          }}
        >
          <div>
            {labels.map((item, index) => (
              <div
                key={item}
                // onClick={() => handleShowLead(item)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  // fontWeight: 530,
                  textAlign: "left",
                  fontFamily: "sans-serif",
                  fontSize: 15,
                }}
              >
                <div
                  style={{
                    width: 20,
                    height: 10,
                    backgroundColor: colors[index],
                  }}
                ></div>
                <div
                  style={{
                    width: "5vw",
                    maxLines: 1,
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    // backgroundColor: "black",
                    overflow: "hidden",
                    marginLeft: 10,
                  }}
                >
                  {item}
                </div>
              </div>
            ))}
          </div>
          <canvas style={{ maxWidth: "12vw" }} ref={chartRef}></canvas>
        </div>
      </div>
    </Card>
  );
};

export default DonutChart;
