import { BASE_URL } from "../constant";
import LeadsContext from "../context/LeadsContext/LeadsContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LeadState = (propsx) => {
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [statistics, setStatistics] = useState(null);

  const updateLeadsStatusInList = (Id, newStatus, leads) => {
    return leads.map((lead) => {
      if (lead.id === Id) {
        return { ...lead, status: newStatus };
      }
      return lead;
    });
  };

  const UpdateLeadStatus = async (props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${BASE_URL}/lead/update_status/${props.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ id: props.id, status: props.status }),
          }
        );

        if (response.status === 200) {
          toast.success("Status updated successfully");
          // console.log(props.status);
          const updatedUserList = updateLeadsStatusInList(
            props.id,
            props.status,
            leads
          );

          setLeads(updatedUserList);
          //console.log(users);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update lead's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update lead's status, Try again later");
        reject(error);
      }
    });
  };

  const getLeadStatistics = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/lead/getstatistics`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data[0]);
        //console.log(statistics);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  // Get all Notes

  const getLeads = async (props) => {
    const { page } = props;

    const limit = 12;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = { limit: limit, offset: offset };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/lead/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setLeads(json.data);
        setTotalLeads(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getSearchLeads = async (props) => {
    const { page, lead_type, status, query_string } = props;

    const limit = 12;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      lead_type: lead_type,
      status: status,

      query_string: query_string,
      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/lead/search/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setLeads(json.data);
        setTotalLeads(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const deleteLeads = async (props) => {
    const { ids, page } = props;
    console.log(ids);
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/lead/bulk/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ ids: ids }),
      });
      if (response.status === 200) {
        // const json = response.json();
        getLeads({ page: page });
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete lead(s), Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete lead(s), Try again later");

      // console.log(e.message);
    }
  };

  return (
    <LeadsContext.Provider
      value={{
        leads,
        totalLeads,
        statistics,

        getLeads,
        getSearchLeads,
        getLeadStatistics,
        deleteLeads,

        UpdateLeadStatus,
      }}
    >
      {propsx.children}
    </LeadsContext.Provider>
  );
};
export default LeadState;
