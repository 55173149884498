import { BASE_URL } from "../constant";
import BuilderContext from "../context/BuildersContext/BuilderContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BuilderState = (propsx) => {
  const [builders, setBuilders] = useState([]);
  const [totalBuilders, setTotalBuilders] = useState(0);
  const [statistics, setStatistics] = useState(null);
  const [sitevisitsOverview, setSitevisitsOverview] = useState([]);
  const [invoiceOverview, setInvoiceOverview] = useState([]);
  const [subscriptionOverview, setSubscriptionOverview] = useState([]);

  const updateBuilderStatusInList = (userId, newStatus, userList) => {
    return userList.map((user) => {
      if (user.id === userId) {
        return { ...user, status: newStatus };
      }
      return user;
    });
  };

  const updateKYCStatusInList = (userId, newStatus, userList) => {
    return userList.map((user) => {
      if (user.id === userId) {
        return { ...user, kyc_verified: newStatus };
      }
      return user;
    });
  };

  const UpdateKYCStatus = async (props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/builders/updatekycstatus`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            id: props.id,
            kyc_verified: props.kyc_verified,
          }),
        });

        if (response.status === 200) {
          toast.success("Status updated successfully");
          // console.log(props.status);
          const updatedUserList = updateKYCStatusInList(
            props.id,
            props.kyc_verified,
            builders
          );
          // console.log(updatedUserList);

          setBuilders(updatedUserList);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update builder's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update builder's status, Try again later");
        reject(error);
      }
    });
  };
  const UpdateBuilderStatus = async (props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/builders/updatestatus`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: props.id, status: props.status }),
        });

        if (response.status === 200) {
          toast.success("Status updated successfully");
          // console.log(props.status);
          const updatedUserList = updateBuilderStatusInList(
            props.id,
            props.status,
            builders
          );
          //console.log(updatedUserList);

          setBuilders(updatedUserList);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update builder's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update builder's status, Try again later");
        reject(error);
      }
    });
  };

  const getBuilderSubscriptionsOverview = async (props) => {
    const queryParams = { id: props.id };
    setSubscriptionOverview([]);

    try {
      const response = await fetch(
        `${BASE_URL}/transaction/get/donutchartuser?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // console.log(json.data);
        setSubscriptionOverview(json.data);
        //console.log(statistics);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      //console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getBuilderSiteVisitOverview = async (props) => {
    const queryParams = { id: props.id };
    setSitevisitsOverview([]);

    try {
      const response = await fetch(
        `${BASE_URL}/site_visits/get/donutchartbuilder?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // console.log(json.data);
        setSitevisitsOverview(json.data);
        // console.log(json.data[5].total_records);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      //console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getBuilderInvoicesOverview = async (props) => {
    const queryParams = { id: props.id };
    setInvoiceOverview([]);

    try {
      const response = await fetch(
        `${BASE_URL}/invoice/get/donutchartbuilder?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // console.log(json.data);
        setInvoiceOverview(json.data);
        // console.log(json.data[5].total_records);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      //console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getBuilderStatistics = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/builders/getbuilderstat`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data[0]);
        //console.log(json.data[0]);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getBuilders = async (props) => {
    const { page } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = { limit: limit, offset: offset };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/builders/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setBuilders(json.data);
        setTotalBuilders(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getSearchBuilders = async (props) => {
    const { page, status, subscription, kyc, query_string } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      status: status,
      subscription: subscription,
      kyc: kyc,
      query_string: query_string,
      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/builders/search/getall?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setBuilders(json.data);
        setTotalBuilders(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  //   // Add a Note
  //   const addNote = async (title, description, tag) => {
  //     // TODO: API Call
  //     // API Call
  //     try{
  //       const response = await fetch(`${host}/api/v1/notes/create`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //         body: JSON.stringify({ title, description, tag }),
  //       });

  //       if (response.status === 200) {
  //         const note = await response.json();

  //         console.log(note.data);

  //         setNotes(notes.concat(note.data));
  //         // props.showAlert("Note added successfully", "success");
  //       } else {
  //         console.log("FAILED WITH STATUS CODE " + response.status.toString());
  //         // props.showAlert("Unknown error occurred", "danger");
  //       }
  //     }catch(e){

  //       console.log(e.message);
  //     }

  //   };

  const deleteBuilders = async (props) => {
    const { ids, page } = props;
    console.log(ids);
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/builders/bulk/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ ids: ids }),
      });
      if (response.status === 200) {
        // const json = response.json();
        getBuilders({ page: page });
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete builder(s), Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete builder(s), Try again later");

      // console.log(e.message);
    }
  };

  // Edit a Note
  //   const editNote = async (id, title, description, tag) => {
  //     // API Call
  //     try{
  //       const response = await fetch(`${host}/api/v1/notes/update/${id}`, {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //         body: JSON.stringify({ title, description, tag }),
  //       });

  //       if (response.status === 200) {
  //         // const json = await response.json();

  //         let newNotes = JSON.parse(JSON.stringify(notes));
  //         // Logic to edit in client
  //         for (let index = 0; index < newNotes.length; index++) {
  //           const element = newNotes[index];
  //           if (element._id === id) {
  //             newNotes[index].title = title;
  //             newNotes[index].description = description;
  //             newNotes[index].tag = tag;
  //             break;
  //           }
  //         }
  //         setNotes(newNotes);
  //         //props.showAlert("Note updated successfully", "success");
  //       } else {
  //         console.log("FAILED WITH STATUS CODE " + response.status.toString());
  //         //props.showAlert("Unknown error occurred", "danger");
  //       }
  //     }catch(e){

  //       console.log(e.message);
  //     }

  //   };

  return (
    <BuilderContext.Provider
      value={{
        builders,
        totalBuilders,
        getBuilders,
        getSearchBuilders,

        getBuilderStatistics,
        statistics,

        getBuilderSiteVisitOverview,
        sitevisitsOverview,

        getBuilderInvoicesOverview,
        invoiceOverview,

        getBuilderSubscriptionsOverview,
        subscriptionOverview,

        UpdateBuilderStatus,
        UpdateKYCStatus,

        deleteBuilders,
      }}
    >
      {propsx.children}
    </BuilderContext.Provider>
  );
};
export default BuilderState;
