import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto"; // Import the Chart class from chart.js

function BarChart(props) {
  const chartRef = useRef(null);
  const { labels, dataset } = props.data;

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      new Chart(ctx, {
        type: "bar", // Specify chart type as 'bar' for a bar chart
        data: {
          labels:  labels,
          datasets: [
            {
              label: "Subscriber",
              backgroundColor: "#1B59F8", // Light grey background color
              // borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 2,
              data:  dataset,
            },
            // {
            //   label: 'Sample Data',
            //   backgroundColor: 'white',
            //  // borderColor: 'rgba(0, 0, 0, 1)',
            //   borderWidth: 2,
            //   data: [700, 200, 500, 560, 400, 380, 600, 390, 290, 800, 200, 900]
            // },
          ],
        },
        options: {
          scales: {
            x: {
              grid: {
                display: false, // Hide grid lines for x-axis
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: true, // Hide grid lines for x-axis
              },
            },
          },
          plugins: {
            labels: {
              render: "value", // Render label as value (data value)
              fontColor: "black", // Label font color
              fontSize: 12, // Label font size
              position: "top", // Position label on top of each bar
            },
          },
        },
      });
    }
  }, []);

  return (
    <div
      style={{
        maxWidth: "40vw",
        minWidth: "40vw",
        maxHeight: "50vh",
        padding: 15,

        // /backgroundColor: "black",
      }}
    >
      <canvas ref={chartRef}></canvas>
    </div>
  );
}

export default BarChart;
