import React, { useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

import { CiSearch } from "react-icons/ci";
import { AiFillDelete } from "react-icons/ai";
import transactionContext from "../../context/TransactionsContext/TransactionsContext";
import dashboardContext from "../../context/DashboardContext/DashboardContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoFilter } from "react-icons/io5";
import FilterModal from "./FiltersModal";
import useConfirm from "../AlertDialog";
import Pagination from "../.././components/Pagination";

import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";

const AllTransactions = () => {
  const [checked, setChecked] = useState([]);
  const [query, setQuery] = useState("");

  const [filters, setFilters] = useState(null);

  // let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(transactionContext);
  const {
    transactions,
    totalTransactions,

    getSearchTransactions,
    deleteTransactions,
  } = context;
  const context2 = useContext(dashboardContext);
  const {
 
    getTransactionsLinechart,
    linechart,
 
  } = context2;
  const [FilterDialog, ApplyFilters] = FilterModal();

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Leads!",
    `Are you sure you want to delete ${
      checked.length > 1 ? "these Leads" : "this user"
    } : ${checked}? `
  );

  const handleFilters = async () => {
    const filtersx = await ApplyFilters();
    if (filtersx === null) {
    } else {
      setCurrentPage(1);
      getSearchTransactions({
        page: currentPage,
        status: filtersx.status,
        is_credited: filtersx.is_credited,
        query_string: query,
      });
      setFilters({
        status: filtersx.status,
        is_credited: filtersx.is_credited,
      });
      // console.log(filters);
    }
  };

  const handleDelete = async () => {
    const ans = await confirmDelete();
    if (ans) {
      handleDeleteUsers();
    } else {
      /* ... */
    }
  };

  const [showLead, setShowLead] = useState(null);

  const handleShowLead = (lead) => {
    console.log(lead.id);
    setShowLead(lead);

    // You can add logic here to fetch data for the selected page
  };

  // const handleStatusChange = async (event, index) => {

  //   const stat = await UpdateTicketStatus({
  //     id: transactions[index].id,
  //     status: transactions[index].status === 1 ? 0 : 1,
  //   });
  //   if (stat) {
  //     // window.location.reload();
  //     //  console.log(stat);
  //   }
  // };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (localStorage.getItem("token")) {
      query.length > 0 || filters !== null
        ? getSearchTransactions({
            page: newPage,
            status: filters !== null ? filters.status : [],
            is_credited: filters !== null ? filters.is_credited : [],

            query_string: query,
          })
        : getSearchTransactions({
            page: newPage,
            status: [],
            is_credited: [],
            query_string: "",
          });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const handleDeleteUsers = async () => {
    if (localStorage.getItem("token")) {
      await deleteTransactions({ ids: checked, page: currentPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);

    getSearchTransactions({
      page: currentPage,
      status: filters !== null ? filters.status : [],
      is_credited: filters !== null ? filters.is_credited : [],

      query_string: query,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getSearchTransactions({
        page: currentPage,
        status: [],
        is_credited: [],
        query_string: "",
      });
      getTransactionsLinechart();
      setShowLead(transactions.length > 0 ? transactions[0] : null);
    } else {
    }

    // eslint-disable-next-line
  }, [showLead === null ? transactions : null]);

  function calculateExpiryDate(subscriptionMonths) {
    // Get the current date
    let currentDate = new Date();

    // Calculate the expiry date by adding the subscription months to the current date
    let expiryDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + subscriptionMonths,
      currentDate.getDate()
    );

    return expiryDate;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,

            // maxWidth: "100%",
          }}
        >
          <ToastContainer position="top-center" />

          <div
            style={{
              display: "flex",
              // backgroundColor: "white",
              justifyContent: "space-around",
              paddingTop: 15,
              //alignItems:"center"
            }}
          >
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  Total Transaction
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>₹{linechart.length>0?linechart[linechart.length-2].total_price:0}</label>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  Current month
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>₹{linechart.length>0?linechart[linechart.length-3].total_price:0}</label>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  Last month
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>₹{linechart.length>0?linechart[linechart.length-4].total_price:0}</label>
                </div>
              </CardContent>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 15,
              //  marginTop: 15,
            }}
          >
            <div
              style={{
                fontSize: 18,
              }}
            >
              All Transactions({totalTransactions})
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <form
                className="form_search"
                onSubmit={handleSubmit}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  className="serach_asset"
                  variant="outlined"
                  placeholder="Search tickets..."
                  value={query}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton type="submit" edge="start">
                        <CiSearch />
                      </IconButton>
                    ),
                    style: {
                      height: "32px",
                      minWidth: "30vw",

                      // width: "500px", // Adjust the height as needed
                      flexGrow: 1, // Allow the input to grow to fill available space
                    },
                  }}
                  fullWidth
                />
              </form>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  //disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleFilters}
                >
                  <IoFilter color="grey" />
                </IconButton>
                <FilterDialog />
              </div>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  marginRight: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleDelete}
                >
                  <AiFillDelete color={checked.length === 0 ? "grey" : "red"} />
                </IconButton>
                <Dialog />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 15,
              fontFamily: "monospace",
              fontWeight: 550,
              textAlign: "left",
              maxWidth: "100%",
              paddingTop: 10,
              paddingLeft: 20,
              color: "grey",
              alignItems: "center",
              justifySelf: "start",
              height: 35,
            }}
          >
            {/* <input
              value="all"
              type="checkbox"
              style={{
                alignContent: "start",
                backgroundColor: "#000",
                marginBottom: "30px",
              }}
            /> */}

            <div
              style={{
                width: "3vw",
                height: 50,
                fontWeight: 600,
                marginLeft: 10,
                //alignContent: "center",
                // backgroundColor:"black"
              }}
            >
              ID
            </div>
            <div
              style={{
                width: "10vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                //backgroundColor: "black",
              }}
            >
              Transaction ID
            </div>
            <div
              style={{
                width: "6vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                //backgroundColor: "black",
              }}
            >
              UTR ID
            </div>
            <div
              style={{
                width: "9vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                //  backgroundColor: "black",
              }}
            >
              Title
            </div>
            <div
              style={{
                width: "10vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                //  backgroundColor: "black",
              }}
            >
              Description
            </div>
            <div
              style={{
                width: "5vw",
                height: 50,
                maxLines: 1,
                // backgroundColor: "black",
              }}
            >
              Amount
            </div>

            <div
              style={{
                width: "5vw",
                height: 50,
                maxLines: 1,
                // marginLeft: 35,
                // backgroundColor: "black",
              }}
            >
              Status
            </div>

            <div
              style={{
                width: "9vw",
                height: 50,
                maxLines: 1,
                // marginLeft: 35,
                // backgroundColor: "black",
              }}
            >
              Date
            </div>
          </div>
          <div
            style={{
              height: "65vh",
              overflowY: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              marginBottom: 10,
            }}
          >
            {transactions.map((item) => (
              <div
                key={item.id}
                onClick={() => handleShowLead(item)}
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",

                  flexWrap: "wrap",
                  color: "#000",
                  fontWeight: 530,
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  maxLines: 1,
                  borderBottom: ".5px solid #d8d0d0",

                  // paddingTop: 10,
                  marginTop: 5,
                }}
              >
                <input
                  value={item.id}
                  type="checkbox"
                  onChange={handleCheck}
                  style={{
                    alignContent: "start",
                    backgroundColor: "#000",
                    marginLeft: "10px",
                    // marginBottom: "30px",
                  }}
                />

                <div
                  style={{
                    width: "3vw",
                    height: 50,
                    fontWeight: 600,
                    marginLeft: 10,
                    alignContent: "center",
                   // paddingTop: "15px",
                  }}
                >
                  {item.id}
                </div>

                <div
                  style={{
                    width: "10vw",
                    height: 50,
                    marginLeft: 10,
                    maxLines: 1,
                    paddingTop: "12px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.transaction_id}
                </div>
                <div
                  style={{
                    width: "6vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",
                    marginLeft: 7,

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.utr_id}
                </div>
                <div
                  style={{
                    width: "9vw",
                    height: 50,
                    marginLeft: 15,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.title}
                </div>
                <div
                  style={{
                    width: "10vw",
                    height: 50,
                    marginLeft: 5,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.description}
                </div>
                <div
                  style={{
                    width: "5vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",

                    color: "black",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  ₹{item.price}
                </div>

                <div
                  style={{
                    width: "5vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",
                    // paddingLeft: "15px",

                    color: item.status === 1 ? "green" : "red",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.status === 1 ? "Succesful" : "Failed"}
                </div>
                <div
                  style={{
                    width: "9vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",
                    // paddingLeft: "15px",

                    color: "black",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.created_at}
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div
              style={{
                marginTop: 15,
                marginLeft: 15,
                marginBottom: 10,
              }}
            >
              Showing {transactions.length} out of {totalTransactions}
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalTransactions / 10)}
                onPageChange={(e) => handlePageChange(e)}
              />
            </div>
          </div>
        </div>
        
        <div>
          <div>
            <Card
              style={{
                minWidth: "20vw",
                maxWidth: "22vw",
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    ID: #{showLead !== null ? showLead.id : ""}
                  </Typography>
                  <Typography
                    variant="h7"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    {showLead !== null ? showLead.status==1?"Succesful":"Failed" : ""}
                  </Typography>
                </div>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography>Transaction ID</Typography>
                  <Typography>
                    {showLead !== null ? showLead.transaction_id : ""}
                  </Typography>
                </div>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography>UTR</Typography>
                  <Typography>
                    {showLead !== null ? showLead.utr_id : ""}
                  </Typography>
                </div>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography>Date</Typography>
                  <Typography>
                    {showLead !== null ? showLead.created_at : ""}
                  </Typography>
                </div>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography>Amount</Typography>
                  <Typography>
                    {showLead !== null ? showLead.price : ""}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              style={{
                minWidth: "20vw",
                maxWidth: "22vw",
                maxHeight: "70vh",
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography
                    variant="h7"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    Transaction Details
                  </Typography>
                </div>
                <div
                  style={{
                    height: "70vh",
                    overflowY: "auto",
                    marginBottom: 10,
                    // backgroundColor:"white"
                  }}
                >
                  <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>User ID</Typography>
                    <Typography>
                      {showLead !== null ? showLead.uid : ""}
                    </Typography>
                  </div>
                  {/* <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Phone</Typography>
                    <Typography>{showLead.phone}</Typography>
                  </div> */}
                  <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Date</Typography>
                    <Typography>
                      {showLead !== null ? showLead.created_at : ""}
                    </Typography>
                  </div>
                  {/* <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Expire On</Typography>
                    <Typography>calculateExpiryDate(1)</Typography>
                  </div> */}
                  <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Enrolled Plan</Typography>
                    <Typography>
                      {showLead !== null ? showLead.title.split("-")[1] : ""}
                    </Typography>
                  </div>
                  <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Amount</Typography>
                    <Typography>
                      {showLead !== null ? showLead.price : ""}
                    </Typography>
                  </div>
                  {/* <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Total Collection</Typography>
                    <Typography>₹345464</Typography>
                  </div> */}
                  <div
                    // key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Title</Typography>
                    <Typography>
                      {showLead !== null ? showLead.title : ""}
                    </Typography>
                  </div>
                  <div
                    // key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>Description</Typography>
                    <div style={{ height: 10 }}></div>
                    <div style={{fontSize:12,fontStyle:"italic"}}>
                      {showLead !== null ? showLead.description : ""}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTransactions;
