import { BASE_URL } from "../constant";
import AssetStatusContext from "../context/AssetsContext/AssetStatusContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AssetStatusState = (propsx) => {
  const [assetsStatuses, setAssetsStatuses] = useState([]);
  const [statistics, setStatistics] = useState([]);

  const getAssetStatusStatistics = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/filter/pstatus/get_analytics`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data);
        //console.log(statistics);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAssetsStatuses = async (props) => {
    // API Call

    try {
      const response = await fetch(`${BASE_URL}/filter/pstatus/get`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);

        const parsedData = json.data.map((item) => ({
          ...item,
          icon: JSON.parse(item.icon), // Convert property_bhk string to JSON
        }));
        //   console.log(parsedData);
        setAssetsStatuses(parsedData);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const createAssetStatus = async (props) => {
    const { name, description, icon } = props;
    // {
    //     "name":"Farm House",
    //     "description":"",
    //     "icon":""
    // }

    try {
      const response = await fetch(`${BASE_URL}/filter/pstatus/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: name,
          description: description,
          icon: icon === null ? null : JSON.stringify(icon),
        }),
      });

      if (response.status === 200) {
        getAssetsStatuses();
        toast.success("Asset status created successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        console.log(response.error);
        toast.error("Couldn't Add asset status, Try again later");
      }
    } catch (e) {
      console.log(e);

      toast.error("Couldn't Add asset status, Try again later");
    }
  };

  const updateAssetStatus = async (props) => {
    const { id, name, description, icon } = props;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${BASE_URL}/filter/pstatus/update/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              name: name,
              description: description,
              icon: icon === null ? null : JSON.stringify(icon),
            }),
          }
        );

        if (response.status === 200) {
          toast.success("Asset status updated successfully");

          getAssetsStatuses();
          //console.log(users);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update, Try again later");
        reject(error);
      }
    });
  };

  const deleteAssetsStatus = async (props) => {
    const { id } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/filter/pstatus/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        // const json = response.json();
        getAssetsStatuses();
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete, Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete, Try again later");

      // console.log(e.message);
    }
  };

  return (
    <AssetStatusContext.Provider
      value={{
        statistics,
        getAssetStatusStatistics,

        assetsStatuses,
        getAssetsStatuses,
        createAssetStatus,
        updateAssetStatus,
        deleteAssetsStatus,
      }}
    >
      {propsx.children}
    </AssetStatusContext.Provider>
  );
};
export default AssetStatusState;
