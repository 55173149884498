import React, { useState, useContext, useEffect } from "react";
import { BASE_URL, IMAGE_PATH } from "../../../constant";
import { CiImageOff } from "react-icons/ci";
import useConfirm from "../../AlertDialog";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import assetLandmarkContext from "../../../context/AssetsContext/AssetLandmarks";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { CiSearch } from "react-icons/ci";

import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddHomeWork, MdCancel } from "react-icons/md";
import { IconButton, TextField } from "@mui/material";

const AllProjectLandmarks = (props) => {
  const context = useContext(assetLandmarkContext);
  const {
    assetsLandmarks,
    getAssetsLandmarks,
    getSearchAssetsLandmarks,

    createAssetLandmark,
    updateAssetLandmark,
    deleteAssetsLandmark,
  } = context;

  const [query, setQuery] = useState("");

  const [landmarks, setLandmarks] = useState({
    id: Math.floor(Math.random() * 100 + 1),
    image: null,
    name: "",
    locality: "",
    category: "",
    lat: 1.1,
    lon: 1.1,
   });
  const landmarks_categories = [
    { id: 1, value: "Education", label: "Education" },
    { id: 2, value: "Transport", label: "Transport" },
    { id: 3, value: "Healthcare", label: "Healthcare" },
    { id: 4, value: "Businesshub", label: "Businesshub" },
    { id: 5, value: "Restaurant", label: "Restaurant" },
  ];
  const [isAdding, setIsAdding] = useState(true);

  const handleIsAdding = (pt, val) => {
    setLandmarks({
      id: pt.id,
      image: pt.image,
      name: pt.name,
      locality: pt.locality,
      category: pt.category,
      lat: pt.lat,
      lon: pt.lon,
     });
    setIsAdding(val);
  };

  const handleData = (key, value) => {
    setLandmarks((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };
  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };
  const handleImageChange = async (key, event) => {
    if (key === "imageadd") {
      const files = Array.from(event.target.files);

      try {
        if (files.length > 0) {
          const formData = new FormData();

          formData.append("profilex", files[0]);

          const queryParams = {
            folderName: "default",
          };

          const response = await fetch(
            `${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`,
            {
              method: "POST",
              headers: {
                // "Content-Type": "application/json",
                // Accept: "application/json",
              },
              body: formData,
            }
          );
          if (response.status === 200) {
            const json = await response.json();

            const selectedFile = files[0];
            const reader = new FileReader();

            reader.onload = (value) => {
              handleData("image", json.data[0]);
            };

            if (selectedFile) {
              reader.readAsDataURL(selectedFile);
            }
            //toast.success("Media uploded successfully");
          } else {
            // toast.error("Couldn't upload media(s), Try again later");
            console.log(response.status);
          }
        }
      } catch (e) {
        console.log(e);
        // toast.error("Couldn't upload media(s), Try again later");
      }
    } else {
      try {
        console.log(landmarks.image.path);
        const queryParams = { filePath: landmarks.image.path };

        const response = await fetch(
          `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          handleData("image", null);
        } else {
          console.log("error");
          // toast.error("Couldn't upload media(s), Try again later");
        }
      } catch (e) {
        console.log(e);
        // toast.error("Couldn't upload media(s), Try again later");
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAssetsLandmarks();
    } else {
    }

    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    if (
      landmarks.name.length > 0 &&
      landmarks.name.length > 0 &&
      landmarks.locality.length > 0 &&
      landmarks.category.length > 0 &&
      landmarks.lat.toString().length > 0 &&
      landmarks.lon.toString().length > 0 
     ) {
      if (isAdding) {
        createAssetLandmark(landmarks);
      } else {
        updateAssetLandmark(landmarks);
      }
      setLandmarks({
        id: 0,
        image: null,
        name: "",
        locality: "",
        category: "",
        lat: 0.0,
        lon: 0.0,
        
      });
    } else {
      console.log(landmarks);
      toast.error("Please enter required data");
    }
  };

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Project Configuration!",
    `Are you sure you want to delete ${landmarks.name}? `
  );

  const handleDelete = async (idx) => {
    const ans = await confirmDelete();
    if (ans) {
      deleteAssetsLandmark({ id: idx });
    } else {
      /* ... */
    }
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getSearchAssetsLandmarks({ name: query });
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(196, 196, 196, 0.15)",
          margin: 0,
          width: "80vw",
          height: "98vh",
          maxHeight: "98vh", // Set maximum height to screen height
          overflowY: "auto", // Enable vertical scrolling if content exceeds height
          border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <ToastContainer position="top-center" />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
              backgroundColor: "white",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              padding: 5,
            }}
          >
            <div style={{ fontSize: 18, margin: 10, fontWeight: 600 }}>
              {isAdding ? "Add Project Landmarks" : "Update Project Landmarks"}
            </div>
            <form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  padding: 5,
                }}
              >
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "column",
                    // alignItems: "center",
                    backgroundColor: "white",
                    padding: 10,
                    flex: 1,

                    height: 100,
                    // width: "33vw",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <label style={{ marginBottom: 10, flex: 1 }}>
                      Landmark Name
                    </label>

                    <TextField
                      id="propertyName"
                      ////value={road.id}
                      value={landmarks.name}
                      placeholder="Hotel Moon Star"
                      helperText="Road/Landmark name"
                      onChange={(e) => handleData("name", e.target.value)}
                      // Hint text
                      variant="outlined"
                      fullWidth
                      style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                      inputProps={{ style: { height: 8 } }}
                      InputLabelProps={{
                        style: { fontSize: "15px" },
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "column",
                    // alignItems: "center",
                    backgroundColor: "white",
                    padding: 10,
                    flex: 1,

                    height: 100,
                    // width: "12vw",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <label style={{ marginBottom: 10, flex: 1 }}>
                      Locality
                    </label>
                    <TextField
                      id="propertyName"
                      placeholder="Kanakapura Road"
                      helperText="Locality Name"
                      value={landmarks.locality}
                      // Hint text
                      onChange={(e) => handleData("locality", e.target.value)}
                      variant="outlined"
                      fullWidth
                      // style={{ height: 20 }}
                      inputProps={{ style: { height: 8 } }}
                      InputLabelProps={{
                        style: { fontSize: "15px" },
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: 10,

                    margin: "0px 10px",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <label style={{ marginBottom: 10, flex: 1 }}>
                      Category
                    </label>
                    <CreatableSelect
                      // isMulti
                      styles={{ flex: 1, width: 400 }}
                      placeholder="Select Category"
                      value={{
                        id: landmarks.category.id,
                        value: landmarks.category,
                        label: landmarks.category,
                      }}
                      onInputChange={(value) => value.toUpperCase()}
                      onChange={(selectedOption) => {
                        handleData("category", selectedOption.value);
                      }}
                      options={landmarks_categories}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "start" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: 5,
                    }}
                  >
                    <div
                      style={{
                        // display: "flex",
                        // flexDirection: "column",
                        // alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,
                        flex: 2,

                         height: 100,
                        //width: "12vw",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Latitude
                        </label>
                        <TextField
                          id="propertyName"
                          value={landmarks.lat}
                          ////value={road.id}
                          placeholder="12.9568611"
                          helperText="Ex-12.9568611"
                          // Hint text
                          variant="outlined"
                          onChange={(e) => handleData("lat", e.target.value)}
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{
                            style: { fontSize: "15px" },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        // display: "flex",
                        // flexDirection: "column",
                        // alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,
                        flex: 2,

                        height: 100,
                        // width: "12vw",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Longitude
                        </label>
                        <TextField
                          id="propertyName"
                          placeholder="77.5330066"
                          helperText="Ex-77.5330066"
                          // Hint text
                          value={landmarks.lon}
                          variant="outlined"
                          onChange={(e) => handleData("lon", e.target.value)}
                          fullWidth
                          // style={{ height: 20 }}
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{
                            style: { fontSize: "15px" },
                          }}
                        />
                      </div>
                    </div>
                    {/* <div
                      style={{
                        // display: "flex",
                        // flexDirection: "column",
                        // alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,
                        flex: 1,

                        height: 100,
                        // width: "12vw",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Distance from project
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="6"
                          helperText="In KM"
                          // Hint text
                          value={landmarks.distance}
                          variant="outlined"
                          onChange={(e) =>
                            handleData("distance", e.target.value)
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{
                            style: { fontSize: "15px" },
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "column",
                    // alignItems: "center",
                    backgroundColor: "white",
                   // flex: 1,
                    padding: 10,

                    // height: 200,
                    // width: 182,

                    // justifyContent: "center",
                  }}
                >
                  {!landmarks.image && (
                    <div style={{ marginLeft: 5 }}>
                      <label style={{ marginBottom: "10px", flex: 1 }}>
                        Image
                      </label>
                      <div>
                        <label
                          htmlFor="fileInput"
                          style={{
                            cursor: "pointer",
                            display: "inline-block",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: 5,

                            // backgroundColor:"black",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Select Image File
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) => handleImageChange("imageadd", e)}
                          style={{
                            display: "none",
                            borderRadius: 10,
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {landmarks.image && (
                    <div style={{ alignItems: "center" }}>
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "5px",
                          position: "relative",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleImageChange("imageremove", e)}
                        >
                          <MdCancel size={25} />
                        </span>
                        <img
                          src={
                            IMAGE_PATH +
                            landmarks.image.fieldname +
                            "/" +
                            landmarks.image.filename
                          }
                          alt="Error"
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "190px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  maxWidth: "15vw",
                  border: "1px solid #ffffff",
                  borderRadius: "6px",
                  color: "white",
                  margin: "20px 10px",
                  padding: "5px 10px",
                  backgroundColor: "#21232d",

                  textAlign: "center",
                }}
                // onClick={addType}
                onClick={() => handleSubmit()}
              >
                <IconButton type="submit" edge="start">
                  <MdOutlineAddHomeWork color="white" />
                </IconButton>
                {isAdding ? "Add this Landmark" : "Update Landmark"}
              </div>
            </form>
          </div>
        </div>

        <div
          style={{
            padding: "15px 20px",
            margin: "0px 0px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5>All Project Landmarks ({assetsLandmarks.length})</h5>
            <form
              className="form_search"
              onSubmit={handleSearchSubmit}
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                className="serach_asset"
                variant="outlined"
                placeholder="Search assets..."
                value={query}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <IconButton type="submit" edge="start">
                      <CiSearch />
                    </IconButton>
                  ),
                  style: {
                    height: "32px",
                    minWidth: "30vw", // Adjust the height as needed
                    flexGrow: 1, // Allow the input to grow to fill available space
                  },
                }}
                fullWidth
              />
            </form>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(20vw, 1fr))",
              gap: "10px",
            }}
          >
            {assetsLandmarks.map((pt, index) => (
              <div
                key={pt.id}
                onClick={() => handleIsAdding(pt, false)}
                style={{
                  //  padding: "7px",
                  // height: "100px",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "8px",
                  //  width: "15vw",
                  display: "flex",
                  marginRight: 14,
                  alignItems: "center",
                }}
              >
                {pt.image !== null ? (
                  // If the icon field exists and contains an image property
                  <img
                    src={
                      IMAGE_PATH + pt.image.fieldname + "/" + pt.image.filename
                    }
                    alt="Icon"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginRight: "10px",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                ) : (
                  // If the icon field is empty or doesn't contain an image property
                  <IconButton type="submit" edge="start">
                    <CiImageOff color="black" />
                  </IconButton>
                )}

                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "start",
                    width: "20vw",
                    // backgroundColor: "black"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // backgroundColor:"black",
                      justifyContent: "space-between",
                      // alignItems:"end",
                      // maxWidth: "14vw",
                    }}
                  >

                    <div style={{
                      display:"flex",
                      justifyContent:"start",
                      alignItems:"start",
                      flexDirection:"column"
                    }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          display: "inline-block",  // Set display to inline-block for inline fitting
                           marginBottom: 5,
                          whiteSpace: "nowrap",      // Prevents text from wrapping
                          overflow: "hidden",        // Hides overflowed content
                          textOverflow: "ellipsis",  // Adds "..." when text overflows
                          maxWidth: "15vw"  
                          // marginRight: "30px",
                           // maxWidth: "100%"
                        }}
                      >
                        {pt.name}
                      </div>
                      <div
                        style={{
                           display: "inline-block",  // Set display to inline-block for inline fitting
                          
                          whiteSpace: "nowrap",      // Prevents text from wrapping
                          overflow: "hidden",        // Hides overflowed content
                          textOverflow: "ellipsis",  // Adds "..." when text overflows
                          maxWidth: "15vw" ,
                          fontSize: 14,
                          // maxWidth: "100%"
                        }}
                      >
                        {pt.locality}
                      </div>
                      <div
                        style={{
                           fontWeight: "bold",
                          // marginRight: "30px",
                          marginBottom: 5,
                          fontSize: 14,
                          // maxWidth: "100%"
                        }}
                      >
                        {pt.category}
                      </div>
                    </div>

                    <div
                      style={{
                        backgroundColor: "rgba(192, 192, 192,0.4 )",
                        alignContent: "center",
                        justifyContent: "center",
                        borderRadius: 7,
                      }}
                    >
                      {/* <IconButton size="small" onClick={handleEditClick}>
                        <FiEdit2 style={{ cursor: "pointer" }} />
                      </IconButton> */}

                      <IconButton
                        onClick={() => handleDelete(pt.id)}
                        size="small"
                        style={{ color: "red" }}
                      >
                        <AiOutlineDelete style={{ cursor: "pointer" }} />
                      </IconButton>
                      <Dialog />
                    </div>
                  </div>
                  {/* <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    {pt.name}
                  </div> */}
                  {/* <div style={{ fontSize: 10, marginBottom: 5 }}>
                    {pt.description}
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjectLandmarks;
