import React, { useState } from 'react';
 import { Button, Modal, TextField } from "@mui/material";

const FileModal = ({ isOpen, onClose, onSave }) => {
  const [fileName, setFileName] = useState('');

  const handleSave = () => {
    onSave(fileName);
    setFileName(''); // Reset the input field after saving
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: '20px', borderRadius: '8px' }}>
        <TextField
          label="File Name"
          variant="outlined"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          fullWidth
          style={{ marginBottom: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleSave}>Create</Button>
      </div>
    </Modal>
  );
};

export default FileModal;
