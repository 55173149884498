import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import "./Modal.css"; // Import CSS for modal animation
import dashboardContext from "../../context/DashboardContext/DashboardContext";

// import Notes from "../Notes/Notes";
import { IoNotifications } from "react-icons/io5";
import UserImage from "../../assets/images/user1.png";
import AssetImage from "../../assets/images/asset1.png";
import LeadImage from "../../assets/images/lead1.png";
import TransactionImage from "../../assets/images/transactions1.png";
import User from "../../assets/images/user1.png";

import { Card, CardContent, Typography, IconButton } from "@mui/material";
import TimeSeriesChart from "./BarChart";
import DonutChart from "./DonutChart";
import LineChart from "./LineChart";

const Dashboardx = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const ref = useRef();
  const context = useContext(dashboardContext);
  const {
    getStatistics,
    statistics,
    getTransactionsBarchart,
    barchart,
    getTransactionsLinechart,
    linechart,
    getAssetDonutchart,
    assetDonutchart,
    getLeadDonutchart,
    leadsDonutchart,
  } = context;

  useEffect(() => {
 
    if (localStorage.getItem("token")) {
      getStatistics();
      getTransactionsBarchart();
      getTransactionsLinechart();
      getAssetDonutchart();
      getLeadDonutchart();
    } else {
    }
  
  }, [ ]);

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // let navigate = useNavigate();
  const listx = [
    {
      id: "1",
      name: "SIDHARTH ",
      title: "New ticket has been raised",
      message:
        "Sidhartha raised a ticket. Click to know more XXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "2",
      name: "SEKHAR",
      title: "New Mesage Recived",
      message: "Sidhartha sent you a message.",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "3",
      name: "SIDHARTH SEKHAR",
      title: "KYC verification request",
      message: "Some one requested for. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "4",
      name: "SIDHARTH MAHANTA",
      title: "New ticket has been raised",
      message: "Sidhartha raised a ticket. Click to know more",

      date: "21-02-2024 11:50AM",
    },
    {
      id: "5",
      name: "SEKHAR Mahanta",
      title: "KYC verification request",
      message: "Some one requested for. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "6",
      name: "MAHANTA",
      title: "New ticket has been raised",
      message: "Sidhartha raised a ticket. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "7",
      name: "SIDHARTH SEKHAR",
      title: "KYC verification request",
      message: "Some one requested for. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "8",
      name: "SIDHARTH",
      title: "New ticket has been raised",
      message: "Sidhartha raised a ticket. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "9",
      name: "SIDHARTH SEKHAR",
      title: "New ticket has been raised",
      message: "Sidhartha raised a ticket. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "10",
      name: "SIDHARTH MAHANTA",
      title: "KYC verification request",
      message: "Some one requested for. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "11",
      name: "EKHAR MAHANTA",
      title: "New ticket has been raised",
      message: "Sidhartha raised a ticket. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "12",
      name: "SIDHARTH SEKHAR",
      title: "New ticket has been raised",
      message: "Sidhartha raised a ticket. Click to know more",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "13",
      name: "SIDHARTH",
      title: "KYC verification request",
      message: "Some one requested for. Click to know more",
      date: "21-02-2024 11:50AM",
    },
  ];
  return (
    <>
      <div
        style={{
          // display: "flex",
          marginLeft: 10,
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            // padding: 15,
            //   backgroundColor: "black",
            justifyContent: "end",
          }}
        >
          <IconButton type="submit" edge="start" onClick={handleIconClick}>
            <IoNotifications color="black" />
          </IconButton>
        </div>

        <div
          style={{
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
            fontFamily: "Montserrat",
            fontSize: 15,
            // maxWidth: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              minWidth: "82vw",
              justifyContent: "space-around",
              paddingBottom: 15,
              //alignItems:"center"
            }}
          >
            <div
              style={{
                width: "37vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <label style={{ fontSize: 25, fontWeight: 700 }}>
                Agent Network Statistics
              </label>

              <div
                style={{
                  display: "flex",
                  margin: 10,
                  justifyContent: "space-between",
                }}
              >
                <Card
                  elevation={5}
                  style={{
                    minWidth: "19vw",
                    maxWidth: "19vw",

                    display: "flex",
                    //backgroundColor: "#00073D",
                    backgroundColor: "white",
                    borderRadius: 15,
                  }}
                >
                  <CardContent style={{ minWidth: "13vw" }}>
                    <Typography
                      variant="h7"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Total Users
                    </Typography>
                    <div
                      // key={index}
                      style={{
                        marginTop: 10,
                        color: "black",
                      }}
                    >
                      <label style={{ fontSize: 25, fontWeight: 700 }}>
                        {statistics !== null ? statistics.total_users : 0}
                      </label>
                    </div>
                  </CardContent>
                  <img src={UserImage} alt="user" style={{ paddingTop: 5 }} />
                </Card>
                <Card
                  elevation={5}
                  style={{
                    minWidth: "15vw",
                    maxWidth: "15vw",

                    display: "flex",
                    //backgroundColor: "#00073D",
                    backgroundColor: "white",
                    borderRadius: 15,
                  }}
                >
                  <CardContent style={{ minWidth: "10vw" }}>
                    <Typography
                      variant="h7"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Total Assets
                    </Typography>
                    <div
                      // key={index}
                      style={{
                        marginTop: 10,
                        color: "black",
                      }}
                    >
                      <label style={{ fontSize: 25, fontWeight: 700 }}>
                        {statistics !== null ? statistics.total_assets : 0}
                      </label>
                    </div>
                  </CardContent>
                  <img src={AssetImage} alt="user" style={{ paddingTop: 5 }} />
                </Card>
              </div>
              <div
                style={{
                  display: "flex",
                  margin: 10,
                  justifyContent: "space-between",
                }}
              >
                <Card
                  elevation={5}
                  style={{
                    minWidth: "15vw",
                    maxWidth: "15vw",

                    display: "flex",
                    //backgroundColor: "#00073D",
                    backgroundColor: "white",
                    borderRadius: 15,
                  }}
                >
                  <CardContent style={{ minWidth: "10vw" }}>
                    <Typography
                      variant="h7"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Total Transaction
                    </Typography>
                    <div
                      // key={index}
                      style={{
                        marginTop: 10,
                        color: "black",
                      }}
                    >
                      <label style={{ fontSize: 25, fontWeight: 700 }}>
                        {statistics !== null
                          ? statistics.total_transactions
                          : 0}
                      </label>
                    </div>
                  </CardContent>
                  <img
                    src={TransactionImage}
                    alt="user"
                    style={{ paddingTop: 5 }}
                  />
                </Card>
                <Card
                  elevation={5}
                  style={{
                    minWidth: "19vw",
                    maxWidth: "19vw",

                    display: "flex",
                    //backgroundColor: "#00073D",
                    backgroundColor: "white",
                    borderRadius: 15,
                  }}
                >
                  <CardContent style={{ minWidth: "13vw" }}>
                    <Typography
                      variant="h7"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Total Leads
                    </Typography>
                    <div
                      // key={index}
                      style={{
                        marginTop: 10,
                        color: "black",
                      }}
                    >
                      <label style={{ fontSize: 25, fontWeight: 700 }}>
                        {statistics !== null ? statistics.total_leads : 0}
                      </label>
                    </div>
                  </CardContent>
                  <img src={LeadImage} alt="user" style={{ paddingTop: 5 }} />
                </Card>
              </div>
            </div>
            <div style={{ height: "100%" }}>
              {leadsDonutchart.length > 0 ? (
                <DonutChart
                  data={{
                    title: "All Leads",
                    value:
                      leadsDonutchart && leadsDonutchart[5]
                        ? leadsDonutchart[5].total_records
                        : 0,
                    labels: [
                      "Resale",
                      "Construction",
                      "Home Loan",
                      "Interior",
                      "New Home",
                    ],
                    colors: [
                      "#1B59F8",
                      "#3966db",
                      "#3456aa",
                      "#5168a0",
                      "#a4adc4",
                    ],
                    data: [
                      leadsDonutchart[0].total_records !== 0
                        ? leadsDonutchart[0].total_records
                        : 1,
                      leadsDonutchart[1].total_records !== 0
                        ? leadsDonutchart[1].total_records
                        : 1,
                      leadsDonutchart[2].total_records !== 0
                        ? leadsDonutchart[2].total_records
                        : 1,
                      leadsDonutchart[3].total_records !== 0
                        ? leadsDonutchart[3].total_records
                        : 1,
                      leadsDonutchart[4].total_records !== 0
                        ? leadsDonutchart[4].total_records
                        : 1,
                    ],
                  }}
                />
              ) : null}
            </div>
            <div style={{ height: "100%" }}>
              {assetDonutchart.length > 0 ? (
                <DonutChart
                  data={{
                    title: "All Assets",
                    value:
                    assetDonutchart.length>0?assetDonutchart[assetDonutchart.length-1].total_records:0,

                     
                    labels: ["Bengaluru", "Bhubaneswar"],
                    colors: [
                      "#1B59F8",
                      "#3966db",
                       
                    ],
                    data: [
                     // assetDonutchart.length>1?assetDonutchart[assetDonutchart.length-3].total_records:1,
                      assetDonutchart.length>2?assetDonutchart[assetDonutchart.length-2].total_records:1,
 
                      assetDonutchart.length>1?assetDonutchart[assetDonutchart.length-1].total_records:1,

                    ],
                  }}
                />
              ) : null}
            </div>
          </div>
          <label style={{ fontSize: 25, fontWeight: 700, marginLeft: 15 }}>
            Transaction Details
          </label>

          <div
            style={{
              display: "flex",
              minWidth: "82vw",
              justifyContent: "space-around",
              paddingBottom: 15,
              //alignItems:"center"
            }}
          >
            <div style={{ height: "100%" }}>
              {linechart.length > 0 ? (
                <LineChart
                  data={{
                    labels: [
                      linechart.length>2?linechart[linechart.length-(linechart.length-0)].month_year:0,
                      linechart.length>3?linechart[linechart.length-(linechart.length-1)].month_year:0,
                      linechart.length>4?linechart[linechart.length-(linechart.length-2)].month_year:0,
                      linechart.length>5?linechart[linechart.length-(linechart.length-3)].month_year:0,
                      linechart.length>6?linechart[linechart.length-(linechart.length-4)].month_year:0,
                      linechart.length>7?linechart[linechart.length-(linechart.length-5)].month_year:0,
                      linechart.length>8?linechart[linechart.length-(linechart.length-6)].month_year:0,
                      linechart.length>9?linechart[linechart.length-(linechart.length-7)].month_year:0,
                      linechart.length>10?linechart[linechart.length-(linechart.length-8)].month_year:0,
                      linechart.length>11?linechart[linechart.length-(linechart.length-9)].month_year:0,
                      linechart.length>12?linechart[linechart.length-(linechart.length-10)].month_year:0,
                      linechart.length>13?linechart[linechart.length-(linechart.length-11)].month_year:0,
             
                    ],
                    dataset: [
                      linechart.length>2?linechart[linechart.length-(linechart.length-0)].total_price:0,
                      linechart.length>3?linechart[linechart.length-(linechart.length-1)].total_price:0,
                      linechart.length>4?linechart[linechart.length-(linechart.length-2)].total_price:0,
                      linechart.length>5?linechart[linechart.length-(linechart.length-3)].total_price:0,
                      linechart.length>6?linechart[linechart.length-(linechart.length-4)].total_price:0,
                      linechart.length>7?linechart[linechart.length-(linechart.length-5)].total_price:0,
                      linechart.length>8?linechart[linechart.length-(linechart.length-6)].total_price:0,
                      linechart.length>9?linechart[linechart.length-(linechart.length-7)].total_price:0,
                      linechart.length>10?linechart[linechart.length-(linechart.length-8)].total_price:0,
                      linechart.length>11?linechart[linechart.length-(linechart.length-9)].total_price:0,
                      linechart.length>12?linechart[linechart.length-(linechart.length-10)].total_price:0,
                      linechart.length>13?linechart[linechart.length-(linechart.length-11)].total_price:0,
                  
                    ],
                  }}
                />
              ) : null}
            </div>
            <div style={{ height: "100%" }}>
              {barchart.length > 0 ? (
                <TimeSeriesChart
                  data={{
                    labels: [
                      barchart.length>2?barchart[barchart.length-(barchart.length-0)].month_year:0,
                      barchart.length>3?barchart[barchart.length-(barchart.length-1)].month_year:0,
                      barchart.length>4?barchart[barchart.length-(barchart.length-2)].month_year:0,
                      barchart.length>5?barchart[barchart.length-(barchart.length-3)].month_year:0,
                      barchart.length>6?barchart[barchart.length-(barchart.length-4)].month_year:0,
                      barchart.length>7?barchart[barchart.length-(barchart.length-5)].month_year:0,
                      barchart.length>8?barchart[barchart.length-(barchart.length-6)].month_year:0,
                      barchart.length>9?barchart[barchart.length-(barchart.length-7)].month_year:0,
                      barchart.length>10?barchart[barchart.length-(barchart.length-8)].month_year:0,
                      barchart.length>11?barchart[barchart.length-(barchart.length-9)].month_year:0,
                      barchart.length>12?barchart[barchart.length-(barchart.length-10)].month_year:0,
                      barchart.length>13?barchart[barchart.length-(barchart.length-11)].month_year:0,
                     
                    ],
                    dataset: [
                      barchart.length>2?barchart[barchart.length-(barchart.length-0)].total_transactions:0,
                      barchart.length>3?barchart[barchart.length-(barchart.length-1)].total_transactions:0,
                      barchart.length>4?barchart[barchart.length-(barchart.length-2)].total_transactions:0,
                      barchart.length>5?barchart[barchart.length-(barchart.length-3)].total_transactions:0,
                      barchart.length>6?barchart[barchart.length-(barchart.length-4)].total_transactions:0,
                      barchart.length>7?barchart[barchart.length-(barchart.length-5)].total_transactions:0,
                      barchart.length>8?barchart[barchart.length-(barchart.length-6)].total_transactions:0,
                      barchart.length>9?barchart[barchart.length-(barchart.length-7)].total_transactions:0,
                      barchart.length>10?barchart[barchart.length-(barchart.length-8)].total_transactions:0,
                      barchart.length>11?barchart[barchart.length-(barchart.length-9)].total_transactions:0,
                      barchart.length>12?barchart[barchart.length-(barchart.length-10)].total_transactions:0,
                      barchart.length>13?barchart[barchart.length-(barchart.length-11)].total_transactions:0,
                      
                      ],
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // / backgroundColor: "black",

                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 10,
                      maxLines: 1,
                      fontWeight: "600",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    All Notification
                  </div>
                  <span className="close" onClick={handleCloseModal}>
                    &times;
                  </span>
                </div>

                <div
                  style={{
                    //   height: "86vh",
                    // maxWidth: "18vw",
                    minWidth: "18vw",
                    maxHeight: "78vh",
                    overflowY: "auto",
                    WebkitOverflowScrolling: "touch",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    //  borderLeft: "1px solid grey",

                    marginBottom: 10,
                    //backgroundColor:"black"
                  }}
                >
                  {listx.map((item) => (
                    <div
                      key={item.id}
                      //onClick={() => handleShowLead(item)}
                      style={{
                        // fontSize: 14,
                        color: "#000",
                        fontWeight: 530,
                        textAlign: "left",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                        maxLines: 1,
                        // minWidth:"",
                        // backgroundColor: "black",
                        // borderRadius: 10 /* Adjust border radius as needed */,
                        //border: ".5px solid rgb(194, 190, 190)",
                        padding: 5,
                        marginTop: 5,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",

                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 5,
                            marginRight: 5,
                            height: "3vh",
                            width: "3vh",

                            // // paddingTop: 10,
                          }}
                        >
                          <img
                            style={{
                              height: "4vh",
                              width: "4vh",
                              borderRadius:
                                "3vh" /* Adjust border radius as needed */,
                              border: ".5px solid rgb(194, 190, 190)",
                            }}
                            src={User}
                            alt="olll"

                            // onError={handleErrorImage}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              marginLeft: 10,
                              maxLines: 1,
                              fontWeight: "600",
                              display: "inline-block",
                              whiteSpace: "nowrap",
                              // backgroundColor: "black",
                              overflow: "hidden",
                            }}
                          >
                            {item.title}
                          </div>
                          <div
                            style={{
                              marginLeft: 10,
                              maxWidth: "14vw",
                              whiteSpace: "nowrap",
                              display: "flex",
                              overflow: "hidden",
                            }}
                          >
                            {item.message}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          height: ".5px",
                          backgroundColor: "lightgrey",
                          marginTop: 5,
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboardx;
