import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import assetContext from "../../context/AssetsContext/AssetContext";

const ResellFilterModal = () => {

  const [promise, setPromise] = useState(null);

  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [location, setLocation] = useState([]);
  const [builder, setBuilder] = useState([]);
  const [bhk, setBHK] = useState([]);
  // const [starting_price, setStartingPrice] = useState(0);
  const [maximum_price, setMaximumPrice] = useState(25);

  const [bathrooms, setBathrooms] = useState([]);
  const [balconies, setBalconies] = useState([]);
  const [doorFacing, setDoorFacing] = useState([]);
  const [furnishingStatus, setFurnishingStatus] = useState([]);
  const [parking, setParking] = useState([]);

  const [showAll, setShowAll] = useState(false);

  const handleClick = () => {
    setShowAll(!showAll);
  };

  const context = useContext(assetContext);

  const { getAvailableFilters, availableFilters } = context;

  const bathroomsx = [1, 2, 3, 4, 5, 6, 7, 8];
  const balconiesx = [1, 2, 3, 4, 5, 6, 7, 8];
  const door_facingx = ["East", "West", "North", "South"];
  const furnishing_statusx = ["Furnished", "Unfurnished", "Semi Furnished"];
  const parkingx = ["Two Wheeler", "Four Wheeler", "Both"];

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve({
      property_type: type,
      property_status: status,
      city: location,
      property_builder: builder,
      bedroom: bhk,
      property_price: maximum_price,
      bath_rooms: bathrooms,
      balconies: balconies,
      door_facing: doorFacing,
      furnishing_status: furnishingStatus,
      parking: parking,
    });
    handleClose();
  };

  const handleType = (element) => {
    const arr = toggleElement(type, element);
    setType(arr);
  };

  const handleStatus = (element) => {
    const arr = toggleElement(status, element);
    setStatus(arr);
  };

  const handleLocation = (element) => {
    const arr = toggleElement(location, element);
    setLocation(arr);
  };

  const handleBuilder = (element) => {
    const arr = toggleElement(builder, element);
    setBuilder(arr);
  };

  const handleBHK = (element) => {
    const arr = toggleElement(bhk, element);
    setBHK(arr);
  };

  const handleBathrooms = (element) => {
    const arr = toggleElement(bathrooms, element);
    setBathrooms(arr);
  };

  const handleBalconies = (element) => {
    const arr = toggleElement(balconies, element);
    setBalconies(arr);
  };

  const handleDoorFacing = (element) => {
    const arr = toggleElement(doorFacing, element);
    setDoorFacing(arr);
  };

  const handleFurnishingStatus = (element) => {
    const arr = toggleElement(furnishingStatus, element);
    setFurnishingStatus(arr);
  };

  const handleParking = (element) => {
    const arr = toggleElement(parking, element);
    setParking(arr);
  };

  const handleChange = (event) => {
    setMaximumPrice(parseInt(event.target.value));
  };

  function toggleElement(array, element) {
    const index = array.indexOf(element);

    if (index === -1) {
      // Element is not present, so insert it
      array.push(element);
    } else {
      // Element is present, so remove it
      array.splice(index, 1);
    }

    return [...array]; // Make sure to return a new array to trigger re-render
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // getAssets({ page: currentPage });
      getAvailableFilters();
    } else {
    }

    // eslint-disable-next-line
  }, []);

  const ConfirmationDialog = () => (
    <Dialog open={promise !== null} onClose={handleClose} fullWidth>
      <DialogTitle style={{ fontWeight: "700" }}>Apply Filters</DialogTitle>
      <DialogContent>
        <div
          style={{
            fontFamily: "sans-serif",
          }}
        >
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Property Type
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_type.length > 0
              ? availableFilters.property_type.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: type.includes(item.name)
                        ? "black"
                        : "white",
                      color: type.includes(item.name) ? "white" : "black",
                      borderRadius: 5,
                      fontSize: 12,
                      border: type.includes(item.name)
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "2px 10px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleType(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Property Status
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_status.length > 0
              ? availableFilters.property_status.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: status.includes(item.name)
                        ? "black"
                        : "white",
                      color: status.includes(item.name) ? "white" : "black",
                      borderRadius: 5,
                      fontSize: 12,

                      border: status.includes(item.name)
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "2px 10px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleStatus(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Property Locations
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_location.length > 0
              ? availableFilters.property_location.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: location.includes(item.name)
                        ? "black"
                        : "white",
                      color: location.includes(item.name) ? "white" : "black",
                      borderRadius: 5,
                      fontSize: 12,

                      border: location.includes(item.name)
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "2px 10px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleLocation(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Property Builders
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_builder.length > 0
              ? availableFilters.property_builder
                  .slice(
                    0,
                    showAll ? availableFilters.property_builder.length : 15
                  )
                  .map((item) => (
                    <div
                      key={item.id}
                      style={{
                        backgroundColor: builder.includes(item.name)
                          ? "black"
                          : "white",
                        color: builder.includes(item.name) ? "white" : "black",
                        borderRadius: 5,
                        fontSize: 12,

                        border: builder.includes(item.name)
                          ? "0px solid #d8d0d0"
                          : ".5px solid #d8d0d0",
                        padding: "2px 10px",
                        margin: "5px 10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleBuilder(item.name)}
                    >
                      {item.name}
                    </div>
                  ))
              : null}

            {!showAll &&
              availableFilters !== null &&
              availableFilters.property_builder.length > 15 && (
                <button
                  onClick={handleClick}
                  style={{
                    fontSize: 12,
                    color: "#9379B8",
                    marginLeft: 15,
                    fontWeight: 600,
                  }}
                >
                  Show All {availableFilters.property_builder.length} developers
                </button>
              )}
            {showAll && (
              <button
                onClick={handleClick}
                style={{
                  fontSize: 12,
                  color: "#9379B8",
                  marginLeft: 15,
                  fontWeight: 600,
                }}
              >
                Show Less
              </button>
            )}
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Property BHK
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_bhk.length > 0
              ? availableFilters.property_bhk.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: bhk.includes(item.name)
                        ? "black"
                        : "white",
                      color: bhk.includes(item.name) ? "white" : "black",
                      borderRadius: 5,
                      fontSize: 12,

                      border: bhk.includes(item.name)
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "2px 10px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleBHK(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Bathrooms
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {bathroomsx.map((item) => (
              <div
                key={item}
                style={{
                  backgroundColor: bathrooms.includes(item) ? "black" : "white",
                  color: bathrooms.includes(item) ? "white" : "black",
                  borderRadius: 5,
                  fontSize: 12,

                  border: bathrooms.includes(item)
                    ? "0px solid #d8d0d0"
                    : ".5px solid #d8d0d0",
                  padding: "2px 10px",
                  margin: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => handleBathrooms(item)}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Balconies
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {balconiesx.map((item) => (
              <div
                key={item}
                style={{
                  backgroundColor: balconies.includes(item) ? "black" : "white",
                  color: balconies.includes(item) ? "white" : "black",
                  borderRadius: 5,
                  fontSize: 12,

                  border: balconies.includes(item)
                    ? "0px solid #d8d0d0"
                    : ".5px solid #d8d0d0",
                  padding: "2px 10px",
                  margin: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => handleBalconies(item)}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Door Facing
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {door_facingx.map((item) => (
              <div
                key={item}
                style={{
                  backgroundColor: doorFacing.includes(item)
                    ? "black"
                    : "white",
                  color: doorFacing.includes(item) ? "white" : "black",
                  borderRadius: 5,
                  fontSize: 12,

                  border: doorFacing.includes(item)
                    ? "0px solid #d8d0d0"
                    : ".5px solid #d8d0d0",
                  padding: "2px 10px",
                  margin: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => handleDoorFacing(item)}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Furnishing
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {furnishing_statusx.map((item) => (
              <div
                key={item}
                style={{
                  backgroundColor: furnishingStatus.includes(item)
                    ? "black"
                    : "white",
                  color: furnishingStatus.includes(item) ? "white" : "black",
                  borderRadius: 5,
                  fontSize: 12,

                  border: furnishingStatus.includes(item)
                    ? "0px solid #d8d0d0"
                    : ".5px solid #d8d0d0",
                  padding: "2px 10px",
                  margin: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => handleFurnishingStatus(item)}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6
            style={{
              fontWeight: "bold",
            }}
          >
            Parking
          </h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {parkingx.map((item) => (
              <div
                key={item}
                style={{
                  backgroundColor: parking.includes(item) ? "black" : "white",
                  color: parking.includes(item) ? "white" : "black",
                  borderRadius: 5,
                  fontSize: 12,

                  border: parking.includes(item)
                    ? "0px solid #d8d0d0"
                    : ".5px solid #d8d0d0",
                  padding: "2px 10px",
                  margin: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => handleParking(item)}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <h6>Property Maximum Price</h6>

          <input
            type="range"
            min={0}
            max={25}
            value={maximum_price}
            onChange={handleChange}
            step={1}
            style={{
              width: "100%",
            }}
          />
          <p>Value: 0 to {maximum_price}</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "red", fontWeight: "bold" }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} style={{
          fontWeight:"bold"
        }}>Apply</Button>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};

export default ResellFilterModal;
