import { BASE_URL } from "../constant";
import TicketsContext from "../context/TicketsContext/TicketsContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TicketState = (propsx) => {
  const [tickets, setTickets] = useState([]);

  const [totalTickets, setTotalTickets] = useState(0);

  const [statistics, setStatistics] = useState(null);

  const updateStatusInList = (Id, newStatus, tickets) => {
    return tickets.map((ticket) => {
      if (ticket.id === Id) {
        return { ...ticket, status: newStatus };
      }
      return ticket;
    });
  };

  const UpdateTicketStatus = async (props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${BASE_URL}/tickets/update_status/${props.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ id: props.id, status: props.status }),
          }
        );

        if (response.status === 200) {
          toast.success("Status updated successfully");
          // console.log(props.status);
          const updatedList = updateStatusInList(
            props.id,
            props.status,
            tickets
          );

          setTickets(updatedList);
          //console.log(users);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update lead's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update lead's status, Try again later");
        reject(error);
      }
    });
  };

  const getTicketStatistics = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/tickets/getstatistics`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data[0]);
        //console.log(statistics);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getTickets = async (props) => {
    const { page } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = { limit: limit, offset: offset };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/tickets/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        const parsedData = json.data.map((item) => ({
          ...item,
          media: JSON.parse(item.media), // Convert property_bhk string to JSON
          follow_up: JSON.parse(item.follow_up), // Convert property_bhk string to JSON
        }));
        setTickets(parsedData);
        setTotalTickets(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getSearchTickets = async (props) => {
    const { page, lead_type, status, query_string } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      lead_type: lead_type,
      status: status,

      query_string: query_string,
      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/tickets/search/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        const parsedData = json.data.map((item) => ({
          ...item,
          media: JSON.parse(item.media), // Convert property_bhk string to JSON
          follow_up: JSON.parse(item.follow_up), // Convert property_bhk string to JSON
        }));
        setTickets(parsedData);
        setTotalTickets(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const deleteTickets = async (props) => {
    const { ids, page } = props;

    try {
      const response = await fetch(`${BASE_URL}/tickets/bulk/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ ids: ids }),
      });
      if (response.status === 200) {
        // const json = response.json();
        getTickets({ page: page });
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete lead(s), Try again later");
      }
    } catch (e) {
      toast.error("Couldn't delete lead(s), Try again later");

      // console.log(e.message);
    }
  };

  return (
    <TicketsContext.Provider
      value={{
        tickets,
        totalTickets,
        statistics,

        getTickets,
        getSearchTickets,
        getTicketStatistics,
        deleteTickets,

        UpdateTicketStatus,
      }}
    >
      {propsx.children}
    </TicketsContext.Provider>
  );
};
export default TicketState;
