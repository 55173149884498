import { NavLink } from "react-router-dom";
import { FaBars, FaHome, FaUser, FaCity } from "react-icons/fa";
import { MdMessage, MdAttachMoney, MdArticle } from "react-icons/md";
import { useState } from "react";
import {
  MdReportProblem,
  MdVerifiedUser,
  MdAdminPanelSettings,
} from "react-icons/md";
import { HiUserGroup } from "react-icons/hi2";
import { IoMdAdd } from "react-icons/io";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { IoDocuments } from "react-icons/io5";

import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/dashboard/assets",
    name: "Assets",
    icon: <FaCity />,
    exact: true,
    subRoutes: [
      {
        path: "/dashboard/assets/asset_all",
        name: "All Projects",
        icon: <IoMdAdd />,
      },
      {
        path: "/dashboard/assets/asset_resell_all",
        name: "All Resell Projects",
        icon: <IoMdAdd />,
      },
      {
        path: "/dashboard/assets/asset_type",
        name: "Asset Types",
        icon: <IoMdAdd />,
      },
      {
        path: "/dashboard/assets/asset_status",
        name: "Asset Statuses",
        icon: <IoMdAdd />,
      },
      {
        path: "/dashboard/assets/asset_location",
        name: "Asset Localities",
        icon: <IoMdAdd />,
      },
      {
        path: "/dashboard/assets/asset_landmark",
        name: "Asset Landmarks",
        icon: <IoMdAdd />,
      },
      {
        path: "/dashboard/assets/asset_builder",
        name: "Asset Builders",
        icon: <IoMdAdd />,
      },
      {
        path: "/dashboard/assets/asset_config",
        name: "Asset Configuration",
        icon: <IoMdAdd />,
      },
      {
        path: "/dashboard/assets/asset_amenity",
        name: "Asset Amenities",
        icon: <IoMdAdd />,
      },
    ],
  },
  {
    path: "/dashboard/file-manager",
    name: "File Manager",
    icon: <IoDocuments />,
  },
  {
    path: "/dashboard/users",
    name: "Users",
    icon: <FaUser />,
  },
  {
    path: "/dashboard/builders",
    name: "Builders",
    icon: <FaUser />,
  },
  {
    path: "/dashboard/messages",
    name: "Messages",
    icon: <MdMessage />,
  },

  {
    path: "/dashboard/dispute",
    name: "Dispute/Ticket",
    icon: <MdReportProblem />,
  },
  {
    path: "/dashboard/leads",
    name: "Leads",
    icon: <HiUserGroup />,
  },
  {
    path: "/dashboard/kyc",
    name: "KYC",
    icon: <MdVerifiedUser />,
  },
  {
    path: "/dashboard/admin",
    name: "Admins",
    icon: <MdAdminPanelSettings />,
  },
  {
    path: "/dashboard/transactions",
    name: "Transactions",
    icon: <MdAttachMoney />,
  },
  {
    path: "/dashboard/blogs",
    name: "blogs",
    icon: <MdArticle />,
  },
  // {
  //   path: "/dashboard/settings",
  //   name: "Setting",
  //   icon: <FaCog />,
  // },
  {
    path: "/dashboard/logout",
    name: "Logout",
    icon: <RiLogoutCircleRLine />,
  },
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  // const inputAnimation = {
  //   hidden: {
  //     width: 0,
  //     padding: 0,
  //     transition: {
  //       duration: 0.2,
  //     },
  //   },
  //   show: {
  //     width: "140px",
  //     padding: "5px 15px",
  //     transition: {
  //       duration: 0.2,
  //     },
  //   },
  // };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div>
        {/* <Navbar/> */}

        <div className="main-container">
          <motion.div
            animate={{
              minWidth: isOpen ? "15vw" : "45px",

              transition: {
                duration: 0.5,
                type: "spring",
                damping: 10,
              },
            }}
            className={`sidebar`}
          >
            <div className="top_section">
              <AnimatePresence>
                {isOpen && (
                  <motion.h1
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                  >
                    Agent Admin CRM
                  </motion.h1>
                )}
              </AnimatePresence>

              <div className="bars">
                <FaBars onClick={toggle} />
              </div>
            </div>
            {/* <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div> */}
            <section className="routes">
              {routes.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      key={index}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    // activeClassName="active"
                    style={{
                      textDecoration: "none",
                      //   display:"flex",
                      // flexDirection:"row",
                      // justifyContent: "flex-start",
                    }}
                  >
                    <div className="icon">{route.icon}</div>

                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                          style={{ textDecoration: "none" }}
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
            </section>
          </motion.div>

          <main>{children}</main>
        </div>
      </div>
    </>
  );
};

export default SideBar;
