import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MdOutlineAddHomeWork } from "react-icons/md";

import { IoMdOpen } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import resellAssetContext from "../../context/ResellAssetContext/ResellAssetContext";
import Pagination from "../Pagination";
import useConfirm from "../AlertDialog";

import ResellFilterModal from "./ResellFiltersModal";
import { CiSearch } from "react-icons/ci";

import { AiFillDelete, AiOutlineStock } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoFilter } from "react-icons/io5";

import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";

import ErrorImage from "../../../src/assets/images/empty.png"; // Path to your image file

const AllResellAsset = () => {
  const [checked, setChecked] = useState([]);
  const [query, setQuery] = useState("");

  const [filters, setFilters] = useState(null);

  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const context = useContext(resellAssetContext);

  const {

    resell_assets,
    totalResellAssets,

    getResellAssets,
    getResellSearchAssets,

    getResellAssetStatistics,
    resell_statistics,
    
 
    // addResellAssetx,

    deleteResellAssets,

    getAssetAmenities,

    // UpdateResellAnAsset,

  } = context;

  const [FilterDialog, ApplyFilters] = ResellFilterModal();

  const handleFilters = async () => {
    const filtersx = await ApplyFilters();
    if (filtersx === null) {
    } else {
      // console.log(filtersx);

      setCurrentPage(1);
      getResellSearchAssets({



        page: currentPage,
        property_name: query,
        property_type: filtersx !== null ? filtersx.property_type : [],
        property_status: filtersx !== null ? filtersx.property_status : [],
        city: filtersx !== null ? filtersx.city : [],
        property_builder: filtersx !== null ? filtersx.property_builder : [],
        bedroom: filtersx !== null ? filtersx.bedroom : [],
        property_price: filtersx !== null ? filtersx.property_price : 25,
        bath_rooms: filtersx !== null ? filtersx.bath_rooms : [],
        balconies: filtersx !== null ? filtersx.balconies : [],
        door_facing: filtersx !== null ? filtersx.door_facing : [],
        furnishing_status: filtersx !== null ? filtersx.furnishing_status : [],
        parking: filtersx !== null ? filtersx.parking : [],
 
        
      });
      setFilters({
        property_type: filtersx !== null ? filtersx.property_type : [],
        property_status: filtersx !== null ? filtersx.property_status : [],
        city: filtersx !== null ? filtersx.city : [],
        property_builder: filtersx !== null ? filtersx.property_builder : [],
        bedroom: filtersx !== null ? filtersx.bedroom : [],
        property_price: filtersx !== null ? filtersx.property_price : 25,
        bath_rooms: filtersx !== null ? filtersx.bath_rooms : [],
        balconies: filtersx !== null ? filtersx.balconies : [],
        door_facing: filtersx !== null ? filtersx.door_facing : [],
        furnishing_status: filtersx !== null ? filtersx.furnishing_status : [],
        parking: filtersx !== null ? filtersx.parking : [],
 
      });
      // console.log(filters);
    }
  };

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Project!",
    `Are you sure you want to delete ${
      checked.length > 1 ? "these projects" : "this project"
    } : ${checked}? `
  );
  const handleDelete = async () => {
    const ans = await confirmDelete();
    if (ans) {
      handleDeleteUsers();
    } else {
      /* ... */
    }
  };
  const handleClick = () => {
    // Add your click event logic here
    // getAvailableFilters();

    navigate("/dashboard/assets/asset_add");
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (localStorage.getItem("token")) {
      query.length > 0 || filters !== null
        ? getResellSearchAssets({
            page: currentPage,

            property_type: filters !== null ? filters.property_type : [],
            property_status: filters !== null ? filters.property_status : [],
            city: filters !== null ? filters.city : [],
            property_builder: filters !== null ? filters.property_builder : [],
            bedroom: filters !== null ? filters.bedroom : [],
            property_price: filters !== null ? filters.property_price : 25,
            bath_rooms: filters !== null ? filters.bath_rooms : [],
            balconies: filters !== null ? filters.balconies : [],
            door_facing: filters !== null ? filters.door_facing : [],
            furnishing_status: filters !== null ? filters.furnishing_status : [],
            parking: filters!== null ? filters.parking : [],
        
            property_name: query,
          })
        : getResellAssets({ page: newPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const handleDeleteUsers = async () => {
    if (localStorage.getItem("token")) {
      await deleteResellAssets({ ids: checked, page: currentPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const assetData = [
    {
      key: "Total Assets in DB",
      value: resell_statistics !== null ? resell_statistics.location[0].total_assets : 0,
    },
    {
      key: "Assets in Bengaluru",
      value:
      resell_statistics !== null
          ? resell_statistics.location[0].total_assets_in_bengaluru
          : 0,
    },
    {
      key: "Assets in Bhubaneswar",
      value:
      resell_statistics !== null
          ? resell_statistics.location[0].total_assets_in_bhubaneswar
          : 0,
    },
  ];

  const logData = [
    {
      key: "Apartment",
      value:
      resell_statistics !== null ? resell_statistics.types[0].total_assets_as_apartment : 0,
    },
    {
      key: "Villa",
      value:
      resell_statistics !== null ? resell_statistics.types[0].total_assets_as_villa : 0,
    },
    {
      key: "Plot",
      value: resell_statistics !== null ? resell_statistics.types[0].total_assets_as_plot : 0,
    },
    {
      key: "Farm House",
      value:
      resell_statistics !== null ? resell_statistics.types[0].total_assets_as_farmhouse : 0,
    },
  ];

  const logData2 = [
    {
      key: "New Launch",
      value:
      resell_statistics !== null
          ? resell_statistics.status[0].total_assets_as_newlaunch
          : 0,
    },
    {
      key: "Under Construction",
      value:
      resell_statistics !== null
          ? resell_statistics.status[0].total_assets_as_underconstruction
          : 0,
    },
    {
      key: "Ready to Move",
      value:
      resell_statistics !== null
          ? resell_statistics.status[0].total_assets_as_readytomove
          : 0,
    },
  ];

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    //console.log(updatedList);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);

    getResellSearchAssets({
      page: currentPage,

      property_type: filters !== null ? filters.property_type : [],
      property_status: filters !== null ? filters.property_status : [],
      city: filters !== null ? filters.city : [],
      property_builder: filters !== null ? filters.property_builder : [],
      bedroom: filters !== null ? filters.bedroom : [],
      property_price: filters !== null ? filters.property_price : 25,
      bath_rooms: filters !== null ? filters.bath_rooms : [],
      balconies: filters !== null ? filters.balconies : [],
      door_facing: filters !== null ? filters.door_facing : [],
      furnishing_status: filters !== null ? filters.furnishing_status : [],
      parking: filters!== null ? filters.parking : [],
  
      property_name: query,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getResellAssets({ page: currentPage });
      getResellAssetStatistics();
    } else {
    }

    // eslint-disable-next-line
  }, []);

  const openProject = ( slug) => {
    window.open(`https://resell.properties/property/${slug}`, "_blank");
  };
  
  const updateProject = async (propertyx) => {
    if (
      propertyx &&
      propertyx.aminities &&
      propertyx.aminities.aminities.length > 0
    ) {
      await getAssetAmenities({ ids: propertyx.aminities.aminities });
    }

    navigate("/dashboard/assets/asset_resell_update", {
      state: { projectDetails: propertyx },
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            margin: 0,
            maxWidth: "100%",
          }}
        >
          <ToastContainer position="top-center" />
          {/* <Modal isOpen={isOpen} onClose={closeModal} user={selectedUser} /> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 15,
            }}
          >
            <div
              style={{
                fontSize: 18,
                fontWeight: 700
              }}
            >
              All Assets({totalResellAssets})
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <form
                className="form_search"
                onSubmit={handleSubmit}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  className="serach_asset"
                  variant="outlined"
                  placeholder="Search assets..."
                  value={query}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton type="submit" edge="start">
                        <CiSearch />
                      </IconButton>
                    ),
                    style: {
                      height: "32px",
                      minWidth: "30vw", // Adjust the height as needed
                      flexGrow: 1, // Allow the input to grow to fill available space
                    },
                  }}
                  fullWidth
                />
              </form>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  //disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleFilters}
                >
                  <IoFilter color="grey" />
                </IconButton>
                <FilterDialog />
              </div>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  marginRight: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleDelete}
                >
                  <AiFillDelete color={checked.length === 0 ? "grey" : "red"} />
                </IconButton>
                <Dialog />
              </div>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  marginRight: 15,
                  padding: "0px 15px",
                  backgroundColor: "#00073D",
                  color: "white",
                  fontWeight: 700,

                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
                onClick={handleClick}
              >
                <IconButton type="submit" edge="start">
                  <MdOutlineAddHomeWork color="white" />
                </IconButton>
                Add An Asset
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 15,
              fontFamily: "monospace",
              fontWeight: 550,
              textAlign: "left",
              maxWidth: "100%",
              paddingTop: 10,
              paddingLeft: 20,
              color: "grey",
              alignItems: "center",
              justifySelf: "start",
              height: 35,
            }}
          >
            <div
              style={{
                width: "3vw",
                height: 50,
                fontWeight: 600,
                marginLeft: 15,
                color:"black"
              }}
            >
              ID
            </div>
            <div
              style={{
                width: "12vw",
                height: 50,
                maxLines: 1,
                color:"black"

              }}
            >
              Name
            </div>
            <div
              style={{
                width: "5vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                color:"black"

                // backgroundColor: "black",
              }}
            >
              Type
            </div>
            <div
              style={{
                width: "7vw",
                height: 50,
                maxLines: 1,
                color:"black",

                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Status
            </div>
            <div
              style={{
                width: "7vw",
                height: 50,
                maxLines: 1,
                color:"black",

                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Location
            </div>
            {/* <div
              style={{
                width: "6vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Locality
            </div> */}

            <div
              style={{
                width: "4vw",
                height: 50,
                maxLines: 1,
                color:"black",

                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              BHKs
            </div>

            <div
              style={{
                width: "8vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                color:"black",

                // backgroundColor: "black",
              }}
            >
              Builder
            </div>
          
            <div
              style={{
                width: "6vw",
                height: 50,
                color:"black",

                maxLines: 1,
                marginRight: 15,
                // backgroundColor: "black",
              }}
            >
              Price
            </div>
            <div
              style={{
                width: "6vw",
                color:"black",

                height: 50,
                maxLines: 1,
                marginRight: 15,
                // backgroundColor: "black",
              }}
            >
              Actions
            </div>
          </div>

          <div
            style={{
              minHeight: "78vh",
              // justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              marginLeft: 15,

              alignItems: "center",
            }}
          >
            {resell_assets.length > 0 ? (
              resell_assets.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    maxWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                    backgroundColor: "transparent",
                    fontWeight: 530,
                    textAlign: "left",
                    fontFamily: "Montserrat",
                    fontSize: 12,

                    maxLines: 1,
                    // backgroundColor: "black",
                    // borderRadius: 10 /* Adjust border radius as needed */,
                    borderBottom: ".5px solid #d8d0d0",
                    // paddingTop: 10,
                    marginTop: 15,
                  }}
                >
                  <input
                    value={item.id}
                    type="checkbox"
                    onChange={handleCheck}
                    style={{
                      alignContent: "start",
                      backgroundColor: "#000",
                      marginLeft: "0px",
                      // marginBottom: "30px",
                    }}
                  />

                  <div
                    style={{
                      width: "3vw",
                      height: 50,
                      fontWeight: 600,
                      marginLeft: 5,
                      alignContent: "center",
                      // paddingTop: "15px",
                      //backgroundColor: "black"
                    }}
                  >
                    {item.id}
                  </div>

                  <div
                    style={{
                      width: "12vw",
                      height: 50,
                      //  marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",
                      fontWeight: 700,

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.property_name}
                  </div>

                  <div
                    style={{
                      width: "5vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.property_type}
                  </div>

                  <div
                    style={{
                      width: "7vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.property_status}
                  </div>
                  <div
                    style={{
                      width: "7vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.city}
                  </div>
                  {/* <div
                    style={{
                      width: "6vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.property_locality}
                  </div> */}

                  <div
                    style={{
                      width: "4vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      display: "flex",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.bedroom}
                  </div>
                  <div
                    style={{
                      width: "8vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.property_builder}
                  </div>
                 
                  <div
                    style={{
                      width: "6vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",
                      fontWeight: 700,

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.property_price}Cr.
                  </div>

                  <div
                    style={{
                      width: "6vw",
                      height: 50,
                      marginRight: 15,
                      maxLines: 1,
                      //  paddingTop: "15px",

                      display: "flex",
                      justifyContent: "space-around",
                      whiteSpace: "nowrap",
                      // backgroundColor: "white",
                      // overflow: "hidden",
                    }}
                  >
                    <IconButton
                      onClick={() => updateProject(item)}
                      size="small"
                      type="submit"
                      edge="start"
                    >
                      <FaRegEdit />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        openProject( item.slug )
                      }
                      size="small"
                      type="submit"
                      edge="start"
                    >
                      <IoMdOpen />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  marginTop: "20vh",
                  justifyContent: "center",
                  //backgroundColor: "black",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    marginTop: 15,
                    width: "10vw",
                  }}
                  alt=""
                  src={ErrorImage}
                />
                <div
                  style={{
                    marginTop: 30,
                    fontSize: 18,

                    fontFamily: "Montserrat",
                  }}
                >
                  Zero record not found in database
                </div>
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 15,

                    fontFamily: "Montserrat",
                  }}
                >
                  Try a different query or filter
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div
              style={{
                marginTop: 15,
                marginLeft: 15,
                marginBottom: 10,
              }}
            >
              Showing {resell_assets.length} out of {totalResellAssets}
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalResellAssets / 10)}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
          
        </div>
        <div>
          <div>
            <Card
              style={{
                minWidth: 300,
                maxWidth: 400,
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    Locations Statistics
                  </Typography>
                  <IconButton aria-label="edit">
                    <AiOutlineStock color="white" />
                  </IconButton>
                </div>
                {assetData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>{item.key}</Typography>
                    <Typography>{item.value}</Typography>
                  </div>
                ))}
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              style={{
                minWidth: 300,
                maxWidth: 400,
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    Types Statistics
                  </Typography>
                </div>
                {logData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>{item.key}</Typography>
                    <Typography>{item.value}</Typography>
                  </div>
                ))}
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              style={{
                minWidth: 300,
                maxWidth: 400,
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    Status Statistics
                  </Typography>
                </div>
                {logData2.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>{item.key}</Typography>
                    <Typography>{item.value}</Typography>
                  </div>
                ))}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllResellAsset;
