import React from "react";
import AuthProvider from "./provider/AuthProvider";
import Routesx from "./routes/routes";
import UserState from "./provider/UserProviders";
import BuilderState from "./provider/BuilderProvider";
import DshboardState from "./provider/DashboardProvider";
import AssetState from "./provider/AssetProvider";
import AssetTypeState from "./provider/AssetTypeProvider";
import AssetStatusState from "./provider/AssetStatusProvider";
import AssetLocalityState from "./provider/AssetLocationProvider";
import AssetBuilderState from "./provider/AssetBuilderProvider";
import AssetAmenityState from "./provider/AssetAmenityProvider";
import AssetBHKState from "./provider/AssetBHKProvider";
import FileManagerState from "./provider/FileManagerProvider";
import LeadState from "./provider/LeadsProvider";
import TicketState from "./provider/TicketProvider";
import TransactionState from "./provider/TransactionProvider";
import AdminState from "./provider/AdminProvider";
import MessageState from "./provider/MessageProvider";
import BlogsState from "./provider/BlogsPrivider";
import ResellAssetState from "./provider/ResellAssetProvider";
import AssetLandmarkState from "./provider/AssetLandmarkProvider";

function App() {
  return (
    <AuthProvider>
      <AdminState>
        <DshboardState>
          <BlogsState>
            <AssetState>
              <ResellAssetState>
                <AssetTypeState>
                  <AssetStatusState>
                    <AssetLocalityState>
                      <AssetLandmarkState>
                      <AssetBuilderState>
                        <AssetBHKState>
                          <AssetAmenityState>
                            <FileManagerState>
                              <LeadState>
                                <TicketState>
                                  <MessageState>
                                    <UserState>
                                      <TransactionState>
                                        <BuilderState>
                                          <Routesx />
                                        </BuilderState>
                                      </TransactionState>
                                    </UserState>
                                  </MessageState>
                                </TicketState>
                              </LeadState>
                            </FileManagerState>
                          </AssetAmenityState>
                        </AssetBHKState>
                      </AssetBuilderState>
                      </AssetLandmarkState>
                    </AssetLocalityState>
                  </AssetStatusState>
                </AssetTypeState>
              </ResellAssetState>
            </AssetState>
          </BlogsState>
        </DshboardState>
      </AdminState>
    </AuthProvider>
  );
}

export default App;
