import { BASE_URL } from "../constant";
import AssetAmenityContext from "../context/AssetsContext/AssetAmenityContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AssetAmenityState = (propsx) => {
  const [assetsAmenities, setAssetsAmenities] = useState([]);
  const [statistics, setStatistics] = useState([]);

  const getAssetAmenitiesStatistics = async (props) => {
    try {
      const response = await fetch(
        `${BASE_URL}/filter/pamenity/get_analytics`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data);
        //console.log(statistics);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAssetsAmenities = async (props) => {
    // API Call

    try {
      const response = await fetch(`${BASE_URL}/filter/pamenity/get`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);

        const parsedData = json.data.map((item) => ({
          ...item,
          icon: JSON.parse(item.icon), // Convert property_bhk string to JSON
        }));
        //   console.log(parsedData);
        setAssetsAmenities(parsedData);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const createAssetAmenity = async (props) => {
    const { name, description, category, icon } = props;
    // {
    //     "name":"Farm House",
    //     "description":"",
    //     "icon":""
    // }

    try {
      const response = await fetch(`${BASE_URL}/filter/pamenity/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: name,
          description: description,
          category: category,
          icon: icon === null ? null : JSON.stringify(icon),
        }),
      });

      if (response.status === 200) {
        getAssetsAmenities();
        toast.success("Asset amenity created successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log(response.error);
        toast.error("Couldn't Add asset amenity, Try again later");
      }
    } catch (e) {
      toast.error("Couldn't Add asset amenity, Try again later");
    }
  };

  const updateAssetAmenity = async (props) => {
    const { id, name, description, category, icon } = props;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${BASE_URL}/filter/pamenity/update/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              name: name,
              description: description,
              category: category,
              icon: icon === null ? null : JSON.stringify(icon),
            }),
          }
        );

        if (response.status === 200) {
          toast.success("Asset amenity updated successfully");

          getAssetsAmenities();
          //console.log(users);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update amenity, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update amenity, Try again later");
        reject(error);
      }
    });
  };

  const deleteAssetsAmenity = async (props) => {
    const { id } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/filter/pamenity/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        // const json = response.json();
        getAssetsAmenities();
        toast.success("Amenity deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete amenity, Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete amenity, Try again later");

      // console.log(e.message);
    }
  };

  return (
    <AssetAmenityContext.Provider
      value={{
        statistics,
        getAssetAmenitiesStatistics,

        assetsAmenities,
        getAssetsAmenities,

        createAssetAmenity,
        updateAssetAmenity,
        deleteAssetsAmenity,
      }}
    >
      {propsx.children}
    </AssetAmenityContext.Provider>
  );
};
export default AssetAmenityState;
