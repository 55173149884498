import React, { useState, useContext, useEffect } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import assetLocationContext from "../../../context/AssetsContext/AssetsLocationContext";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddHomeWork } from "react-icons/md";
import {
  IconButton,
  TextField,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { CiSearch } from "react-icons/ci";

import CreatableSelect from "react-select/creatable";

const AllProjectLocations = (props) => {
  const [query, setQuery] = useState("");

  const context = useContext(assetLocationContext);
  const {
    statistics,
    getAssetLocalityStatistics,

    assetsLocalities,
    getAssetsLocalities,

    assetsLocations,
    getAssetsLocations,
    getSearchAssetsLocalities,

    createAssetLocality,
    updateAssetLocality,
    deleteAssetsLocality,
  } = context;

  const [selectedValue, setSelectedValue] = useState(null);

  const [selectedAssetLocality, setSelectedAssetLocality] = useState({
    id: 0,
    name: "",
    description: "",
    location: "",
    // icon: JSON.stringify({})
  });
  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getSearchAssetsLocalities({ name: query });
  };
  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };
  const [isAdding, setIsAdding] = useState(true);

  const handleIsAdding = (pt, val) => {
    setSelectedAssetLocality({
      id: pt.id,
      name: pt.name,
      description: pt.description !== null ? pt.description : "",
      location: pt.location !== null ? pt.location : "",
    });
    setIsAdding(val);
    setSelectedValue({
      label: pt.location !== null ? pt.location : "",
      value: pt.location !== null ? pt.location : "",
    });
  };

  const handleData = (key, value) => {
    setSelectedAssetLocality((prevItem) => ({
      ...prevItem,
      [key]: value !== null ? value : "",
    }));
    if (key === "location") {
      setSelectedValue({
        label: value !== null ? value : "",
        value: value !== null ? value : "",
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAssetsLocations();
      getAssetsLocalities();
      getAssetLocalityStatistics();
    } else {
    }

    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    if (selectedAssetLocality.name.length > 0) {
      if (isAdding) {
        createAssetLocality(selectedAssetLocality);
      } else {
        updateAssetLocality(selectedAssetLocality);
      }
      setSelectedAssetLocality({
        id: 0,
        name: "",
        description: "",
        location: "",
        // icon: JSON.stringify({})
      });
    } else {
      toast.error("Please enter valid name and description");
    }
  };

  const handleDelete = async (idx) => {
    deleteAssetsLocality({ id: idx });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(196, 196, 196, 0.15)",
          margin: 0,
          width: "80vw",

          height: "98vh",
          maxHeight: "98vh", // Set maximum height to screen height
          overflowY: "auto", // Enable vertical scrolling if content exceeds height
          border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <ToastContainer position="top-center" />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: 15,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 600 }}>
            {isAdding ? "Add A Locality" : "Update A Locality"}
          </div>

          <div style={{ display: "flex" }}>
            <form
              style={{
                maxWidth: "60vw",
                minWidth: "55vw",
                marginTop: 25,
                marginRight: 25,
                backgroundColor: "white",
                padding: 20,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{}}>
                  <label style={{ marginBottom: "10px", flex: 1 }}>
                    Enter Property Locality
                  </label>
                  <TextField
                    id="propertyName"
                    placeholder="Enter Locality Name" // Hint text
                    variant="outlined"
                    value={selectedAssetLocality.name}
                    fullWidth
                    onChange={(e) => handleData("name", e.target.value)}
                    style={{ backgroundColor: "white" }}
                    inputProps={{ style: { height: 9, minWidth: "20vw" } }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
                <div style={{ marginRight: "10px" }}>
                  <label style={{ marginBottom: "10px", flex: 1 }}>
                    Property Location
                  </label>
                  <CreatableSelect
                    // isMulti
                    styles={{ flex: 1, width: 400 }}
                    placeholder="Select Property Location....."
                    onChange={(e) => handleData("location", e.value)}
                    value={selectedValue}
                    options={
                      assetsLocations !== null
                        ? assetsLocations.map((option) => ({
                            label: option.name,
                            value: option.name,
                          }))
                        : []
                    }
                  />
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    backgroundColor: "white",

                    height: 120,
                    width: 120,

                    // justifyContent: "center",
                  }}
                >
                  {!selectedImage && (
                    <div>
                      <label style={{ marginBottom: "10px", flex: 1 }}>
                        Icon/Logo
                      </label>
                      <div>
                        <label
                          htmlFor="fileInput"
                          style={{
                            cursor: "pointer",
                            display: "inline-block",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: 5,
                          }}
                        >
                          Choose File
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleImageChange}
                          style={{
                            display: "none",
                            borderRadius: 10,
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {selectedImage && (
                    <div style={{ alignItems: "center" }}>
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "5px",
                          position: "relative",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleClearImage}
                        >
                          <MdCancel size={25} />
                        </span>
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{
                            width: "108px",
                            height: "120px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
              <div>
                <label style={{ marginBottom: "10px", flex: 1, marginTop: 30 }}>
                  Locality/Landmark Description(optional)
                </label>
                <TextField
                  id="propertyName"
                  placeholder="Enter Description" // Hint text
                  variant="outlined"
                  onChange={(e) => handleData("description", e.target.value)}
                  value={selectedAssetLocality.description}
                  fullWidth
                  multiline
                  rows={5}
                  style={{ backgroundColor: "white" }}
                  inputProps={{ style: { height: 100, width: 500 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>
              <div
                style={{
                  maxWidth: "13vw",
                  border: "1px solid #ffffff",
                  borderRadius: "6px",
                  color: "white",
                  margin: "15px 0px",
                  padding: "5px 10px",
                  backgroundColor: "#21232d",

                  textAlign: "center",
                }}
                onClick={handleSubmit}
                // onClick={handleClick}
              >
                <IconButton type="submit" edge="start">
                  <MdOutlineAddHomeWork color="white" />
                </IconButton>
                {isAdding ? "Add this Locality" : "Update Locality"}
              </div>
            </form>

            <div
              style={{
                margin: "15px 25px",
              }}
            >
              <Card
                style={{
                  minWidth: "20vw",
                  minHeight: "40vh",
                  margin: "auto",
                  marginTop: 10,
                  marginLeft: 15,
                  backgroundColor: "#21232d",
                  borderRadius: 15,
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ color: "white", fontWeight: 600 }}
                    >
                      Project Locations
                    </Typography>
                    {/* <IconButton aria-label="edit">
                <AiOutlineStock color="white" />
              </IconButton> */}
                  </div>
                  {statistics.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                        color: "white",
                      }}
                    >
                      <Typography>{item.property_locations}</Typography>
                      <Typography>{item.project_count}</Typography>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: "15px 20px",
            margin: "0px 0px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5>All Locations/Localities ({assetsLocalities.length})</h5>
            <form
              className="form_search"
              onSubmit={handleSearchSubmit}
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                className="serach_asset"
                variant="outlined"
                placeholder="Search assets..."
                value={query}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <IconButton type="submit" edge="start">
                      <CiSearch />
                    </IconButton>
                  ),
                  style: {
                    height: "32px",
                    minWidth: "30vw", // Adjust the height as needed
                    flexGrow: 1, // Allow the input to grow to fill available space
                  },
                }}
                fullWidth
              />
            </form>
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(15vw, 1fr))",
              gap: "10px",
            }}
          >
            {assetsLocalities.map((pt, index) => (
              <div
                key={pt.id}
                onClick={() => handleIsAdding(pt, false)}
                style={{
                  //  padding: "7px",
                  // height: "100px",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "8px",
                  //  width: "15vw",
                  display: "flex",
                  marginRight: 14,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      width: "14vw",
                      //   backgroundColor:"black",
                      fontWeight: "bold",
                      marginRight: "30px",
                      marginBottom: 5,
                    }}
                  >
                    {pt.name}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      // backgroundColor: "black",
                      alignItems: "center",

                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 13,

                        maxHeight: 45,
                        // backgroundColor:"black",
                        overflowY: "hidden",
                      }}
                    >
                      {pt.location === null || pt.location === ""
                        ? "Location not found"
                        : pt.location}
                    </div>
                    <div
                      style={{
                        backgroundColor: "rgba(192, 192, 192,0.4 )",
                        alignContent: "center",
                        justifyContent: "center",
                        borderRadius: 7,
                      }}
                    >
                      {/* <IconButton size="small" onClick={handleEditClick}>
                        <FiEdit2 style={{ cursor: "pointer" }} />
                      </IconButton> */}

                      <IconButton
                        onClick={() => handleDelete(pt.id)}
                        size="small"
                        style={{ color: "red" }}
                      >
                        <AiOutlineDelete style={{ cursor: "pointer" }} />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjectLocations;
