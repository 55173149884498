// validateAssets.js

function validateAssets(assets) {
  const errors = [];

  // Validate property_name
  if (!assets.property_name.trim()) {
    errors.push("Property name is required") ;
  }

  // Validate status
  if (typeof assets.status !== "number" || ![0, 1].includes(assets.status)) {
    errors.push("Status must be either 0 or 1") ;
 
  }

  // Validate property_type
  if (!assets.property_type.trim()) {
    errors.push("Property type is required") ;

   }
   if (!assets.property_status.trim()) {
    errors.push("Property status is required") ;

   }
   
  // Validate property_status
  // if (!assets.property_status.trim()) {
  //  errors.push("Property status is required") ;
  // }

  // Validate property_location

  if (!assets.property_location.trim()) {
    errors.push("Property location is required") ;

   }

  // Validate property_builder
  if (!assets.property_builder.trim()) {
    errors.push("Property builder is required") ;

   }

  // Validate property_starting_price
  if (
    typeof assets.property_starting_price !== "number" ||
    isNaN(assets.property_starting_price) ||
    assets.property_starting_price <= 0
  ) {
    errors.push( "Starting price must be a non-negative number") ;

     
  }

  // Validate property_maximum_price
  if (
    typeof assets.property_maximum_price !== "number" ||
    isNaN(assets.property_maximum_price) ||
    assets.property_maximum_price <= 0
  ) {
    errors.push("Maximum price must be a non-negative number") ;

     
  }

  // Validate brochure
  if (!assets.brochure.trim()) {
    errors.push("Brochure is required") ;

   }

  // Parse the JSON string into a JavaScript object
  const parsedStoryImages = JSON.parse(assets.story_images);

  // Check if the 'images' property is an empty object
  if (Object.keys(parsedStoryImages.images).length === 0) {
    errors.push("Story images are required");
   }

  // Validate cover_image
  if (!assets.cover_image.trim() || assets.cover_image.length < 0) {
    errors.push( "Cover image is required") ;

   }

  // Validate admin_id
  // if (typeof assets.admin_id !== "number" || isNaN(assets.admin_id) || assets.admin_id <= 0) {
  //   errors.admin_id = "Admin ID must be a positive number";
  // }

  // Validate project_rera
  if (!assets.project_rera.trim()) {
    errors.push("Project RERA is required") ;

   }

  // // Validate homznoffiz_rera
  // if (!assets.homznoffiz_rera.trim()) {
  //   errors.homznoffiz_rera = "Homznoffiz RERA is required";
  // }

  // Validate is_exclusive
  // if (typeof assets.is_exclusive !== "number" || ![0, 1].includes(assets.is_exclusive)) {
  //   errors.is_exclusive = "Is exclusive must be either 0 or 1";
  // }

  // Validate description
  if (!assets.description.trim()) {
    errors.push("Description is required") ;

   }

  // Validate latitude
  if (
    typeof assets.latitude !== "number" ||
    isNaN(assets.latitude) ||
    assets.latitude < -90 ||
    assets.latitude > 90
  ) {
    errors.push("Latitude must be a number between -90 and 90") ;

   }

  // Validate longitude
  if (
    typeof assets.longitude !== "number" ||
    isNaN(assets.longitude) ||
    assets.longitude < -180 ||
    assets.longitude > 180
  ) {
    errors.push("Longitude must be a number between -180 and 180") ;

   }

  // Validate property_units
  if (
    typeof assets.property_units !== "number" ||
    isNaN(assets.property_units) ||
    assets.property_units <= 0
  ) {
    errors.push("Property units must be a positive number") ;

 
  }

  // Validate property_size
  if (
    typeof assets.property_size !== "number" ||
    isNaN(assets.property_size) ||
    assets.property_size <= 0
  ) {
    errors.push("Property size must be a positive number") ;

   }

  // Validate property_locality
  if (!assets.property_locality.trim()) {
    errors.push("Property locality is required") ;

   }

  // Validate property_bhks
  if (!Object.keys(JSON.parse(assets.property_bhks)).length) {
    errors.push( "Property BHKs are required") ;

   }

  const parsedPropertyImages = JSON.parse(assets.property_images);

  // Check if the 'images' property is an empty array
  if (parsedPropertyImages.images.length === 0) {
    errors.push( "Property images are required") ;

   }

  // Validate marketing_materials
  // if (!Object.keys(JSON.parse(assets.marketing_materials)).length) {
  //   errors.marketing_materials = "Marketing materials are required";
  // }

  // Validate property_video
//   if (!assets.property_video.trim()) {
//     errors.push("Property video is required") ;

//    }

  // Validate floor_plans
  if (!Object.keys(JSON.parse(assets.floor_plans)).length) {
    errors.push("Floor plans are required") ;

   }

  // Validate aminities
  if (!Object.keys(JSON.parse(assets.aminities)).length) {
    errors.push( "Aminities are required") ;

   }

  // Validate connecting_roads
  // if (!Object.keys(JSON.parse(assets.connecting_roads)).length) {
  //   errors.connecting_roads = "Connecting roads are required";
  // }

  // Validate faqs
  if (!Object.keys(JSON.parse(assets.faqs)).length) {
    errors.push("FAQs are required") ;

   }

  // // Validate rating_details
  // if (!Object.keys(JSON.parse(assets.rating_details)).length) {
  //   errors.rating_details = "Rating details are required";
  // }

  // // Validate specification_rooms
  // if (!Object.keys(JSON.parse(assets.specification_rooms)).length) {
  //   errors.specification_rooms = "Specification rooms are required";
  // }

  // Validate agents
  // if (!Object.keys(JSON.parse(assets.agents)).length) {
  //   errors.agents = "Agents are required";
  // }

  // Validate landmarks
  if (!Object.keys(JSON.parse(assets.landmarks)).length) {
    errors.push("Landmarks are required") ;

   }

  return errors;
}

module.exports = validateAssets;
