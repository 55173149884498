import { BASE_URL } from "../constant";
import DashboardContext from "../context/DashboardContext/DashboardContext";
import { useState } from "react";
 
const DshboardState = (propsx) => {

  const [statistics, setStatistics] = useState(null);
  const [barchart, setBarchart] = useState([]);
  const [linechart, setLinechart] = useState([]);
  const [assetDonutchart, setAssetDonutchart] = useState([]);
  const [leadsDonutchart, setLeadsDonutchart] = useState([]);
 
 
  const getStatistics = async (props) => {
 
    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/transaction/get/dashboardinfo`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data[0]);
       } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getTransactionsBarchart = async (props) => {
 
    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/transaction/get/barchart`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setBarchart(json.data);
       } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  const getTransactionsLinechart = async (props) => {
 
    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/transaction/get/linechart`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setLinechart(json.data);
       } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  const getAssetDonutchart = async (props) => {
 
    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/asset/get/donutchart`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setAssetDonutchart(json.data);
       } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  const getLeadDonutchart = async (props) => {
 
    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/lead/get/donutchart`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setLeadsDonutchart(json.data);
       } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
 
  return (
    <DashboardContext.Provider
      value={{
        getStatistics,
        statistics,
        getTransactionsBarchart,
        barchart,
        getTransactionsLinechart,
        linechart,
        getAssetDonutchart,
        assetDonutchart,
        getLeadDonutchart,
        leadsDonutchart
      }}
    >
      {propsx.children}
    </DashboardContext.Provider>
  );
};

export default DshboardState;
