import React, { useState, useContext, useEffect } from "react";

import adminContext from "../../context/AdminContext/AdminContext";
import Pagination from "../.././components/Pagination";
import useConfirm from "../AlertDialog";
import { CiSearch } from "react-icons/ci";
import { AiFillDelete } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { IconButton, TextField } from "@mui/material";
import ErrorImage from "../../../src/assets/images/error.png"; // Path to your image file
import CreatableSelect from "react-select/creatable";

const AllAdmins = () => {
  const [checked, setChecked] = useState([]);
  const [query, setQuery] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isButtonEnabled] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(adminContext);
  const {
    admins,
    totalAdmins,
    getSearchAdmins,

    CreateAdmin,
    UpdateAdmin,

    deleteAdmins,
  } = context;

  const [admin, setAdmin] = useState({
    id: 0,
    name: "",
    phone: "",
    email: "",
    password: "",
    access: JSON.stringify({}),
    image: "",
  });

  const handleData = (key, value) => {
    setAdmin((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };
  const ptypes = [
    { id: 1, value: 1, label: "GET" },
    { id: 2, value: 2, label: "POST" },
    { id: 3, value: 3, label: "PATCH" },
    { id: 4, value: 4, label: "DELETE" },
  ];
  // let navigate = useNavigate();

  const [access, setAccess] = useState([]);

  const handleCreateOrUpdate = () => {
    if (isUpdating) {
      UpdateAdmin(admin);
    } else {
      CreateAdmin(admin);
    }
  };

  const handleSelectAccess = (newValue, actionMeta) => {
    // Check the action performed
    if (actionMeta.action === "select-option") {
      setAccess((prevItems) => [
        ...prevItems,
        {
          id: actionMeta.option.id,
          name: actionMeta.option.label,
        },
      ]);
    } else if (actionMeta.action === "remove-value") {
      setAccess(
        access.filter((item) => item.id !== actionMeta.removedValue.id)
      );
      //console.log("Option removed - ID:", actionMeta.removedValue.id);
    }
    // You can access all selected information from the newValue array
  };

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Admins!",
    `Are you sure you want to delete ${
      checked.length > 1 ? "these admins" : "this admin"
    } : ${checked}? `
  );

  const handleDelete = async () => {
    const ans = await confirmDelete();
    if (ans) {
      handleDeleteUsers();
    } else {
      /* ... */
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (localStorage.getItem("token")) {
      getSearchAdmins({
        page: currentPage,

        query_string: query,
      });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const handleDeleteUsers = async () => {
    if (localStorage.getItem("token")) {
      await deleteAdmins({ ids: checked, page: currentPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const setUpdateData = (item) => {
    if (JSON.parse(localStorage.getItem("me")).is_super_admin === 0) {
      toast.error("Only super admin can add/update data");
    } else {
      setAdmin({
        id: item.id,
        name: item.name,
        phone: item.phone,
        email: item.email,
        password: item.password,
        access: JSON.stringify(item.access),
        image: item.image,
      });
      setIsUpdating(true);
    }
  };

  const handleCheck = (event, index) => {
    if (admins[index].is_super_admin) {
      toast.error("This is a super admin account, It can't be deleted");
      console.log("error");
    } else {
      var updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
    }

    //console.log(updatedList);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);

    getSearchAdmins({
      page: currentPage,

      query_string: query,
    });
  };
  const handleSubmit2 = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getSearchAdmins({ page: currentPage, query_string: "" });
    } else {
    }

    console.log(JSON.parse(localStorage.getItem("me")));

    // console.log(JSON.parse(localStorage.getItem('me')).name);

    // eslint-disable-next-line
  }, []);

  const setAllJSON = () => {
    setAdmin((prevItem) => ({
      ...prevItem,
      access: JSON.stringify({ access: access.length > 0 ? access : null }),
    }));
  };

  useEffect(() => {
    setAllJSON();
    // eslint-disable-next-line
  }, [access]);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            margin: 0,
            maxWidth: "100%",
          }}
        >
          <ToastContainer position="top-center" />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 15,
            }}
          >
            <div
              style={{
                fontSize: 18,
              }}
            >
              All Admins({totalAdmins})
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <form
                className="form_search"
                onSubmit={handleSubmit}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  className="serach_asset"
                  variant="outlined"
                  placeholder="Search admins..."
                  value={query}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton type="submit" edge="start">
                        <CiSearch />
                      </IconButton>
                    ),
                    style: {
                      height: "32px",
                      minWidth: "30vw", // Adjust the height as needed
                      flexGrow: 1, // Allow the input to grow to fill available space
                    },
                  }}
                  fullWidth
                />
              </form>

              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  marginRight: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleDelete}
                >
                  <AiFillDelete color={checked.length === 0 ? "grey" : "red"} />
                </IconButton>
                <Dialog />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 15,
              fontFamily: "monospace",
              fontWeight: 550,
              textAlign: "left",
              maxWidth: "100%",
              paddingTop: 10,
              paddingLeft: 20,
              color: "grey",
              alignItems: "center",
              justifySelf: "start",
              height: 35,
            }}
          >
            <div
              style={{
                width: "3vw",
                height: 50,
                fontWeight: 600,
                marginLeft: 10,
               // alignContent: "center",
                // backgroundColor:"black"
              }}
            >
              ID
            </div>
            <div
              style={{
                width: "9vw",
                height: 50,
                maxLines: 1,
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "10vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Email
            </div>
            <div
              style={{
                width: "6vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Phone
            </div>
            <div
              style={{
                width: "14vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Access
            </div>

            <div
              style={{
                width: "10vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Last update
            </div>
          </div>
          <div
            style={{
              minHeight: "78vh",
              // justifyContent: "center",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
            }}
          >
            {admins.length > 0 ? (
              admins.map((item, index) => (
                <div
                  key={item.id}
                  onClick={() => setUpdateData(item)}
                  style={{
                    maxWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                    backgroundColor: "transparent",
                    fontWeight: 530,
                    textAlign: "left",
                    fontFamily: "Montserrat",
                    fontSize: 12,

                    maxLines: 1,
                    // backgroundColor: "black",
                    // borderRadius: 10 /* Adjust border radius as needed */,
                    borderBottom: ".5px solid #d8d0d0",
                    // paddingTop: 10,
                    marginTop: 5,
                    marginBottom: 8,
                  }}
                >
                  <input
                    value={item.id}
                    type="checkbox"
                    onChange={(e) => handleCheck(e, index)}
                    style={{
                      alignContent: "start",
                      backgroundColor: "#000",
                      marginLeft: "10px",
                      // marginBottom: "30px",
                    }}
                  />

                  <div
                    style={{
                      width: "3vw",
                      height: 50,
                      fontWeight: 600,
                      marginLeft: 10,
                      alignContent: "center",
                     // paddingTop: "15px",
                    }}
                  >
                    {item.id}
                  </div>

                  <div
                    style={{
                      width: "9vw",
                      height: 50,
                      //  marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      color: item.is_super_admin ? "green" : "black",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.name}
                  </div>

                  <div
                    style={{
                      width: "10vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.email}
                  </div>

                  <div
                    style={{
                      width: "6vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    (+91) {item.phone}
                  </div>
                  <div
                    style={{
                      width: "14vw",
                      height: 50,

                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      // display: "inline-block",
                      whiteSpace: "nowrap",
                      display: "flex",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.access !==null && item.access.access !== null &&
                    item.access.access.length > 0
                      ? item.access.access.map((itemx, index) => (
                          <div key={itemx.name} style={{}}>
                            {itemx.name + ", "}
                          </div>
                        ))
                      : null}
                  </div>
                  <div
                    style={{
                      width: "10vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.updated_at}
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  marginTop: "20vh",
                  justifyContent: "center",
                  //backgroundColor: "black",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    marginTop: 15,
                    width: "10vw",
                  }}
                  alt=""
                  src={ErrorImage}
                />
                <div
                  style={{
                    marginTop: 30,
                    fontSize: 18,

                    fontFamily: "Montserrat",
                  }}
                >
                  Zero record not found in database
                </div>
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 15,

                    fontFamily: "Montserrat",
                  }}
                >
                  Try a different query or filter
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div
              style={{
                marginTop: 15,
                marginLeft: 15,
                marginBottom: 10,
              }}
            >
              Showing {admins.length} out of {totalAdmins}
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalAdmins / 10)}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            justifyContent: "center",
            minWidth: "25vw",
            marginLeft: 25,
            padding: 20,
          }}
        >
          <form style={{}} onSubmit={handleSubmit2}>
            <h3>{isUpdating ? "Update Admin" : "Add an Admin Account"}</h3>
            <div style={{ marginTop: 35 }}>
              <label style={{ marginBottom: "10px", flex: 1 }}>
                Admin Name
              </label>
              <TextField
                id="aname"
                value={admin.name}
                placeholder="Name" // Hint text
                variant="outlined"
                onChange={(e) => handleData("name", e.target.value)}
                fullWidth
                style={{ backgroundColor: "white" }}
                inputProps={{ style: { height: 9 } }}
                InputLabelProps={{ style: { fontSize: "15px" } }}
              />
            </div>
            {!isUpdating ? (
              <div>
                <div>
                  <label
                    style={{ marginBottom: "10px", marginTop: 15, flex: 1 }}
                  >
                    Admin Phone
                  </label>
                  <TextField
                    id="aphone"
                    placeholder="Phone" // Hint text
                    variant="outlined"
                    value={admin.phone}
                    onChange={(e) => handleData("phone", e.target.value)}
                    fullWidth
                    style={{ backgroundColor: "white" }}
                    inputProps={{ style: { height: 9 } }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
                <div>
                  <label
                    style={{ marginBottom: "10px", marginTop: 15, flex: 1 }}
                  >
                    Admin EMail
                  </label>
                  <TextField
                    id="aemail"
                    placeholder="Email" // Hint text
                    variant="outlined"
                    onChange={(e) => handleData("email", e.target.value)}
                    fullWidth
                    style={{ backgroundColor: "white" }}
                    inputProps={{ style: { height: 9 } }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
                <div>
                  <label
                    style={{ marginBottom: "10px", marginTop: 15, flex: 1 }}
                  >
                    Admin Password
                  </label>
                  <TextField
                    id="apass"
                    placeholder="Password" // Hint text
                    variant="outlined"
                    onChange={(e) => handleData("password", e.target.value)}
                    fullWidth
                    style={{ backgroundColor: "white" }}
                    inputProps={{ style: { height: 9 } }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
              </div>
            ) : null}

            <div style={{ flex: 1, marginRight: "10px" }}>
              <label style={{ marginBottom: "10px", marginTop: 15, flex: 1 }}>
                Access Level
              </label>
              <CreatableSelect
                isMulti
                styles={{ flex: 1 }}
                onChange={handleSelectAccess}
                placeholder="Select Admin Access Level"
                // onInputChange={(value) => value.toUpperCase()}
                options={ptypes.map((option) => ({
                  label: option.label,
                  value: option.value,
                  id: option.id,
                  // /  name: option.name,
                }))}
                //options={ptypes}
              />
            </div>
            <label style={{ marginBottom: "10px", marginTop: 15, flex: 1 }}>
              Note: Only Super Admin can add other admin
            </label>
            <button
              onClick={() => handleCreateOrUpdate()}
              //
              disabled={!isButtonEnabled}
              style={{
                marginTop: 20,
                height: "50px",
                width: "100%", // Take available space
                backgroundColor: "black",
                color: "white",
                border: "none",
                borderRadius: "5px", // Optional: Add border radius for better appearance
              }}
            >
              {isUpdating ? "Update Admin" : "Create Admin"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default AllAdmins;
