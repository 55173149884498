import { BASE_URL } from "../constant";
import FileManagerContext from "../context/FileMangerContext/FileManagerContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FileManagerState = (propsx) => {
  const [directoryContents, setDirectoryContents] = useState([]);
  const [recentDirectoryContents, setRecentDirectoryContents] = useState([]);

  const getSearchDirectoryContents = async (props) => {
    const { list } = props;

    setDirectoryContents(list);
  };

  const getAllDirectoryContents = async (props) => {
    const { path, type } = props;

    const queryParams = { type: type };

    console.log(`${BASE_URL}/media${path}?${new URLSearchParams(queryParams)}`);

    try {
      const response = await fetch(
        `${BASE_URL}/media${path}?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);

        // const parsedData = json.data.map((item) => ({
        //   ...item,
        //   icon: JSON.parse(item.icon), // Convert property_bhk string to JSON
        // }));
        //   console.log(parsedData);

        setDirectoryContents(json.filteredFiles);
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAllRecentDirectoryContents = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/recent_media`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);

        // const parsedData = json.data.map((item) => ({
        //   ...item,
        //   icon: JSON.parse(item.icon), // Convert property_bhk string to JSON
        // }));
        //   console.log(parsedData);

        setRecentDirectoryContents(json.recentDirectories);
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const createDirectory = async (props) => {
    const { dpath, dname } = props;

    try {
      const response = await fetch(`${BASE_URL}/create-directory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          dname: dname,
          dpath: dpath,
        }),
      });

      if (response.status === 200) {
        getAllDirectoryContents({ path: dpath, type: "all" });
        toast.success("Directory created successfully");
      } else {
        console.log(response.error);
        toast.error("Couldn't create directory, Try again later");
      }
    } catch (e) {
      console.log(e);

      toast.error("Couldn't create directory, Try again later");
    }
  };

  const uploadMediaInDirectory = async (props) => {
    const { dpath, files } = props;

    try {
      const selectedFiles = Array.from(files);

      if (selectedFiles.length > 0) {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("profilex", selectedFiles[i]);
        }

        const queryParams = { folderName: dpath };

        const response = await fetch(`${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`, {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            // Accept: "application/json",
          },
          body: formData,
        });
        if (response.status === 200) {
          getAllDirectoryContents({ path: dpath, type: "all" });
 
          toast.success("Media uploded successfully");
          const json = await response.json();
 
 
        } else {
          toast.error("Couldn't upload media(s), Try again later");

          console.log(response.status);
        }
      }
    } catch (e) {
      console.log(e);

      toast.error("Couldn't upload media(s), Try again later");
     }
  
  };

  //   const updateAssetAmenity = async (props) => {
  //     const { id, name, description, category, icon } = props;

  //     return new Promise(async (resolve, reject) => {
  //       try {
  //         const response = await fetch(
  //           `${BASE_URL}/filter/pamenity/update/${id}`,
  //           {
  //             method: "PATCH",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Accept: "application/json",
  //               Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             },
  //             body: JSON.stringify({
  //               name: name,
  //               description: description,
  //               category: category,
  //               icon: icon === null ? null : JSON.stringify(icon),
  //             }),
  //           }
  //         );

  //         if (response.status === 200) {
  //           toast.success("Asset amenity updated successfully");

  //           getAssetsAmenities();
  //           //console.log(users);

  //           resolve(true);
  //         } else {
  //           toast.error("Couldn't update amenity, Try again later");
  //           reject("Update Failed");
  //         }
  //       } catch (error) {
  //         console.error(error);
  //         toast.error("Couldn't update amenity, Try again later");
  //         reject(error);
  //       }
  //     });
  //   };

  const deleteDirectory = async (props) => {
    const { path } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/media_delete${path}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        // const json = response.json();
        getAllDirectoryContents({ path: "", type: "all" });
        toast.success("Directory deleted successfully");
      } else {
        toast.error("Couldn't delete directory, Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete directory, Try again later");

      // console.log(e.message);
    }
  };

  const deleteMedia = async (props) => {
    const { path } = props;
    try {
      const queryParams = { filePath: path };

      const response = await fetch(
        `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        getAllDirectoryContents({ path: "", type: "all" });

        toast.success("Media deleted successfully");
      } else {
        toast.error("Couldn't delete media, Try again later");
      }
    } catch (e) {
      console.log(e);
      toast.error("Couldn't delete media, Try again later");
    }
  };

  return (
    <FileManagerContext.Provider
      value={{
        directoryContents,
        getAllDirectoryContents,

        recentDirectoryContents,
        getAllRecentDirectoryContents,

        getSearchDirectoryContents,
        createDirectory,
        uploadMediaInDirectory,

        deleteDirectory,
        deleteMedia,
      }}
    >
      {propsx.children}
    </FileManagerContext.Provider>
  );
};
export default FileManagerState;
