import { BASE_URL } from "../constant";
import AssetBuilderContext from "../context/AssetsContext/AssetBuilderContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AssetBuilderState = (propsx) => {
  const [assetsBuilders, setAssetsBuilders] = useState([]);
  const [statistics, setStatistics] = useState([]);

  const getAssetBuildersStatistics = async (props) => {
    try {
      const response = await fetch(
        `${BASE_URL}/filter/pbuilder/get_analytics`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data);
        //console.log(statistics);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAssetsBuilders = async (props) => {
    // API Call

    try {
      const response = await fetch(`${BASE_URL}/filter/pbuilder/get`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);

        const parsedData = json.data.map((item) => ({
          ...item,
          icon: JSON.parse(item.icon), // Convert property_bhk string to JSON
        }));
        //   console.log(parsedData);
        setAssetsBuilders(parsedData);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  const getSearchAssetsBuilders = async ({name}) => {
    // API Call

    try {
      const response = await fetch(`${BASE_URL}/filter/pbuilder/search/get/${name}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);

        const parsedData = json.data.map((item) => ({
          ...item,
          icon: JSON.parse(item.icon),  
        }));
        //   console.log(parsedData);
        setAssetsBuilders(parsedData);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const createAssetBuilders = async (props) => {
    const { name, description, icon } = props;
    // {
    //     "name":"Farm House",
    //     "description":"",
    //     "icon":""
    // }

    try {
      const response = await fetch(`${BASE_URL}/filter/pbuilder/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: name,
          description: description,
          icon: icon === null ? null : JSON.stringify(icon),
        }),
      });

      if (response.status === 200) {
        getAssetsBuilders();
        toast.success("Asset Builder created successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        console.log(response.error);
        toast.error("Couldn't Add asset Builder, Try again later");
      }
    } catch (e) {
      console.log(e);

      toast.error("Couldn't Add asset Builder, Try again later");
    }
  };

  const updateAssetBuilders = async (props) => {
    const { id, name, description, icon } = props;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${BASE_URL}/filter/pbuilder/update/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              name: name,
              description: description,
              icon: icon === null ? null : JSON.stringify(icon),
            }),
          }
        );

        if (response.status === 200) {
          toast.success("Asset Builder updated successfully");

          getAssetsBuilders();
          //console.log(users);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update, Try again later");
        reject(error);
      }
    });
  };

  const deleteAssetsBuilders = async (props) => {
    const { id } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/filter/pbuilder/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        // const json = response.json();
        getAssetsBuilders();
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete, Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete, Try again later");

      // console.log(e.message);
    }
  };

  return (
    <AssetBuilderContext.Provider
      value={{
        statistics,
        getAssetBuildersStatistics,

        assetsBuilders,
        getAssetsBuilders,
        getSearchAssetsBuilders,

        createAssetBuilders,
        updateAssetBuilders,
        deleteAssetsBuilders,
      }}
    >
      {propsx.children}
    </AssetBuilderContext.Provider>
  );
};
export default AssetBuilderState;
