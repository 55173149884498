import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { AiFillDelete } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import ticketContext from "../../context/TicketsContext/TicketsContext";
import messageContext from "../../context/MessageContext/MessageContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoFilter } from "react-icons/io5";
import FilterModal from "./FiltersModal";
import useConfirm from "../AlertDialog";
import { IMAGE_PATH } from "../../constant";
 
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import Switch from "react-switch";
import Pagination from "../.././components/Pagination";

const AllDisputes = () => {
  const [me, setMe] = useState(JSON.parse(localStorage.getItem("me")));
 
  const [checked, setChecked] = useState([]);
  const [query, setQuery] = useState("");

  const [filters, setFilters] = useState(null);

   const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(ticketContext);
  const messageCon = useContext(messageContext);
  const {
    CreateChatUser
  } = messageCon;

  const {
    tickets,
    totalTickets,
    statistics,

    getTickets,
    getSearchTickets,
    getTicketStatistics,
    deleteTickets,

    UpdateTicketStatus,
  } = context;

  const [FilterDialog, ApplyFilters] = FilterModal();

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Leads!",
    `Are you sure you want to delete ${
      checked.length > 1 ? "these Leads" : "this user"
    } : ${checked}? `
  );

  const handleFilters = async () => {
    const filtersx = await ApplyFilters();
    if (filtersx === null) {
    } else {
      setCurrentPage(1);
      getSearchTickets({
        page: 1,
        status: filtersx.status,

        query_string: query,
      });
      setFilters({
        status: filtersx.status,
      });
      // console.log(filters);
    }
  };

  const handleDelete = async () => {
    const ans = await confirmDelete();
    if (ans) {
      handleDeleteUsers();
    } else {
      /* ... */
    }
  };

  const [showLead, setShowLead] = useState( null);

  const handleShowLead = (lead) => {
    console.log(lead.id);
    setShowLead(lead);

    // You can add logic here to fetch data for the selected page
  };
  const handleOpenInChat = async (userid) => {
 
    const stat = await  CreateChatUser( {
       user_id: userid, admin_id: me.id, last_msg: "", is_blocked: 0});
    if (stat) {
 
      // window.location.reload();
      //  console.log(stat);
    }
  };

  const handleStatusChange = async (event, index) => {
 
    const stat = await UpdateTicketStatus({
      id: tickets[index].id,
      status: tickets[index].status === 1 ? 0 : 1,
    });
    if (stat) {
      // window.location.reload();
      //  console.log(stat);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (localStorage.getItem("token")) {
      query.length > 0 || filters !== null
        ? getSearchTickets({
            page: newPage,
            status: filters !== null ? filters.status : [],

            query_string: query,
          })
        : getTickets({ page: newPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const handleDeleteUsers = async () => {
    if (localStorage.getItem("token")) {
      await deleteTickets({ ids: checked, page: currentPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };
 
 
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);

    getSearchTickets({
      page: currentPage,
      status: filters !== null ? filters.status : [],

      query_string: query,
    });
  };
 
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getTickets({ page: currentPage });
      getTicketStatistics();
      setShowLead(tickets.length>0?tickets[0]:null);
    } else {
    }

    // eslint-disable-next-line
  }, [showLead===null?tickets : null]);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,

            // maxWidth: "100%",
          }}
        >
          <ToastContainer position="top-center" />

          <div
            style={{
              display: "flex",
              // backgroundColor: "white",
              justifyContent: "space-around",
              paddingTop: 15,
            }}
          >
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  Total Ticket
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>{statistics!=null?statistics.total_tickets:0}</label>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  Open Ticket
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>{statistics!=null?statistics.active_tickets:0}</label>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  Closed Ticket
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>{statistics!=null?statistics.closed_tickets:0}</label>
                </div>
              </CardContent>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 15,
              //  marginTop: 15,
            }}
          >
            <div
              style={{
                fontSize: 18,
              }}
            >
              All Tickets({totalTickets})
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <form
                className="form_search"
                onSubmit={handleSubmit}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  className="serach_asset"
                  variant="outlined"
                  placeholder="Search tickets..."
                  value={query}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton type="submit" edge="start">
                        <CiSearch />
                      </IconButton>
                    ),
                    style: {
                      height: "32px",
                      minWidth: "30vw",

                      // width: "500px", // Adjust the height as needed
                      flexGrow: 1, // Allow the input to grow to fill available space
                    },
                  }}
                  fullWidth
                />
              </form>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  //disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleFilters}
                >
                  <IoFilter color="grey" />
                </IconButton>
                <FilterDialog />
              </div>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  marginRight: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleDelete}
                >
                  <AiFillDelete color={checked.length === 0 ? "grey" : "red"} />
                </IconButton>
                <Dialog />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 15,
              fontFamily: "monospace",
              fontWeight: 550,
              textAlign: "left",
              maxWidth: "100%",
              paddingTop: 10,
              paddingLeft: 20,
              color: "grey",
              alignItems: "center",
              justifySelf: "start",
              height: 35,
            }}
          >
            {/* <input
              value="all"
              type="checkbox"
              style={{
                alignContent: "start",
                backgroundColor: "#000",
                marginBottom: "30px",
              }}
            /> */}

            <div
              style={{
                width: "3vw",
                height: 50,
                fontWeight: 600,
                marginLeft: 10,
               // alignContent: "center",
                // backgroundColor:"black"
              }}
            >
              ID
            </div>

            <div
              style={{
                width: "9vw",
                height: 50,
                maxLines: 1,
              }}
            >
              Name
            </div>

            <div
              style={{
                width: "6vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Phone
            </div>
            <div
              style={{
                width: "13vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Subject
            </div>
            <div
              style={{
                width: "13vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Message
            </div>
            <div
              style={{
                width: "4vw",
                height: 50,
                maxLines: 1,
                marginLeft: 20,
                // backgroundColor: "black",
              }}
            >
              Status
            </div>
            <div
              style={{
                width: "5vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Action
            </div>
          </div>
          <div
            style={{
              height: "65vh",
              overflowY: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              marginBottom: 10,
            }}
          >
            {tickets.map((item, index) => (
              <div
                key={item.id}
                onClick={() => handleShowLead(item)}
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",

                  flexWrap: "wrap",
                  // fontSize: 14,
                  color: "#000",
                  fontWeight: 530,
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  maxLines: 1,
                  borderBottom: ".5px solid #d8d0d0",

                  // paddingTop: 10,
                  marginTop: 5,
                }}
              >
                <input
                  value={item.id}
                  type="checkbox"
                  onChange={handleCheck}
                  style={{
                    alignContent: "start",
                    backgroundColor: "#000",
                    marginLeft: "10px",
                    // marginBottom: "30px",
                  }}
                />

                <div
                  style={{
                    width: "3vw",
                    height: 50,
                    fontWeight: 600,
                    marginLeft: 10,
                    alignContent: "center",
                   // paddingTop: "15px",
                  }}
                >
                  {item.id}
                </div>

                <div
                  style={{
                    width: "9vw",
                    height: 50,
                    // marginLeft: 10,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.name}
                </div>

                <div
                  style={{
                    width: "6vw",
                    height: 50,
                    marginLeft: 10,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  +91 {item.phone}
                </div>

                <div
                  style={{
                    width: "13vw",
                    height: 50,
                    marginLeft: 10,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.subject}
                </div>
                <div
                  style={{
                    width: "13vw",
                    height: 50,
                    marginLeft: 10,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.message}
                </div>

                <div
                  style={{
                    width: "4vw",
                    height: 50,
                    marginLeft: 20,
                    maxLines: 1,
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    paddingTop: "15px",

                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  <Switch
                    onChange={(e) => handleStatusChange(e, index)}
                    checked={item.status === 0 ? false : true}
                  />
                  {/* {item.is_manager === 1 ? "Yes" : "No"} */}
                </div>
                <div
                  style={{
                    width: "5vw",
                    height: 50,
                    marginRight: 10,
                    marginLeft: 15,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    // overflow: "hidden",
                  }}
                >
                  <IconButton
                   onClick={() => handleOpenInChat(item.uid)}
                    size="small"
                    type="submit"
                    edge="start"
                  >
                    <FaRegEdit />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div
              style={{
                marginTop: 15,
                marginLeft: 15,
                marginBottom: 10,
              }}
            >
              Showing {tickets.length} out of {totalTickets}
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalTickets / 10)}
                onPageChange={(e) => handlePageChange(e)}
              />
            </div>
          </div>
        </div>

        <div>
          <div>
            <Card
              style={{
                minWidth: "20vw",
                maxWidth: "22vw",
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    ID: #{showLead!==null?showLead.id:""}
                  </Typography>
                  <Typography
                    variant="h7"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    {showLead!==null?showLead.name:""}
                  </Typography>
                </div>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography>{showLead!==null?showLead.subject:""}</Typography>
                  <Typography>{showLead!==null?showLead.updated_at:""}</Typography>
                </div>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              style={{
                minWidth: "20vw",
                maxWidth: "22vw",
                maxHeight: "80vh",
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  // key={index}
                  style={{
                     display: "flex",
                     flexDirection:"column",
                    maxHeight: "60vh",
                    minHeight: "60vh",

                   // justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography
                    variant="h7"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    Desc/Message
                  </Typography>
                  <label
                    style={{
                      marginTop: 10,
                    }}
                  >
                    {showLead!==null?showLead.message:""}
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",

                    minHeight: "15vh",
                    overflowX: "auto",
                  }}
                >
                  {showLead!==null && showLead.media!==null && showLead.media.data!==null && showLead.media.data.length>0? showLead.media.data.map((item) => (
                    <div
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                        height: "15vh",
                        width: "15vh",
                      }}
                    >
                      <img
                        style={{
                          height: "15vh",
                          width: "15vh",
                        }}
                        src={IMAGE_PATH +item.url}
                        alt={item.url}

                        // onError={handleErrorImage}
                      />
                    </div>
                  )):null}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllDisputes;
