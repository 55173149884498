import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

function LineChart(props) {
  const chartRef = useRef(null);
  
  const { labels, dataset } = props.data;

  const data = {
    labels:  labels,
    datasets: [
      {
        label: "Transaction",
        data: dataset, // Sample data
        fill: true, // Fill area under line
        backgroundColor: "rgba(220, 220, 220, 0.5)", // Light grey background color
        borderColor: "rgba(75,192,192,1)", // Line color
        borderWidth: 3, // Line width
        tension: 0.3, // Curvature of the line (0 to 1)
      },
      //   {
      //   label: 'Total Assets',
      //   data: [40, 60, 70, 40, 66, 77, 88, 60, 55, 67, 77,20], // Sample data
      //   fill: true, // Fill area under line
      //   backgroundColor: 'rgba(220, 220, 220, 0.5)', // Light grey background color
      //   borderColor: '#00073D', // Line color
      //   borderWidth: 2, // Line width
      //   tension: 0.3 // Curvature of the line (0 to 1)
      // },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines for x-axis
        },
      },
      y: {
        // beginAtZero: true,
        grid: {
          display: true, // Hide grid lines for x-axis
        },
      },
    },
    // Specify options as needed
  };
  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    }
  }, []);

  return (
    <div
      style={{
        maxWidth: "40vw",
        minWidth: "40vw",
        maxHeight: "50vh",
        padding: 15,

        // /backgroundColor: "black",
      }}
    >
 
      <canvas ref={chartRef}></canvas>
    </div>
  );
}

export default LineChart;
