import React, { useState, useContext, useEffect } from "react";
import { BASE_URL, IMAGE_PATH } from "../../../constant";
import { CiImageOff } from "react-icons/ci";
import useConfirm from "../../AlertDialog";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import assetBHKContext from "../../../context/AssetsContext/AssetBHKContext";
import { toast } from "react-toastify";

import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddHomeWork, MdCancel } from "react-icons/md";
import {
  IconButton,
  TextField,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

const AllProjectBHKs = (props) => {
  const context = useContext(assetBHKContext);
  const {
    statistics,
    getAssetBHKsStatistics,

    assetsBHKs,
    getAssetsBHKs,

    createAssetBHKs,
    updateAssetBHKs,
    deleteAssetsBHKs,
  } = context;

  const [selectedAssetBHK, setSelectedAssetBHK] = useState({
    id: 0,
    name: "",
    description: "",
    icon: null,
    // icon: JSON.stringify({})
  });

  const [isAdding, setIsAdding] = useState(true);

  const handleIsAdding = (pt, val) => {
    setSelectedAssetBHK({
      id: pt.id,
      name: pt.name,
      description: pt.description,
      icon: pt.icon,
    });
    setIsAdding(val);
  };
  const handleData = (key, value) => {
    setSelectedAssetBHK((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  const handleImageChange = async (key, event) => {
    if (key === "imageadd") {
      const files = Array.from(event.target.files);

      try {
        if (files.length > 0) {
          const formData = new FormData();

          formData.append("profilex", files[0]);

          const queryParams = {
            folderName: "default",
          };

          const response = await fetch(
            `${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`,
            {
              method: "POST",
              headers: {
                // "Content-Type": "application/json",
                // Accept: "application/json",
              },
              body: formData,
            }
          );
          if (response.status === 200) {
            const json = await response.json();

            const selectedFile = files[0];
            const reader = new FileReader();

            reader.onload = (value) => {
              handleData("icon", json.data[0]);
            };

            if (selectedFile) {
              reader.readAsDataURL(selectedFile);
            }
            //toast.success("Media uploded successfully");
          } else {
            // toast.error("Couldn't upload media(s), Try again later");
            console.log(response.status);
          }
        }
      } catch (e) {
        console.log(e);
        // toast.error("Couldn't upload media(s), Try again later");
      }
    } else {
      try {
        console.log(selectedAssetBHK.icon.path);
        const queryParams = { filePath: selectedAssetBHK.icon.path };

        const response = await fetch(
          `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          handleData("icon", null);
        } else {
          console.log("error");
          // toast.error("Couldn't upload media(s), Try again later");
        }
      } catch (e) {
        console.log(e);
        // toast.error("Couldn't upload media(s), Try again later");
      }
    }

    // console.log(roadsandLandmarks);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAssetsBHKs();
      getAssetBHKsStatistics();
    } else {
    }

    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    if (selectedAssetBHK.name.length > 0) {
      if (isAdding) {
        createAssetBHKs(selectedAssetBHK);
      } else {
        updateAssetBHKs(selectedAssetBHK);
      }
      setSelectedAssetBHK({
        id: 0,
        name: "",
        description: "",
        icon: null,
        // icon: JSON.stringify({})
      });
    } else {
      toast.error("Please enter valid name and description");
    }
  };

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Project Configuration!",
    `Are you sure you want to delete ${selectedAssetBHK.name}? `
  );

  const handleDelete = async (idx) => {
    const ans = await confirmDelete();
    if (ans) {
      deleteAssetsBHKs({ id: idx });
    } else {
      /* ... */
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(196, 196, 196, 0.15)",
          margin: 0,
          width: "80vw",
          height: "98vh",
          maxHeight: "98vh", // Set maximum height to screen height
          overflowY: "auto", // Enable vertical scrolling if content exceeds height
          border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <ToastContainer position="top-center" />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: 15,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 600 }}>
            {isAdding
              ? "Add Project Configuration"
              : "Update Project Configuration"}
          </div>

          <div style={{ display: "flex" }}>
            <form
              style={{
                maxWidth: "60vw",
                minWidth: "50vw",
                marginTop: 25,
                backgroundColor: "white",
                padding: 20,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginRight: 30 }}>
                  <label style={{ marginBottom: "10px", flex: 1 }}>
                    Name of Configuration
                  </label>
                  <TextField
                    id="propertyName"
                    placeholder="Enter Name" // Hint text
                    variant="outlined"
                    value={selectedAssetBHK.name}
                    fullWidth
                    onChange={(e) => handleData("name", e.target.value)}
                    style={{ backgroundColor: "white" }}
                    inputProps={{
                      style: { height: 9, width: 600, color: "black" },
                    }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    backgroundColor: "white",

                    height: 120,
                    width: 120,

                    // justifyContent: "center",
                  }}
                >
                  {!selectedAssetBHK.icon && (
                    <div>
                      <label style={{ marginBottom: "10px", flex: 1 }}>
                        Icon/Logo
                      </label>
                      <div>
                        <label
                          htmlFor="fileInput"
                          style={{
                            cursor: "pointer",
                            display: "inline-block",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: 5,
                          }}
                        >
                          Choose File
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) => handleImageChange("imageadd", e)}
                          style={{
                            display: "none",
                            borderRadius: 10,
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {selectedAssetBHK.icon && (
                    <div style={{ alignItems: "center" }}>
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "5px",
                          position: "relative",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleImageChange("imageremove", e)}
                        >
                          <MdCancel size={25} />
                        </span>
                        <img
                          src={
                            IMAGE_PATH +
                            selectedAssetBHK.icon.fieldname +
                            "/" +
                            selectedAssetBHK.icon.filename
                          }
                          alt="Error"
                          style={{
                            width: "108px",
                            height: "120px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label style={{ marginBottom: "10px", flex: 1, marginTop: 10 }}>
                  Config Description
                </label>
                <TextField
                  id="propertyDesc"
                  placeholder="Enter Description" // Hint text
                  variant="outlined"
                  value={selectedAssetBHK.description}
                  fullWidth
                  multiline
                  rows={10}
                  onChange={(e) => handleData("description", e.target.value)}
                  style={{ backgroundColor: "white" }}
                  inputProps={{ style: { height: 150, width: 500 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>
              <div
                style={{
                  maxWidth: "15vw",
                  border: "1px solid #ffffff",
                  borderRadius: "6px",
                  color: "white",
                  margin: "15px 0px",
                  padding: "5px 10px",
                  backgroundColor: "#21232d",

                  textAlign: "center",
                }}
                // onClick={addType}
                onClick={() => handleSubmit()}
              >
                <IconButton type="submit" edge="start">
                  <MdOutlineAddHomeWork color="white" />
                </IconButton>
                {isAdding
                  ? "Add this Project Configuration"
                  : "Update Project Configuration"}
              </div>
            </form>
            <div
              style={{
                margin: "15px 25px",
              }}
            >
              <Card
                style={{
                  minWidth: "20vw",
                  minHeight: "50vh",
                  margin: "auto",
                  marginTop: 10,
                  marginLeft: 15,
                  backgroundColor: "#21232d",
                  borderRadius: 15,
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ color: "white", fontWeight: 600 }}
                    >
                      Project Configuration
                    </Typography>
                    {/* <IconButton aria-label="edit">
                <AiOutlineStock color="white" />
              </IconButton> */}
                  </div>
                  <div
                    style={{
                      overflowY: "auto",
                      height: "45vh",
                    }}
                  >
                    {statistics.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 10,
                          color: "white",
                        }}
                      >
                        <Typography>{item.property_bhks}</Typography>
                        <Typography>{item.project_count}</Typography>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: "15px 20px",
            margin: "0px 0px",
            backgroundColor: "white",
          }}
        >
          <h5>All Project Configuration ({assetsBHKs.length})</h5>

          <div
            style={{
              marginTop: "10px",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(15vw, 1fr))",
              gap: "10px",
            }}
          >
            {assetsBHKs.map((pt, index) => (
              <div
                key={pt.id}
                onClick={() => handleIsAdding(pt, false)}
                style={{
                  //  padding: "7px",
                  // height: "100px",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "8px",
                  //  width: "15vw",
                  display: "flex",
                  marginRight: 14,
                  alignItems: "center",
                }}
              >
                {pt.icon !== null ? (
                  // If the icon field exists and contains an image property
                  <img
                    src={
                      IMAGE_PATH + pt.icon.fieldname + "/" + pt.icon.filename
                    }
                    alt="Icon"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginRight: "10px",
                      width: "50px",
                      height: "50px",
                    }}
                  />
                ) : (
                  // If the icon field is empty or doesn't contain an image property
                  <IconButton type="submit" edge="start">
                    <CiImageOff color="black" />
                  </IconButton>
                )}

                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "start",
                    width: "14vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // backgroundColor:"black",
                      justifyContent: "space-between",
                      //alignItems:"end",
                      // maxWidth: "14vw",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        // marginRight: "30px",
                        marginBottom: 5,
                        // maxWidth: "100%"
                      }}
                    >
                      {pt.name}
                    </div>
                    <div
                      style={{
                        backgroundColor: "rgba(192, 192, 192,0.4 )",
                        alignContent: "center",
                        justifyContent: "center",
                        borderRadius: 7,
                      }}
                    >
                      {/* <IconButton size="small" onClick={handleEditClick}>
                        <FiEdit2 style={{ cursor: "pointer" }} />
                      </IconButton> */}

                      <IconButton
                        onClick={() => handleDelete(pt.id)}
                        size="small"
                        style={{ color: "red" }}
                      >
                        <AiOutlineDelete style={{ cursor: "pointer" }} />
                      </IconButton>
                      <Dialog />
                    </div>
                  </div>
                  {/* <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    {pt.name}
                  </div> */}
                  {/* <div style={{ fontSize: 10, marginBottom: 5 }}>
                    {pt.description}
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjectBHKs;
