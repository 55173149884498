import { BASE_URL } from "../constant";
import UserContext from "../context/UsersContext/UsersContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserState = (propsx) => {

  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [statistics, setStatistics] = useState(null);
  const [leadsOverview, setLeadsOverview] = useState([]);
  const [subscriptionOverview, setSubscriptionOverview] = useState([]);
  const [teamMembersAndManager, setTeamMembersAndManager] = useState(null);

  const updateUserStatusInList = (userId, newStatus, userList) => {
    return userList.map((user) => {
      if (user.id === userId) {
        return { ...user, status: newStatus };
      }
      return user;
    });
  };
  
  const updateKYCStatusInList = (userId, newStatus, userList) => {
    return userList.map((user) => {
      if (user.id === userId) {
        return { ...user, kyc_verified: newStatus };
      }
      return user;
    });
  };

  const UpdateKYCStatus = async (props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/users/updatekycstatus`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: props.id, kyc_verified: props.kyc_verified }),
        });

        if (response.status === 200) {
          toast.success("Status updated successfully");
          // console.log(props.status);
          // console.log(users);
          const updatedUserList = updateKYCStatusInList(props.id, props.kyc_verified, users);
          // console.log(updatedUserList);

          setUsers(updatedUserList);
          //console.log(users);

          resolve(true);
        } else if(response.status===403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        }else {
          toast.error("Couldn't update user's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update user's status, Try again later");
        reject(error);
      }
    });
  };

  const UpdateUserStatus = async (props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/users/updatestatus`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: props.id, status: props.status }),
        });

        if (response.status === 200) {
          toast.success("Status updated successfully");
          // console.log(props.status);
          console.log(users);
          const updatedUserList = updateUserStatusInList(
            props.id,
            props.status,
            users
          );
          console.log(updatedUserList);

          setUsers(updatedUserList);
          //console.log(users);

          resolve(true);
        }else if(response.status===403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update user's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update user's status, Try again later");
        reject(error);
      }
    });
  };

  const getTeamMembersAndManagers = async (props) => {
    const queryParams = { phone: props.phone };
    setTeamMembersAndManager(null);

    try {
      const response = await fetch(
        `${BASE_URL}/users/info?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // console.log(json.data);
        setTeamMembersAndManager(json.data);
        //console.log(json.data.members);
      }else if(response.status===403) {
        toast.error("Your access has been revoked, Contact your Admin");
       } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      //  console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getUserSubscriptionsOverview = async (props) => {
    const queryParams = { id: props.id };
    setSubscriptionOverview([]);

    try {
      const response = await fetch(
        `${BASE_URL}/transaction/get/donutchartuser?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // console.log(json.data);
        setSubscriptionOverview(json.data);
        //console.log(statistics);
      } else if(response.status===403) {
          toast.error("Your access has been revoked, Contact your Admin");
         }else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      //console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getUserLeadsOverview = async (props) => {
    const queryParams = { id: props.id };
    setLeadsOverview([]);

    try {
      const response = await fetch(
        `${BASE_URL}/lead/get/donutchartuser?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // console.log(json.data);
        setLeadsOverview(json.data);
        // console.log(json.data[5].total_records);
      } else if(response.status===403) {
          toast.error("Your access has been revoked, Contact your Admin");
         }else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      //console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getUserStatistics = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/users/getuserstat`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data[0]);
        //console.log(statistics);
      }else if(response.status===403) {
        toast.error("Your access has been revoked, Contact your Admin");
       } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

 
  const getUsers = async (props) => {
    const { page } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = { limit: limit, offset: offset };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/users/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setUsers(json.data);
        setTotalUsers(json.total);
      }else if(response.status===403) {
        toast.error("Your access has been revoked, Contact your Admin");
       } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };


  const getSearchUsers = async (props) => {
    const { page, type, status, subscription, kyc, query_string } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      type: type,
      status: status,
      subscription: subscription,
      kyc: kyc,
      query_string: query_string,
      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/users/search/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setUsers(json.data);
        setTotalUsers(json.total);
      }else if(response.status===403) {
          toast.error("Your access has been revoked, Contact your Admin");
         } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  
   //   const addNote = async (title, description, tag) => {
  //     // TODO: API Call
  //     // API Call
  //     try{
  //       const response = await fetch(`${host}/api/v1/notes/create`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //         body: JSON.stringify({ title, description, tag }),
  //       });

  //       if (response.status === 200) {
  //         const note = await response.json();

  //         console.log(note.data);

  //         setNotes(notes.concat(note.data));
  //         // props.showAlert("Note added successfully", "success");
  //       } else {
  //         console.log("FAILED WITH STATUS CODE " + response.status.toString());
  //         // props.showAlert("Unknown error occurred", "danger");
  //       }
  //     }catch(e){

  //       console.log(e.message);
  //     }

  //   };

  // Delete bulk users
  const deleteUsers = async (props) => {
    const { ids, page } = props;
    console.log(ids);
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/users/bulk/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ ids: ids }),
      });
      if (response.status === 200) {
        // const json = response.json();
        getUsers({ page: page });
        toast.success("Deleted successfully");
      }else if(response.status===403) {
        toast.error("Your access has been revoked, Contact your Admin");
       } else {
        toast.error("Couldn't delete user(s), Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete user(s), Try again later");

      // console.log(e.message);
    }
  };

 

  return (
    <UserContext.Provider
      value={{
        users,
        totalUsers,
        getUserStatistics,
        statistics,
        getUserLeadsOverview,
        leadsOverview,
        getUserSubscriptionsOverview,
        subscriptionOverview,
        getTeamMembersAndManagers,
        teamMembersAndManager,
        UpdateUserStatus,
        UpdateKYCStatus,
        deleteUsers,
        getUsers,
        getSearchUsers,
      }}
    >
      {propsx.children}
    </UserContext.Provider>
  );
};
export default UserState;
