import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import LoginPage from "../components/Auth/LoginPage";
import LogoutPage from "../components/Auth/LogoutPage";
import SideBar from "../components/Sidebar/SideBar";
import AllAsset from "../components/Projects/AllProjects";
import AddAsset from "../components/Projects/AddProject";
import UpdateProject from "../components/Projects/UpdateProject";
import AllProjectTypes from "../components/Projects/ProjectTypes/AllProjectTypes";
import AllProjectStatus from "../components/Projects/ProjectStatuses/AllProjectStatuses";
import AllProjectBHKs from "../components/Projects/ProjectBHKs/AllProjectBHKs";
import AllProjectBuilders from "../components/Projects/ProjectBuilders/AllProjectBuilders";
import AllProjectLocations from "../components/Projects/ProjectLocations/AllProjectLocations";
import AllProjectAmenities from "../components/Projects/ProjectAminities/AllProjectAmenities";
import MyFileManager from "../components/FileManager/FileManager";
import AllUsers from "../components/Users/AllUsers";
import AllBuildersx from "../components/Builders/AllBuilders";
import AllLeads from "../components/Leads/AllLeads";
import AllDisputes from "../components/Dispute/AllDispute";
import AllMessages from "../components/Messages/AllMessages";
import AllAdmins from "../components/Admin/AllAdmins";
import AllTransactions from "../components/Transactions/AllTransactions";
import AllKYCUsers from "../components/KYCUsers/AllKYCUsers";
import Dashboardx from "../components/Dashboard/dashboard";
import Blogs from "../components/Blogs/Blogs";
import NotFoundPage from "../components/404NotFoundPage";
import AllResellAsset from "../components/ProjectsResell/AllResellProjects";

import UpdateResellProject from "../components/ProjectsResell/UpdateResellProject";
import AllProjectLandmarks from "../components/Projects/ProjectLandmarks/AllProjectLandmarks";
import "../App.css";

const Routes = (props) => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/service",
      element: <div>Service Page</div>,
    },
    {
      path: "/about-us",
      element: <div>About Us</div>,
    },
    {
      path: "*",
      element: (
        <div>
          <NotFoundPage />
        </div>
      ),
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          element: (
            <SideBar>
              <div>
                <Dashboardx />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets",
          element: (
            <SideBar>
              <div>
                <AllAsset />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_all",
          element: (
            <SideBar>
              <div>
                <AllAsset />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_add",
          element: (
            <SideBar>
              <div>
                <AddAsset />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_update",
          element: (
            <SideBar>
              <div>
                <UpdateProject />
              </div>
            </SideBar>
          ),
        },

        {
          path: "/dashboard/assets/asset_resell_all",
          element: (
            <SideBar>
              <div>
                <AllResellAsset />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_resell_add",
          element: (
            <SideBar>
              <div>
                <AddAsset />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_resell_update",
          element: (
            <SideBar>
              <div>
                <UpdateResellProject />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_type",
          element: (
            <SideBar>
              <div>
                <AllProjectTypes />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_status",
          element: (
            <SideBar>
              <div>
                <AllProjectStatus />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_location",
          element: (
            <SideBar>
              <div>
                <AllProjectLocations />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_landmark",
          element: (
            <SideBar>
              <div>
                <AllProjectLandmarks />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_builder",
          element: (
            <SideBar>
              <div>
                <AllProjectBuilders />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_config",
          element: (
            <SideBar>
              <div>
                <AllProjectBHKs />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/assets/asset_amenity",
          element: (
            <SideBar>
              <div>
                <AllProjectAmenities />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/file-manager",
          element: (
            <SideBar>
              <div>
                <MyFileManager />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/users",
          element: (
            <SideBar>
              <div>
                <AllUsers />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/builders",
          element: (
            <SideBar>
              <div>
                <AllBuildersx />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/messages",
          element: (
            <SideBar>
              <div>
                <AllMessages />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/dispute",
          element: (
            <SideBar>
              <div>
                <AllDisputes />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/leads",
          element: (
            <SideBar>
              <div>
                <AllLeads />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/kyc",
          element: (
            <SideBar>
              <div>
                <AllKYCUsers />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/admin",
          element: (
            <SideBar>
              <div>
                <AllAdmins />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/transactions",
          element: (
            <SideBar>
              <div>
                <AllTransactions />
              </div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/blogs",
          element: (
            <SideBar>
              <div><Blogs/></div>
            </SideBar>
          ),
        },
        {
          path: "/dashboard/logout",
          element: <LogoutPage />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <h2> Public Home Page</h2>
        </div>
      ),
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/signup",
      element: <LoginPage />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
