import React, { useState, } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdOutlineCancel } from "react-icons/md";
 
import { BASE_URL } from "../../constant";

const ReorderableImageList = ({
  idx,
  name,
  isMultiple,
  folder,
  accept,
  onDataReceived,
}) => {
 
 
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);

  // const [storyImages, setStoryImages] = useState([]);
  // const [coverImages, setCoverImages] = useState("");

  // const [marketingMaterials, setMarketingMaterials] = useState([]);

  const [document, setDocument] = useState(null);
  const [video, setVideo] = useState(null);

  const handleDragEnd = (result) => {

    if (!result.destination) return;
    const reorderedImages = Array.from(images);
    const [reorderedItem] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, reorderedItem);
    setImages(reorderedImages);

  };

  const handleRemoveImage = async (indexxxxx) => {
    
    try {
      const queryParams = { filePath: urls[indexxxxx].path};

      const response = await fetch(
        `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const listImages = images.filter((item, index) => index !== indexxxxx);
        const listUrls = urls.filter((item, index) => index !== indexxxxx);

        setImages(listImages);
        setUrls(listUrls);
        onDataReceived({ id: idx, data: listUrls });

        //toast.success("Media uploded successfully");
      } else {
        // toast.error("Couldn't upload media(s), Try again later");
        console.log(response.status);
      }
    } catch (e) {
      console.log(e);
      // toast.error("Couldn't upload media(s), Try again later");
    }
  };

  const handleUpload = async (selectedFiles) => {
    try {
      if (selectedFiles.length > 0) {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("profilex", selectedFiles[i]);
        }

        const queryParams = { folderName: folder };

        const response = await fetch(`${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`, {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            // Accept: "application/json",
          },
          body: formData,
        });
        if (response.status === 200) {
          const json = await response.json();

          const uploadedImages = selectedFiles.map((file) => ({
            id: `${file.name}-${Date.now()}`,
            name: file.name,

            url: URL.createObjectURL(file),
          }));

          setImages([...images, ...uploadedImages]);
          setUrls([...urls, ...json.data]);
          onDataReceived({ id: idx, data: [...urls, ...json.data] });

          //toast.success("Media uploded successfully");
        } else {
          // toast.error("Couldn't upload media(s), Try again later");
          console.log(response.status);
        }
      }
    } catch (e) {
      console.log(e);
      // toast.error("Couldn't upload media(s), Try again later");
    }
  };

  const handleUploadImages = (event) => {
    const files = Array.from(event.target.files);

    handleUpload(files);
  };

  return (
    <div
      style={{
        margin: 20,
        paddingLeft: 15,
        paddingTop: 15,
        paddingRight: 15,
        backgroundColor: "white",
        borderRadius: "8px",
        border: "1px solid #ccc",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "37vw",
          overflowX: "auto",
        }}
      >
        <div>{name}</div>

        <input
          type="file"
          onChange={handleUploadImages}
          multiple={isMultiple}
          accept={accept}
          style={
            {
              //  color: 'transparent',
            }
          }
        />
      </div>
      <div style={{ marginBottom: 15 }}>
        {accept === "image/*" ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
              // droppableId="imageList"
              direction="horizontal"
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    display: "flex",
                    //  paddingBottom: 15,
                    marginTop: 15,

                    maxWidth: "40vw", // Set maximum height to screen height
                    overflowX: "auto",
                  }}
                >
                  {images.map((image, index) => (
                    <Draggable
                      key={image.id}
                      draggableId={image.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            margin: "0 5px",
                            position: "relative",
                            padding: "8px",
                            borderRadius: "8px",
                            marginBottom: 15,
                            border: "1px solid #ccc",
                          }}
                        >
                          <img
                            src={image.url}
                            alt="Imagex"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "8px",
                            }}
                          />
                          <MdOutlineCancel
                            onClick={() => handleRemoveImage(index)}
                            style={{
                              position: "absolute",
                              top: "8px",
                              right: "8px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : accept === ".pdf" && document !== null ? (
          <div>{document.name}</div>
        ) : accept === "video/mp4" && video !== null ? (
          <div>{video.name}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ReorderableImageList;
