function validateRentalAssets(assets) {
  const errors = [];
  if (assets.property_name.trim() === "") {
    errors.push("Property name is required");
  }
  if (assets.sell_or_rent.trim() === "") {
    errors.push("Sell or Rent is required");
  }
  if (assets.city.trim() === "") {
    errors.push("City is required");
  }

  if (!assets.location.trim() || assets.location.trim() === "") {
    errors.push("Location is required");
  }

  if (isNaN(assets.latitude) || assets.latitude < -90 || assets.latitude > 90) {
    errors.push("Latitude must be a number between -90 and 90");
  }

  if (
    isNaN(assets.longitude) ||
    assets.longitude < -180 ||
    assets.longitude > 180
  ) {
    errors.push("Longitude must be a number between -180 and 180");
  }

  if (!assets.postal_code.trim() || assets.postal_code.trim() === "") {
    errors.push("Postal code is required");
  }
  if (!assets.address.trim() || assets.address.trim() === "") {
    errors.push("Address is required");
  }

  if (assets.bedroom.trim() === "") {
    errors.push("Bedroom is required");
  }
  if (assets.bath_rooms === 0 || assets.bath_rooms >= 6) {
    errors.push("Valid bath room required");
  }

  if (assets.door_facing.trim() === "") {
    errors.push("Door facing is required");
  }
  
  if (assets.property_status.trim() === "") {
    errors.push("Property status is required");
  }

  if (assets.furnishing_status.trim() === "") {
    errors.push("Furnishing status is required");
  }

  if (assets.parking.trim() === "") {
    errors.push("Parking is required");
  }
  if (assets.property_age === 0) {
    errors.push("Property age is required");
  }

  if (assets.property_dimension === 0) {
    errors.push("Property dimension is required");
  }

  // if (assets.total_floor === 0) {
  //   errors.push("Property total floor is required");
  // }



  // if (assets.tenent_type.trim() === "") {
  //   errors.push("Tenant type is required");
  // }

  // if (assets.monthly_rent === 0) {
  //   errors.push("Monthly rent is required");
  // }

  // if (assets.maintenance_charge === 0) {
  //   errors.push("Maintenance charge is required");
  // }

  // if (assets.security_deposit === 0) {
  //   errors.push("Security deposit is required");
  // }

  if (assets.about.trim() === "") {
    errors.push("About is required");
  }

  // Parse the JSON string into a JavaScript object
  const parsedImages = JSON.parse(assets.property_images);

  // Check if the 'images' property is an empty object
  if (checkIfJsonIsEmpty(parsedImages.images)) {
    errors.push("Property images are required");
  }

  // Validate cover_image
  if (checkIfJsonIsEmpty(JSON.parse(assets.cover_image))) {
    errors.push("Cover image is required");
  }

  if (checkIfJsonIsEmpty(JSON.parse(assets.master_plan))) {
    errors.push("Masterplan is required");
  }
  if (checkIfJsonIsEmpty(JSON.parse(assets.brochure))) {
    errors.push("Brochure is required");
  }

  // if (checkIfJsonIsEmpty(JSON.parse(assets.floor_plan))) {
  //   errors.push("Floor plan is required");
  // }
  // if (!assets.video.trim() || assets.video.trim() === "") {
  //   errors.push("Video URL is required");
  // }

  // if (checkIfJsonIsEmpty(JSON.parse(assets.video))) {
  //   errors.push("Video is required");
  // }

  return errors;
}
function checkIfJsonIsEmpty(jsonObject) {
  // Check if the JSON object is empty
  return jsonObject && Object.keys(jsonObject).length === 0;
}
module.exports = validateRentalAssets;
