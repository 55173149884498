import { BASE_URL } from "../constant";
import resellAssetContext from "../context/ResellAssetContext/ResellAssetContext";

import { useState } from "react";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const ResellAssetState = (propsx) => {
  const [resell_assets, setResellAssets] = useState([]);
  const [totalResellAssets, setTotalResellAssets] = useState(0);
  const [resell_statistics, setResellStatistics] = useState(null);
  const [amenities, setAmenities] = useState([]);

    const getResellAssetStatistics = async (props) => {
      try {
        const response = await fetch(`${BASE_URL}/asset/resell/getassetstat`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.status === 200) {
          const json = await response.json();
          //  console.log(json.total);
          setResellStatistics(json.data);
          //console.log(statistics);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
        } else {
          // console.log("FAILED WITH STATUS CODE " + response.status.toString());
        }
      } catch (e) {
        // console.log(e.message);
        console.log("SERVER DOWN");
      }
    };

  const getAssetAmenities = async (props) => {
    const { ids } = props;

    const queryParams = {
      ids: ids,
    };

    try {
      const response = await fetch(
        `${BASE_URL}/filter/homznoffiz/amenity/get?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // const parsedData = json.data.map((item) => ({
        //   ...item,
        // }));

        if (json.data.length > 0) {
          // const groupByCategory = (amenities) => {
          //   return amenities.reduce(
          //     (acc, amenity) => {
          //       const { category } = amenity;
          //       if (!acc[category]) {
          //         acc[category] = [];
          //       }
          //       acc[category].push(amenity);
          //       if (!acc.list.includes(category)) {
          //         acc.list.push(category);
          //       }
          //       return acc;
          //     },
          //     { list: [] }
          //   );
          // };
          // const groupedAmenities = groupByCategory(json.data);

          setAmenities(json.data);
        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getResellSearchAssets = async (props) => {
    const {
      page,
      property_type,
      property_status,
      property_builder,
      property_name,
      bedroom,
      bath_rooms,
      balconies,
      door_facing,
      furnishing_status,
      parking,
      city,
     
      property_price
 

    } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      property_name: property_name,
      property_type: property_type,
      property_status: property_status,
      property_builder: property_builder,

      bedroom: bedroom,
      bath_rooms: bath_rooms,
      balconies: balconies,
      door_facing: door_facing,
      furnishing_status: furnishing_status,
      parking: parking,
      city: city,
     
      property_price: property_price,
      
      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/asset/search/resell/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        const parsedData = json.data.map((item) => ({
          ...item,
          property_images: JSON.parse(item.property_images),
          floor_plan: JSON.parse(item.floor_plan),

          brochure: JSON.parse(item.brochure),
          cover_image: JSON.parse(item.cover_image),
          master_plan: JSON.parse(item.master_plan),

          aminities: JSON.parse(item.aminities),
          faqs: JSON.parse(item.faqs),
          landmarks: JSON.parse(item.landmarks),

        }));
        // console.log(parsedData);
        setResellAssets(parsedData);
        setTotalResellAssets(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getResellAssets = async (props) => {
    const { page } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = { limit: limit, offset: offset };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/asset/resell/getallx?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        const parsedData = json.data.map((item) => ({
          ...item,
           property_images: JSON.parse(item.property_images),
          floor_plan: JSON.parse(item.floor_plan),
 

          brochure: JSON.parse(item.brochure),
          cover_image: JSON.parse(item.cover_image),
          master_plan: JSON.parse(item.master_plan),

           aminities: JSON.parse(item.aminities),
          faqs: JSON.parse(item.faqs),
          landmarks: JSON.parse(item.landmarks),

        }));
        //   console.log(parsedData);
        setResellAssets(parsedData);
        setTotalResellAssets(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const addResellAssetx = async (props) => {
    const { data } = props;

    try {
      const response = await fetch(`${BASE_URL}/asset/rentalResale/create`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        body: JSON.stringify({ data }),

      });

      if (response.status === 200) {
        getResellAssets({ page: 1 });
        toast.success("Resell Asset Created successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't Add asset, Try again later");
      }
    } catch (e) {
      toast.error("Couldn't Add asset, Try again later");
    }
  };

  const deleteResellAssets = async (props) => {
    const { ids, page } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/asset/bulk/resell/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ ids: ids }),
      });
      if (response.status === 200) {
        // const json = response.json();
        getResellAssets({ page: page });
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete builder(s), Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete builder(s), Try again later");

      // console.log(e.message);
    }
  };

  const UpdateResellAnAsset = async (props) => {
    const { id, data } = props;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/asset/update/resell/${id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ data }),
        });

        if (response.status === 200) {
          toast.success("Resell Asset updated successfully");

          getResellAssets({ page: 1 });

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update user's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update user's status, Try again later");
        reject(error);
      }
    });
  };

  return (
    <resellAssetContext.Provider
      value={{

        resell_assets,
        totalResellAssets,

        getResellSearchAssets,
        getResellAssets,

        getResellAssetStatistics,
        resell_statistics,


        addResellAssetx,

        deleteResellAssets,

        amenities,
        getAssetAmenities,

        UpdateResellAnAsset,
      }}
    >
      {propsx.children}
    </resellAssetContext.Provider>
  );
};
export default ResellAssetState;
