import React, { useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { IMAGE_PATH } from "../../constant";

import { FaRegEdit } from "react-icons/fa";
import builderContext from "../../context/BuildersContext/BuilderContext";
import Pagination from "../.././components/Pagination";
import useConfirm from "../AlertDialog";
import FilterModal from "./FiltersModal";
import Modal from "./BuilderDetailsModal";
import { CiSearch } from "react-icons/ci";
import { AiFillDelete, AiOutlineStock } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoFilter } from "react-icons/io5";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";

import Switch from "react-switch";

import UserImage from "../../../src/assets/images/user.png"; // Path to your image file
import ErrorImage from "../../../src/assets/images/error.png"; // Path to your image file

const AllBuildersx = () => {
  const [checked, setChecked] = useState([]);
  const [query, setQuery] = useState("");

  const [filters, setFilters] = useState(null);

  // let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(builderContext);
  const {
    builders,
    totalBuilders,
    statistics,
    getBuilders,
    getBuilderStatistics,
    getSearchBuilders,
    deleteBuilders,

    getBuilderInvoicesOverview,
    getBuilderSubscriptionsOverview,
    getBuilderSiteVisitOverview
     
  } = context;

  const [FilterDialog, ApplyFilters] = FilterModal();

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Builders!",
    `Are you sure you want to delete ${
      checked.length > 1 ? "these builders" : "this builder"
    } : ${checked}? `
  );

  const handleFilters = async () => {
    const filtersx = await ApplyFilters();
    if (filtersx === null) {
    } else {
      setCurrentPage(1);
      getSearchBuilders({
        page: currentPage,
         status: filtersx.status,
        subscription: filtersx.subscription,
        kyc: filtersx.kyc,
        query_string: query,
      });
      setFilters({
         status: filtersx.status,
        subscription: filtersx.subscription,
        kyc: filtersx.kyc,
      });
      // console.log(filters);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: 0,
    phone: 8114613927,
    image_url: "",
    name: "",
    email: "",
    status: 0,
    subscription: 0,
    kyc_verified: 0,

  });

  const openModal = (user) => {
    // const usserr = {
    //   id: user.id,
    //   phone: user.phone,
    //   image: user.image,
    //   name: user.name,
    //   email: user.email,
    //   is_manager: user.is_manager,
    // };

    setSelectedUser(user);
    if (localStorage.getItem("token")) {
      getBuilderInvoicesOverview({ id: user.id });
      getBuilderSubscriptionsOverview({ id: user.id });
      getBuilderSiteVisitOverview({ id: user.id });
     } else {
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDelete = async () => {
    const ans = await confirmDelete();
    if (ans) {
      handleDeleteUsers();
    } else {
      /* ... */
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (localStorage.getItem("token")) {
      query.length > 0 || filters !== null
        ? getSearchBuilders({
            page: currentPage,
            type: filters !== null ? filters.type : [],
            status: filters !== null ? filters.status : [],
            subscription: filters !== null ? filters.subscription : [],
            kyc: filters !== null ? filters.kyc : [],
            query_string: query,
          })
        : getBuilders({ page: newPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const handleDeleteUsers = async () => {
    if (localStorage.getItem("token")) {
      await deleteBuilders({ ids: checked, page: currentPage });
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const assetData = [
    {
      key: "Total builders in DB",
      value: statistics !== null ? statistics.total_builders : 0,
    },
 
  ];

  const logData = [
    {
      key: "Total Active Builders",
      value: statistics !== null ? statistics.total_active_builders : 0,
    },
    {
      key: "Total Blocked Builders",
      value: statistics !== null ? statistics.total_blocked_builders : 0,
    },
    {
      key: "Total Verified Builders",
      value: statistics !== null ? statistics.total_verified_builders : 0,
    },
    {
      key: "Total Unverified Builders",
      value: statistics !== null ? statistics.total_unverified_builders : 0,
    },
    {
      key: "Total Subscribed Builders",
      value: statistics !== null ? statistics.total_subscribed_builders : 0,
    },
    {
      key: "Total Unsubscribed Builders",
      value: statistics !== null ? statistics.total_unsubscribed_builders : 0,
    },
  ];

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    //console.log(updatedList);
  };
  const handleSwitch = (event) => {
   // setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };
  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);

    getSearchBuilders({
      page: currentPage,
      type: filters !== null ? filters.type : [],
      status: filters !== null ? filters.status : [],
      subscription: filters !== null ? filters.subscription : [],
      kyc: filters !== null ? filters.kyc : [],
      query_string: query,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getBuilders({ page: currentPage });
      getBuilderStatistics();
    } else {
    }

    // eslint-disable-next-line
  }, []);



  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            margin: 0,
            maxWidth: "100%",
          }}
        >
          <ToastContainer position="top-center" />
          <Modal isOpen={isOpen} onClose={closeModal} user={selectedUser} />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 15,
            }}
          >
            <div
              style={{
                fontSize: 18,
              }}
            >
              All builders({totalBuilders})
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <form
                className="form_search"
                onSubmit={handleSubmit}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  className="serach_asset"
                  variant="outlined"
                  placeholder="Search builders..."
                  value={query}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton type="submit" edge="start">
                        <CiSearch />
                      </IconButton>
                    ),
                    style: {
                      height: "32px",
                      minWidth: "30vw", // Adjust the height as needed
                      flexGrow: 1, // Allow the input to grow to fill available space
                    },
                  }}
                  fullWidth
                />
              </form>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  //disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleFilters}
                >
                  <IoFilter color="grey" />
                </IconButton>
                <FilterDialog />
              </div>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  marginRight: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleDelete}
                >
                  <AiFillDelete color={checked.length === 0 ? "grey" : "red"} />
                </IconButton>
                <Dialog />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 15,
              fontFamily: "monospace",
              fontWeight: 550,
              textAlign: "left",
              maxWidth: "100%",
              paddingTop: 10,
              paddingLeft: 20,
              color: "grey",
              alignItems: "center",
              justifySelf: "start",
              height: 35,
            }}
          >
            <div
              style={{
                width: "3vw",
                height: 50,
                fontWeight: 600,
                marginLeft: 10,
               
              }}
            >
              ID
            </div>
            <div
              style={{
                width: "9vw",
                height: 50,
                maxLines: 1,
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "10vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Email
            </div>
            <div
              style={{
                width: "6vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Phone
            </div>
            <div
              style={{
                width: "3vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Image
            </div>
            <div
              style={{
                width: "4vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Verified
            </div>

            <div
              style={{
                width: "12vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Address
            </div>

            <div
              style={{
                width: "10vw",
                height: 50,
                maxLines: 1,
                marginLeft: 10,
                // backgroundColor: "black",
              }}
            >
              Last update
            </div>
            <div
              style={{
                width: "3vw",
                height: 50,
                maxLines: 1,
                marginRight: 15,
                // backgroundColor: "black",
              }}
            >
              Action
            </div>
          </div>
          <div
            style={{
              minHeight: "78vh",
              // justifyContent: "center",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
            }}
          >
            {builders.length > 0 ? (
              builders.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    maxWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                    backgroundColor: "transparent",
                    fontWeight: 530,
                    textAlign: "left",
                    fontFamily: "Montserrat",
                    fontSize: 12,

                    maxLines: 1,
                    // backgroundColor: "black",
                    // borderRadius: 10 /* Adjust border radius as needed */,
                    borderBottom: ".5px solid #d8d0d0",
                    // paddingTop: 10,
                    marginTop: 5,
                  }}
                >
                  <input
                    value={item.id}
                    type="checkbox"
                    onChange={handleCheck}
                    style={{
                      alignContent: "start",
                      backgroundColor: "#000",
                      marginLeft: "10px",
                      // marginBottom: "30px",
                    }}
                  />

                  <div
                    style={{
                      width: "3vw",
                      height: 50,
                      fontWeight: 600,
                      marginLeft: 10,
                      alignContent: "center",
                    //  paddingTop: "15px",
                    }}
                  >
                    {item.id}
                  </div>

                  <div
                    style={{
                      width: "9vw",
                      height: 50,
                      //  marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.name}
                  </div>

                  <div
                    style={{
                      width: "10vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.email}
                  </div>

                  <div
                    style={{
                      width: "6vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    (+91) {item.phone}
                  </div>
                  <div
                    style={{
                      width: "3vw",
                      height: 50,
                      marginLeft: 10,

                      margin: 5,
                    }}
                  >
                    <img
                      src={
                        item.image_url !== "" ? IMAGE_PATH + item.image_url : UserImage
                      }
                      alt="banner"
                      style={{
                        margin: "auto",
                        width: "2.5vw",
                        height: "2.5vw",
                        borderRadius: 10 /* Adjust border radius as needed */,
                        //border: ".5px solid rgb(194, 190, 190)",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: "4vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      paddingTop: "15px",
                       zIndex:1,
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                  <Switch onChange={handleSwitch} checked={item.status} />

                    {/* {item.is_manager === 1 ? "Yes" : "No"} */}
                  </div>
                  <div
                    style={{
                      width: "12vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.address}
                  </div>

                  <div
                    style={{
                      width: "10vw",
                      height: 50,
                      marginLeft: 10,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "black",
                      overflow: "hidden",
                    }}
                  >
                    {item.updated_at}
                  </div>
                  <div
                    style={{
                      width: "3vw",
                      height: 50,
                      marginRight: 15,
                      maxLines: 1,
                      paddingTop: "15px",

                      display: "inline-block",
                      whiteSpace: "nowrap",
                      // backgroundColor: "white",
                      // overflow: "hidden",
                    }}
                  >
                    <IconButton
                      onClick={() => openModal(builders[index])}
                      size="small"
                      type="submit"
                      edge="start"
                    >
                      <FaRegEdit />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  marginTop: "20vh",
                  justifyContent: "center",
                  //backgroundColor: "black",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    marginTop: 15,
                    width: "10vw",
                  }}
                  alt=""
                  src={ErrorImage}
                />
                <div
                  style={{
                    marginTop: 30,
                    fontSize: 18,

                    fontFamily: "Montserrat",
                  }}
                >
                  Zero record not found in database
                </div>
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 15,

                    fontFamily: "Montserrat",
                  }}
                >
                  Try a different query or filter
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div
              style={{
                marginTop: 15,
                marginLeft: 15,
                marginBottom: 10
              }}
            >
              Showing {builders.length} out of {totalBuilders}
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalBuilders / 10)}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <Card
              style={{
                minWidth: 300,
                maxWidth: 400,
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    User Statistics
                  </Typography>
                  <IconButton aria-label="edit">
                    <AiOutlineStock color="white" />
                  </IconButton>
                </div>
                {assetData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>{item.key}</Typography>
                    <Typography>{item.value}</Typography>
                  </div>
                ))}
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              style={{
                minWidth: 300,
                maxWidth: 400,
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    User Status
                  </Typography>
                </div>
                {logData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      color: "white",
                    }}
                  >
                    <Typography>{item.key}</Typography>
                    <Typography>{item.value}</Typography>
                  </div>
                ))}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

 

export default AllBuildersx;
