import React, { useState, useContext, useEffect } from "react";
import { BASE_URL, IMAGE_PATH } from "../../constant";
import { CiImageOff } from "react-icons/ci";
import useConfirm from "../AlertDialog";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import blogContext from "../../context/BlogsContext/BlogsContext";
import { toast } from "react-toastify";

import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddHomeWork, MdCancel } from "react-icons/md";
import { IconButton, TextField } from "@mui/material";

import TimeAgo from "../TimeAgo";

const Blogs = (props) => {
  const context = useContext(blogContext);
  const { blogs, getBlogs, createBlogs, updateBlog, deleteBlog } = context;

  const [selectedAssetStatus, setSelectedAssetStatus] = useState({
    id: 0,
    title: "",
    description: "",
    author: "",
    content: "",
    image: null,
  });

  const [isAdding, setIsAdding] = useState(true);

  const handleIsAdding = (pt, val) => {
    setSelectedAssetStatus({
      id: pt.id,
      title: pt.title,
      description: pt.description,
      author: pt.author,
      content: pt.content,
      image: pt.image,
    });
    setIsAdding(val);
  };
  const handleData = (key, value) => {
    setSelectedAssetStatus((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  const handleImageChange = async (key, event) => {
    if (key === "imageadd") {
      const files = Array.from(event.target.files);

      try {
        if (files.length > 0) {
          const formData = new FormData();

          formData.append("profilex", files[0]);

          const queryParams = {
            folderName: "default",
          };

          const response = await fetch(
            `${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`,
            {
              method: "POST",
              headers: {
                // "Content-Type": "application/json",
                // Accept: "application/json",
              },
              body: formData,
            }
          );
          if (response.status === 200) {
            const json = await response.json();

            const selectedFile = files[0];
            const reader = new FileReader();

            reader.onload = (value) => {
              handleData("image", json.data[0]);
            };

            if (selectedFile) {
              reader.readAsDataURL(selectedFile);
            }
            //toast.success("Media uploded successfully");
          } else {
            // toast.error("Couldn't upload media(s), Try again later");
            console.log(response.status);
          }
        }
      } catch (e) {
        console.log(e);
        // toast.error("Couldn't upload media(s), Try again later");
      }
    } else {
      try {
        const queryParams = { filePath: selectedAssetStatus.image.path };

        const response = await fetch(
          `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          handleData("image", null);
        } else {
          console.log("error");
          // toast.error("Couldn't upload media(s), Try again later");
        }
      } catch (e) {
        console.log(e);
        // toast.error("Couldn't upload media(s), Try again later");
      }
    }

    // console.log(roadsandLandmarks);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getBlogs();
    } else {
    }

    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    if (selectedAssetStatus.title.length > 0) {
      if (isAdding) {
        createBlogs(selectedAssetStatus);
      } else {
        updateBlog(selectedAssetStatus);
      }
      setSelectedAssetStatus({
        id: 0,
        title: "",
        description: "",
        author: "",
        content: "",
        image: null,
      });
    } else {
      toast.error("Please enter valid title, description, author and content");
    }
  };

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Blog!",
    `Are you sure you want to delete ${selectedAssetStatus.name}? `
  );

  const handleDelete = async (idx) => {
    const ans = await confirmDelete();
    if (ans) {
      deleteBlog({ id: idx });
    } else {
      /* ... */
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(196, 196, 196, 0.15)",
          margin: 0,
          width: "80vw",
          height: "98vh",
          maxHeight: "98vh", // Set maximum height to screen height
          overflowY: "auto", // Enable vertical scrolling if content exceeds height
          border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <ToastContainer position="top-center" />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: 15,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 600 }}>
            {isAdding ? "Add Blog" : "Update Blog"}
          </div>

          <div style={{ display: "flex" }}>
            <form
              style={{
                maxWidth: "100%",
                minWidth: "100%",
                marginTop: 25,
                backgroundColor: "white",
                padding: 20,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginRight: 30 }}>
                  <label style={{ marginBottom: "10px", flex: 1 }}>
                    Blog Title
                  </label>
                  <TextField
                    id="propertyName"
                    placeholder="Enter title" // Hint text
                    variant="outlined"
                    value={selectedAssetStatus.title}
                    fullWidth
                    onChange={(e) => handleData("title", e.target.value)}
                    style={{ backgroundColor: "white" }}
                    inputProps={{
                      style: { height: 9, color: "black" },
                    }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
                <div style={{ marginRight: 30 }}>
                  <label style={{ marginBottom: "10px", flex: 1 }}>
                    Blog Description
                  </label>
                  <TextField
                    id="propertyName"
                    placeholder="Enter description" // Hint text
                    variant="outlined"
                    value={selectedAssetStatus.description}
                    fullWidth
                    onChange={(e) => handleData("description", e.target.value)}
                    style={{ backgroundColor: "white" }}
                    inputProps={{
                      style: { height: 9, color: "black" },
                    }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
                <div style={{ marginRight: 30 }}>
                  <label style={{ marginBottom: "10px", flex: 1 }}>
                    Blog Author
                  </label>
                  <TextField
                    id="propertyName"
                    placeholder="Enter author name" // Hint text
                    variant="outlined"
                    value={selectedAssetStatus.author}
                    fullWidth
                    onChange={(e) => handleData("author", e.target.value)}
                    style={{ backgroundColor: "white" }}
                    inputProps={{
                      style: { height: 9, color: "black" },
                    }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    backgroundColor: "white",

                    height: 120,
                    width: 120,

                    // justifyContent: "center",
                  }}
                >
                  {!selectedAssetStatus.image && (
                    <div>
                      <label style={{ marginBottom: "10px", flex: 1 }}>
                        Image
                      </label>
                      <div>
                        <label
                          htmlFor="fileInput"
                          style={{
                            cursor: "pointer",
                            display: "inline-block",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: 5,
                          }}
                        >
                          Choose File
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          accept=".jpg,.jpeg,.png,.svg"
                          onChange={(e) => handleImageChange("imageadd", e)}
                          style={{
                            display: "none",
                            borderRadius: 10,
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {selectedAssetStatus.image && (
                    <div style={{ alignItems: "center" }}>
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "5px",
                          position: "relative",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleImageChange("imageremove", e)}
                        >
                          <MdCancel size={25} />
                        </span>
                        <img
                          src={
                            IMAGE_PATH +
                            selectedAssetStatus.image.fieldname +
                            "/" +
                            selectedAssetStatus.image.filename
                          }
                          alt="Error"
                          style={{
                            width: "108px",
                            height: "120px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label style={{ marginBottom: "10px", flex: 1, marginTop: 10 }}>
                  Blog Content-HTML
                </label>
                <TextField
                  id="propertyDesc"
                  placeholder="Enter Description" // Hint text
                  variant="outlined"
                  value={selectedAssetStatus.content}
                  fullWidth
                  multiline
                  rows={10}
                  onChange={(e) => handleData("content", e.target.value)}
                  style={{ backgroundColor: "white" }}
                  inputProps={{ style: { height: 150 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>
              <div
                style={{
                  maxWidth: "15vw",
                  border: "1px solid #ffffff",
                  borderRadius: "6px",
                  color: "white",
                  margin: "15px 0px",
                  padding: "5px 10px",
                  backgroundColor: "#21232d",

                  textAlign: "center",
                }}
                // onClick={addType}
                onClick={() => handleSubmit()}
              >
                <IconButton type="submit" edge="start">
                  <MdOutlineAddHomeWork color="white" />
                </IconButton>
                {isAdding ? "Add this Blog" : "Update Blog"}
              </div>
            </form>
          </div>
        </div>

        <div
          style={{
            padding: "15px 20px",
            margin: "0px 0px",
            backgroundColor: "white",
          }}
        >
          <h5>All Blog ({blogs.length})</h5>

          <div
            style={{
              marginTop: "10px",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(25vw, 1fr))",
              gap: "10px",
            }}
          >
            {blogs.map((pt, index) => (
              <div
                key={pt.id}
                onClick={() => handleIsAdding(pt, false)}
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between", // Adjusted to ensure space between title, description, and footer
                }}
              >
                <div style={{ marginBottom: "10px" }}>
                  {pt.image !== null ? (
                    <img
                      src={
                        IMAGE_PATH +
                        pt.image.fieldname +
                        "/" +
                        pt.image.filename
                      }
                      alt="Icon"
                      style={{
                        width: "100%", // Adjusted to fill the container width
                        height: "auto", // Maintain aspect ratio
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <IconButton type="submit" edge="start">
                      <CiImageOff color="black" />
                    </IconButton>
                  )}
                </div>

                <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  {pt.title}
                </div>

                <div
                  style={{
                    marginBottom: "10px",
                    maxHeight: "50px",
                    overflow: "hidden",
                  }}
                >
                  {pt.description}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TimeAgo timestamp={pt.updated_at} />

                  <div
                    style={{
                      backgroundColor: "rgba(192, 192, 192, 0.4)",
                      borderRadius: "7px",
                      padding: "5px",
                    }}
                  >
                    <IconButton
                      onClick={() => handleDelete(pt.id)}
                      size="small"
                      style={{ color: "red" }}
                    >
                      <AiOutlineDelete style={{ cursor: "pointer" }} />
                    </IconButton>
                    {/* Add your Dialog component here if needed */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
