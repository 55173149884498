import React, { useContext, useState } from "react";

import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../provider/AuthProvider";
import adminContext from "../../context/AdminContext/AdminContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = () => {
  // const { setToken } = useAuth();
  const navigate = useNavigate();
  const context = useContext(adminContext);
  const { LoginAdmin } = context;
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleData = (key, value) => {
    setUser((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };
  // const handleLogin = () => {
  //   setToken(
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN1bHQiOnsiaWQiOjEwMDAwLCJuYW1lIjoiU0lESEFSVEhBIiwiZW1haWwiOiJzaWRoYXJ0aGE3NTgwMTNAZ21haWwuY29tIiwicGhvbmUiOiIxMjM0NTY3ODkwIiwiaW1hZ2UiOiJwcm9maWxlL3Byb2ZpbGVfMTcwNTA1Nzk2ODgwMC5qcGciLCJpc19tYW5hZ2VyIjoxLCJnZW5kZXIiOiIiLCJhZGRyZXNzIjoiQWdyaWdvbGQgRm9ydHVuZSBmbGF0IHBlbnRob3VzZSA0MDEsIEFyZWtlcmVnYXRlIDU2ODc1NSIsImFnZSI6MCwic3RhdHVzIjoxLCJzdWJzY3JpcHRpb24iOjEsImt5Y192ZXJpZmllZCI6MSwiY3JlYXRlZF9hdCI6IjIwMjQtMDMtMDZUMDk6NTU6MDEuMDAwWiIsInVwZGF0ZWRfYXQiOiIyMDI0LTAzLTEzVDA2OjA4OjU1LjAwMFoifSwiaWF0IjoxNzExNDMxNjUxLCJleHAiOjE3MTI3Mjc2NTF9.76q7e0r0dkcKtdoNDaT0u9av1fClUcSBdPBJcpq_onM"
  //   );
  //   navigate("/", { replace: true });
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await LoginAdmin(user);
      // Promise resolved
      console.log("Promise resolved:", result);
      navigate("/", { replace: true });
      // Perform operations based on resolved result
    } catch (error) {
      // Promise rejected
      console.error("Promise rejected:", error);
      // Perform operations based on rejected error
    }

    // onSearch(query);
  };

  //const [isEditModalOpen, setIsEditModalOpen] = useState(true);
  const isEditModalOpen = true;

  // const handleEditClick = () => {
  //   setIsEditModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsEditModalOpen(false);
  // };
  const handleSubmit2 = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div
        style={{
          minWidth: "70vw",
          minHeight: "60vh",
          display: "flex",
          marginTop: "15vh",
          justifyContent: "center",
        }}
      >
        {isEditModalOpen && (
          <div
            style={{
              backgroundColor: "rgba(196, 196, 196, 0.15)",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: "22vw",
              borderRadius: 10,
              border: "1px solid black",
              padding: 20,
            }}
          >
            <ToastContainer position="top-center" />

            <form style={{}} onSubmit={handleSubmit2}>
              <h4>Elevate Your Lifestyle </h4>

              <h6>Explore Real Estate Opportunities Today</h6>

              <h5 style={{ marginTop: 25 }}>Login Now</h5>

              <div>
                <label style={{ marginBottom: "10px", marginTop: 15, flex: 1 }}>
                  Email
                </label>
                <TextField
                  id="aemail"
                  placeholder="Email" // Hint text
                  variant="outlined"
                  value={user.email}
                  onChange={(e) => handleData("email", e.target.value)}
                  fullWidth
                  style={{ backgroundColor: "white" }}
                  inputProps={{ style: { height: 9 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>
              <div>
                <label style={{ marginBottom: "10px", marginTop: 15, flex: 1 }}>
                  Password
                </label>
                <TextField
                  id="apass"
                  placeholder="Password" // Hint text
                  variant="outlined"
                  value={user.password}
                  onChange={(e) => handleData("password", e.target.value)}
                  fullWidth
                  style={{ backgroundColor: "white" }}
                  inputProps={{ style: { height: 9 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>

              <label style={{ marginBottom: "10px", marginTop: 15, flex: 1 }}>
                Don't have an account. Contact your admin now
              </label>
              <button
                onClick={handleSubmit}
                style={{
                  marginTop: 20,
                  height: "50px",
                  width: "100%", // Take available space
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                  borderRadius: "5px", // Optional: Add border radius for better appearance
                }}
              >
                Login
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default LoginPage;
