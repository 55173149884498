import React, { useState, useContext, useEffect } from "react";
import { IMAGE_PATH } from "../../constant";
import useConfirm from "../AlertDialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fileManagerContext from "../../context/FileMangerContext/FileManagerContext";
import TimeAgo from "../TimeAgo";
import { MdCloudUpload, MdCreateNewFolder } from "react-icons/md";

import FilterModal from "./FileFiltersModal";
import { CiSearch } from "react-icons/ci";
import { IoFilter } from "react-icons/io5";

import { GoFileDirectoryFill } from "react-icons/go";
import { FaFileImage, FaVideo, FaFilePdf } from "react-icons/fa6";
// import { PiFilePdfDuotone } from "react-icons/pi";

import { AiOutlineDelete, AiFillFileUnknown } from "react-icons/ai";
import { IconButton, TextField } from "@mui/material";
import FileModal from "./DirectoryInputNameModal";

const MyFileManager = (props) => {
  const context = useContext(fileManagerContext);

  const {
    directoryContents,
    getAllDirectoryContents,

    // recentDirectoryContents,
    getAllRecentDirectoryContents,

    getSearchDirectoryContents,
    createDirectory,
    uploadMediaInDirectory,

    deleteDirectory,
    deleteMedia,
  } = context;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveFileName = (fileName) => {
    // You can do something with the fileName here, like saving it to state or performing an action
    if (fileName.length > 0) {
      createDirectory({ dpath: currentDirectory, dname: fileName });
    } else {
      toast.error(
        "Directory name must be eqaual to or more than one charecter."
      );
    }
  };
  const handleUploadMedia = (filesx) => {
    uploadMediaInDirectory({ dpath: currentDirectory, files: filesx });
  };
  const [isHovered, setIsHovered] = useState(-1);
  const [isHoveredPath, setIsHoveredPath] = useState(-1);

  const [currentDirectory, setCurrentDirectory] = useState("/");
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAllDirectoryContents({ path: "", type: "all" });

      getAllRecentDirectoryContents({ path: "", type: "all" });
    } else {
    }

    // eslint-disable-next-line
  }, []);

  const handleInputChange = (event) => {
    setQuery(event.target.value);

    //  console.log(identifyInput(event.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const matchedItems = searchList(directoryContents, query);

    getSearchDirectoryContents({ list: matchedItems });
    // console.log(matchedItems);
  };

  const [FilterDialog, ApplyFilters] = FilterModal();

  const handleFilters = async () => {
    const filtersx = await ApplyFilters();
    if (filtersx === null) {
    } else {
      //  console.log(filtersx.file_type.toString());
      getAllDirectoryContents({
        path: currentDirectory,
        type: filtersx.file_type.toString(),
      });
    }
  };

  const [Dialog, confirmDelete] = useConfirm(
    "Deleting Project Directory!",
    `Are you sure you want to delete this? `
  );

  const handleDelete = async (content) => {
    const ans = await confirmDelete();
    if (ans) {
      //console.log(pathx);

      if (content.type === "media") {
        deleteMedia({ path: content.relpath });
      } else if (content.type === "directory") {
        deleteDirectory({ path: content.path });
      }
      // deleteAssetsAmenity({ id: idx });
    } else {
      /* ... */
    }
  };

  const handleNavigate = async (content) => {
    if (content.type === "directory") {
      getAllDirectoryContents({ path: content.path, type: "all" });
    } else {
      window.open(
        IMAGE_PATH + content.name.split("_")[0] + "/" + content.name,
        "_blank"
      );
    }
    // console.log(content.path.split("/"));
    setCurrentDirectory(content.path);
  };

  const handleURLNavigation = async (path) => {
    // console.log(currentDirectory.split(path)[0]+path);
    const pathx = currentDirectory.split(path)[0] + path;
    getAllDirectoryContents({ path: pathx, type: "all" });

    //  console.log(content.path.split("/"));
    setCurrentDirectory(pathx);
  };

  function searchList(list, query) {
    // Convert the query to lowercase for case-insensitive search
    const lowercaseQuery = query.toLowerCase();

    // Filter the list to include only items whose names contain the search query
    const matchedItems = list.filter((item) =>
      item.name.toLowerCase().includes(lowercaseQuery)
    );

    return matchedItems;
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(196, 196, 196, 0.15)",
          margin: 0,
          width: "83vw",
          height: "97vh",
          maxHeight: "98vh", // Set maximum height to screen height
          overflowY: "auto", // Enable vertical scrolling if content exceeds height
          border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <ToastContainer position="top-center" />
        <FileModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveFileName}
        />

        <div
          style={{
            padding: "15px 20px",
            margin: "0px 0px",
            backgroundColor: "white",
          }}
        >
          <h5>
            Showing {directoryContents.length}{" "}
            {directoryContents.length >= 2
              ? "directories and contents"
              : "directory/content"}{" "}
            from{" "}
          </h5>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              height: 20,
              justifyContent: "start",
              // backgroundColor:"black"
              // alignItems:"center",
              // alignContent:"center"
            }}
          >
            {currentDirectory.split("/").map((path, index) => (
              <div
                key={index}
                // onClick={() => handleIsAdding(pt, false)}
                onMouseEnter={() => setIsHoveredPath(index)}
                onMouseLeave={() => setIsHoveredPath(-1)}
                onClick={() => handleURLNavigation(path)}
                //onDoubleClick={() => handleNavigate(pt)}
                style={{
                  // padding: "10px",
                  // border: "1px solid #ccc",
                  // borderRadius: "8px",
                  // alignItems: "center",
                  // paddingTop:5,
                  display: "flex",
                  fontSize: 12,
                  // height: "15vh",
                  fontFamily: "Montserrat",
                  backgroundColor:
                    index === isHoveredPath ? "#ccc" : "transparent", // Grey color when hovered
                  transition: "background-color 0.3s ease", // Smooth transition
                }}
              >
                <h6 style={{ color: "grey" }}>
                  {path === "" ? "root" : path}/
                </h6>
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: 15,
              display: "flex",
              // backgroundColor:"black",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  paddingLeft: 10,
                  // marginRight: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  //disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  onClick={handleFilters}
                >
                  <IoFilter color="grey" />
                </IconButton>
                <FilterDialog />
              </div>
              <form
                className="form_search"
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  backgroundColor: "white",
                  marginLeft: 15,
                  alignItems: "center",
                }}
              >
                <TextField
                  className="serach_asset"
                  variant="outlined"
                  placeholder="Search files..."
                  value={query}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton type="submit" edge="start">
                        <CiSearch />
                      </IconButton>
                    ),
                    style: {
                      height: "40px",
                      minWidth: "50vw", // Adjust the height as needed
                      flexGrow: 1, // Allow the input to grow to fill available space
                    },
                  }}
                  fullWidth
                />
              </form>
            </div>
            <div
              style={{
                display: "flex",

                // paddingLeft: 10,
                // marginLeft: 15,
                // backgroundColor: "rgba(192, 192, 192,0.4 )",
                // alignContent: "center",
                // justifyContent: "center",
                // borderRadius: 7,
              }}
            >
              <div
                onClick={handleOpenModal}
                //  onClick={() => handleCreateDirectory()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  paddingRight: 20,
                  borderRadius: 8,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  style={{
                    marginLeft: 10,
                    borderRadius: 7,
                    //  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  }}
                  //disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  //  onClick={handleFilters}
                >
                  <MdCreateNewFolder color="grey" />
                </IconButton>
                <label>Create Directory</label>
              </div>

              <div
                style={{
                  marginLeft: 20,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  paddingRight: 20,
                  borderRadius: 8,
                }}
              >
                <IconButton
                  type="submit"
                  edge="start"
                  style={{
                    marginLeft: 10,
                    // marginRight: 25,
                    borderRadius: 7,
                    // backgroundColor: "rgba(192, 192, 192,0.4 )",
                  }}
                  //disabled={checked.length === 0 ? true : false}
                  //  onClick={() => handleDeleteUsers()}
                  //  onClick={handleFilters}
                >
                  <MdCloudUpload color="grey" />
                </IconButton>
                <div>
                  <label
                    htmlFor="fileInput"
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      //  padding: "10px",
                      //border: "1px solid #ccc",
                      borderRadius: 5,
                      // width: 175,
                      // backgroundColor:"black",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Upload Media
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    multiple
                    accept=".jpg,.jpeg,.png,.gif,.bmp,.svg,.mp4,.avi,.mov,.mkv,.wmv,.flv,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.mp3,.wav,.flac,.aac,.ogg,.wma"
                    onChange={(event) => handleUploadMedia(event.target.files)}
                    style={{
                      display: "none",
                      borderRadius: 10,
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(13vw, 1fr))",
              gap: "10px",
            }}
          >
            {directoryContents.map((pt, index) => (
              <div
                key={pt.name}
                //onClick={() => handleIsAdding(pt, false)}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(-1)}
                onDoubleClick={() => handleNavigate(pt)}
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  alignItems: "center",
                  display: "flex",
                  fontSize: 12,
                  height: "15vh",
                  fontFamily: "Montserrat",
                  backgroundColor: index === isHovered ? "#ccc" : "transparent", // Grey color when hovered
                  transition: "background-color 0.3s ease", // Smooth transition
                }}
              >
                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "start",
                    width: "15vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",

                      // backgroundColor:"black",
                      justifyContent: "space-between",
                      //alignItems:"end",
                      // borderTop: "1px solid light-grey"
                      // maxWidth: "14vw",
                    }}
                  >
                    <IconButton
                      size="medium"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "rgba(192, 192, 192,0.4 )",
                      }}
                    >
                      {pt.type === "directory" ? (
                        <GoFileDirectoryFill
                          style={{ cursor: "pointer" }}
                          color="black"
                        />
                      ) : pt.type === "media" &&
                        pt.mimeType.includes("image") ? (
                        <FaFileImage
                          style={{ cursor: "pointer" }}
                          color="green"
                        />
                      ) : pt.type === "media" &&
                        pt.mimeType === "application/pdf" ? (
                        <FaFilePdf
                          style={{ cursor: "pointer" }}
                          color="purple"
                        />
                      ) : pt.type === "media" && pt.mimeType === "video/mp4" ? (
                        <FaVideo style={{ cursor: "pointer" }} color="orange" />
                      ) : (
                        <AiFillFileUnknown style={{ cursor: "pointer" }} />
                      )}
                    </IconButton>

                    <div
                      style={{
                        //backgroundColor: "rgba(192, 192, 192,0.4 )",
                        alignContent: "center",
                        justifyContent: "center",
                        borderRadius: 7,
                      }}
                    >
                      {pt.size} {pt.unit}
                    </div>
                  </div>

                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: 5,
                      fontSize: 12,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      //  backgroundColor:"black",
                      maxWidth: "12vw",
                    }}
                  >
                    {pt.name}
                  </div>

                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: 5,
                      fontSize: 9,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      //  backgroundColor:"black",
                      color: "grey",
                      maxWidth: "12vw",
                    }}
                  >
                    {pt.path}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 5,
                      // backgroundColor:"black",
                      justifyContent: "space-between",
                      //alignItems:"end",
                      // borderTop: "1px solid light-grey"
                      // maxWidth: "14vw",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontStyle: "italic",
                        marginTop: 5,
                        color: "grey",
                        fontSize: 10,
                      }}
                    >
                      <TimeAgo timestamp={pt.updated} />
                    </div>
                    <div
                      style={{
                        //backgroundColor: "rgba(192, 192, 192,0.4 )",
                        alignContent: "center",
                        justifyContent: "center",
                        borderRadius: 7,
                      }}
                    >
                      {/* <IconButton size="small" onClick={handleEditClick}>
                        <FiEdit2 style={{ cursor: "pointer" }} />
                      </IconButton>   */}

                      <IconButton
                        onClick={() => handleDelete(pt)}
                        size="small"
                        style={{ color: "red" }}
                      >
                        <AiOutlineDelete style={{ cursor: "pointer" }} />
                      </IconButton>
                      <Dialog />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyFileManager;
