import { BASE_URL } from "../constant";
import TransactionContext from "../context/TransactionsContext/TransactionsContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TransactionState = (propsx) => {

  const [transactions, setTransactions] = useState([]);

  const [totalTransactions, setTotalTransactions] = useState(0);
 
  const getSearchTransactions = async (props) => {
    const { page, is_credited, status, query_string } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
        is_credited: is_credited,
      status: status,
      
      query_string: query_string,
      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/transaction/search/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
 
        setTransactions(json.data);
        setTotalTransactions(json.total);
      } else if(response.status===403) {
        toast.error("Your access has been revoked, Contact your Admin");
       }else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
 
  const deleteTransactions = async (props) => {

    const { ids, page } = props;
      
    try {
      const response = await fetch(`${BASE_URL}/transaction/bulk/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ ids: ids }),
      });
      if (response.status === 200) {
        // const json = response.json();
        getSearchTransactions({ page: page, status:[], is_credited:[],query_string:"" });
        toast.success("Deleted successfully");
      }else if(response.status===403) {
        toast.error("Your access has been revoked, Contact your Admin");
       } else {
        toast.error("Couldn't delete lead(s), Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete lead(s), Try again later");

      // console.log(e.message);
    }
  };

  return (
    <TransactionContext.Provider
      value={{
        transactions,
        totalTransactions,
 
        getSearchTransactions,
        deleteTransactions,

       
      
      }}
    >
      {propsx.children}
    </TransactionContext.Provider>
  );
};
export default TransactionState;
