import React, { useState, useContext, useEffect } from "react";
import assetContext from "../../context/AssetsContext/AssetContext.js";
import CreatableSelect from "react-select/creatable";
import ReorderableImageList from "../Projects/ImagesProv.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoAddCircle } from "react-icons/io5";
import { IoIosRemove, IoMdArrowDropdown } from "react-icons/io";
import { SpinnerDotted } from "spinners-react";
import { TextField, IconButton } from "@mui/material";
import { BASE_URL, IMAGE_PATH } from "../../constant.js";
import { toast } from "react-toastify";
import { MdOutlineExpandMore, MdCancel } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";

const AddRentNresale = () => {
  const validateRentalAssets = require("./validateRentalAssets.js");
  const [spinnerStatus, SetSpinnerStatus] = useState(false);

  const context = useContext(assetContext);
  const {
    getAvailableFilters,
    availableFilters,

    addResaleRentalAssetx,
  } = context;
  const handleRentalClick = async () => {
    SetSpinnerStatus(true);

    const errors = validateRentalAssets(rentalData);

    // Check for errors
    if (errors.length === 0) {
      toast.success("Validation successful!");

      // No errors, proceed with sending data to frontend
      addResaleRentalAssetx({ data: rentalData });
    } else {
      errors.map((error, index) => {
        toast.error(error);
      }); // Validation failed, handle errors
      //console.log("Validation failed. Errors:", errors);
    }

    SetSpinnerStatus(false);

    // navigate("/dashboard/assets/asset_all");
  };

  const landmarks_categories = [
    { id: 1, value: "Education", label: "Education" },
    { id: 2, value: "Transport", label: "Transport" },
    { id: 3, value: "Healthcare", label: "Healthcare" },
    { id: 4, value: "Businesshub", label: "Businesshub" },
    { id: 5, value: "Restaurant", label: "Restaurant" },
  ];
  const [rentalData, setRentalData] = useState({
    property_name: "",
    agent_id: JSON.parse(localStorage.getItem("me")).id,
    property_builder: "",
    property_type: "Apartment",
    property_price: 0,
    bath_rooms: 3,
    is_residential: true,
    property_age: 0,
    balconies: 0,
    door_facing: "",
    furnishing_status: "Furnished",
    parking: "",
    covered_parking: 0,
    open_parking: 0,
    property_dimension: 0,
    total_floor: 0,
    your_floor: 0,
    tenent_type: "",
    monthly_rent: 0,
    maintenance_charge: 0,
    security_deposit: 0,
    available_from: "",
    is_broker: true,
    brokerage: 0,
    is_gated_security: true,
    is_nonveg_allowed: true,
    city: "Bengaluru",
    location: "",
    address: "",
    postal_code: "",
    about: "",
    sell_or_rent: "Sell",
    bedroom: "3 BHK",
    property_status: "Ready to move in",
    aminities: JSON.stringify({}),
    landmarks: JSON.stringify({}),
    faqs: JSON.stringify({}),
    property_images: JSON.stringify({}),
    video: "",
    floor_plan: JSON.stringify({}),
    master_plan: JSON.stringify({}),
    cover_image: JSON.stringify({}),
    brochure: JSON.stringify({}),

    latitude: 0.0,
    longitude: 0.0,
    is_verified: 0,
  });

  const [propertyImages, setPropertyImages] = useState([]);

  const [expandedItemsRoadRR, setExpandedItemsRoadRR] = useState({});
  const [roadsandLandmarksRR, setRoadsAndLandmarkRR] = useState([]);

  const toggleExpandRoadRR = (index) => {
    setExpandedItemsRoadRR({
      ...expandedItemsRoadRR,
      [index]: !expandedItemsRoadRR[index],
    });
  };

  const deleteByIndexRoadRR = (index) => {
    setRoadsAndLandmarkRR((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleRoadRR = () => {
    let x = Math.floor(Math.random() * 100 + 1);
    const ok = {
      id: x,
      image: "",
      name: "",
      locality: "",
      category: "",
      lat: 0.0,
      lon: 0.0,
      distance: "",
    };
    setRoadsAndLandmarkRR(roadsandLandmarksRR.concat(ok));
    // console.log(roadsandLandmarks.length);
  };

  const handleRoadChangeRR = async (index, key, value) => {
    if (key === "imageadd" || key === "imageremove") {
      if (key === "imageadd") {
        try {
          if (value.target.files.length > 0) {
            const formData = new FormData();
            formData.append("profilex", value.target.files[0]);

            const queryParams = {
              folderName:
                rentalData.property_name !== ""
                  ? generateSlug(rentalData.property_name)
                  : "default",
            };

            const response = await fetch(
              `${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`,
              {
                method: "POST",
                headers: {
                  // "Content-Type": "application/json",
                  // Accept: "application/json",
                },
                body: formData,
              }
            );
            if (response.status === 200) {
              const json = await response.json();

              const selectedFile = value.target.files[0];
              const reader = new FileReader();

              reader.onload = (value) => {
                setRoadsAndLandmarkRR((prevList) => {
                  const newList = [...prevList];
                  newList[index]["image"] = json.data[0];
                  return newList;
                });
              };

              if (selectedFile) {
                reader.readAsDataURL(selectedFile);
              }
              //toast.success("Media uploded successfully");
            } else {
              // toast.error("Couldn't upload media(s), Try again later");
              console.log(response.status);
            }
          }
        } catch (e) {
          console.log(e);
          // toast.error("Couldn't upload media(s), Try again later");
        }
      } else {
        try {
          //  roadsandLandmarks[index].image
          const queryParams = {
            filePath: roadsandLandmarksRR[index].image.path,
          };

          const response = await fetch(
            `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response.status === 200) {
            setRoadsAndLandmarkRR((prevList) => {
              const newList = [...prevList];
              newList[index]["image"] = "";
              return newList;
            });
          } else {
            // toast.error("Couldn't upload media(s), Try again later");
          }
        } catch (e) {
          console.log(e);
          // toast.error("Couldn't upload media(s), Try again later");
        }
      }
    } else {
      setRoadsAndLandmarkRR((prevList) => {
        const newList = [...prevList];
        newList[index][key] = value;
        return newList;
      });
    }

    // console.log(roadsandLandmarks);
  };

  const [expandedItems, setExpandedItems] = useState({});
  const [floorPlan, setFlorPlan] = useState([]);

  const toggleExpand = (index) => {
    setExpandedItems({
      ...expandedItems,
      [index]: !expandedItems[index],
    });
  };

  const deleteByIndex = (index) => {
    setFlorPlan((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
    // console.log(bhk);
  };

  const handleFloor = () => {
    let x = Math.floor(Math.random() * 100 + 1);
    const ok = {
      id: x,
      image: "",
      size: "",
      price: 0,
      type: "",
    };
    setFlorPlan(floorPlan.concat(ok));
    // console.log(floorPlan.length);
  };

  const handleFloorPlanChange = async (index, key, value) => {
    if (key === "imageadd" || key === "imageremove") {
      if (key === "imageadd") {
        try {
          if (value.target.files.length > 0) {
            const formData = new FormData();
            formData.append("profilex", value.target.files[0]);

            const queryParams = {
              folderName:
                rentalData.property_name !== ""
                  ? generateSlug(rentalData.property_name)
                  : "default",
            };

            const response = await fetch(
              `${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`,
              {
                method: "POST",
                headers: {
                  // "Content-Type": "application/json",
                  // Accept: "application/json",
                },
                body: formData,
              }
            );
            if (response.status === 200) {
              const json = await response.json();

              const selectedFile = value.target.files[0];
              const reader = new FileReader();

              reader.onload = (value) => {
                setFlorPlan((prevList) => {
                  const newList = [...prevList];
                  newList[index]["image"] = json.data[0];
                  return newList;
                });
              };

              if (selectedFile) {
                reader.readAsDataURL(selectedFile);
              }
              //toast.success("Media uploded successfully");
            } else {
              // toast.error("Couldn't upload media(s), Try again later");
              console.log(response.status);
            }
          }
        } catch (e) {
          console.log(e);
          // toast.error("Couldn't upload media(s), Try again later");
        }
      } else {
        try {
          //  roadsandLandmarks[index].image
          const queryParams = { filePath: floorPlan[index].image.path };

          const response = await fetch(
            `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response.status === 200) {
            setFlorPlan((prevList) => {
              const newList = [...prevList];
              newList[index]["image"] = "";
              return newList;
            });
          } else {
            // toast.error("Couldn't upload media(s), Try again later");
          }
        } catch (e) {
          console.log(e);
          // toast.error("Couldn't upload media(s), Try again later");
        }
      }
    } else {
      setFlorPlan((prevList) => {
        const newList = [...prevList];
        newList[index][key] = value;
        return newList;
      });
    }

    // console.log(floorPlan);
  };

  const [expandedItemsFAQRR, setExpandedItemsFAQRR] = useState({});
  const [faqsRR, setFAQRR] = useState([]);

  const toggleExpandFAQRR = (index) => {
    setExpandedItemsFAQRR({
      ...expandedItemsFAQRR,
      [index]: !expandedItemsFAQRR[index],
    });
  };

  const deleteByIndexFAQRR = (index) => {
    setFAQRR((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleFAQRR = () => {
    let x = Math.floor(Math.random() * 100 + 1);
    const ok = {
      id: x,
      question: "",
      answer: "",
    };
    setFAQRR(faqsRR.concat(ok));
  };

  const handleFAQChangeRR = (index, key, value) => {
    setFAQRR((prevList) => {
      const newList = [...prevList];
      newList[index][key] = value;
      return newList;
    });
  };

  const [aminityRR, setAminityRR] = useState([]);

  // const handleSelectAminityRR = (newValue, actionMeta) => {
  //   // Check the action performed
  //   if (actionMeta.action === "select-option") {
  //     setAminityRR((prevItems) => [
  //       ...prevItems,
  //       {
  //         id: actionMeta.option.id,
  //         name: actionMeta.option.name,
  //         category: actionMeta.option.category,
  //         icon: actionMeta.option.icon,
  //       },
  //     ]);
  //   } else if (actionMeta.action === "remove-value") {
  //     setAminityRR(
  //       aminityRR.filter((item) => item.id !== actionMeta.removedValue.id)
  //     );
  //     //console.log("Option removed - ID:", actionMeta.removedValue.id);
  //   }
  //   // You can access all selected information from the newValue array
  // };

  const handleSelectAminityRR = (newValue, actionMeta) => {
    // Check the action performed
    if (actionMeta.action === "select-option") {
      setAminityRR((prevItems) => [
        ...prevItems,
        actionMeta.option.id,
        // {
        //   id: actionMeta.option.id,
        //   name: actionMeta.option.name,
        //   category: actionMeta.option.category,
        //   icon: actionMeta.option.icon,
        // },
      ]);
    } else if (actionMeta.action === "remove-value") {
      // console.log(actionMeta.removedValue);
      //    console.log(aminity);
      setAminityRR(
        aminityRR.filter((item) => item !== actionMeta.removedValue.id)
      );
      //console.log("Option removed - ID:", actionMeta.removedValue.id);
    }
    // You can access all selected information from the newValue array
  };

  const handleRentalData = (key, value) => {
    if (key === "property_name") {
      setRentalData((prevItem) => ({
        ...prevItem,
        property_name: value,
        slug: generateSlug2(value),
      }));
    } else {
      setRentalData((prevItem) => ({
        ...prevItem,
        [key]: value,
      }));
    }
  };

  const handleDataReceivedRR = (data) => {
    if (data.id === 1) {
      if (data.data.length > 0) {
        console.log(data.data[0]);
        handleRentalData("cover_image", JSON.stringify(data.data[0]));
      }
    } else if (data.id === 2) {
      if (data.data.length > 0) {
        // const parsedData = data.data.map((item) => ({
        //   url: item.fieldname + "/" + item.filename,
        // }));
        setPropertyImages(data.data);

        //  handleRentalData("property_images", JSON.stringify(data.data[0]));
      }
    } else if (data.id === 3) {
      if (data.data.length > 0) {
        handleRentalData("master_plan", JSON.stringify(data.data[0]));
      }
    } else if (data.id === 4) {
      if (data.data.length > 0) {
        handleRentalData("floor_plan", JSON.stringify(data.data[0]));
      }
    } else if (data.id === 5) {
      if (data.data.length > 0) {
        handleRentalData("video", JSON.stringify(data.data[0]));
      }
    } else if (data.id === 6) {
      if (data.data.length > 0) {
        handleRentalData("brochure", JSON.stringify(data.data[0]));
      }
    } else {
      console.log("Data in ParentComponentxx:", data);
    }
  };

  const setAllJSONRR = () => {
    setRentalData((prevItem) => ({
      ...prevItem,
      aminities: JSON.stringify({ aminities: aminityRR }),
      floor_plan: JSON.stringify({ plans: floorPlan }),

      faqs: JSON.stringify({ faqs: faqsRR }),
      landmarks: JSON.stringify({ landmarks: roadsandLandmarksRR }),
      property_images: JSON.stringify({ images: propertyImages }),
       
    }));
  };
  useEffect(() => {
    getAvailableFilters();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //getAvailableFilters();
    setAllJSONRR();
    // eslint-disable-next-line
  }, [aminityRR, floorPlan, faqsRR, roadsandLandmarksRR, propertyImages]);

  function generateSlug(text) {
    return text
      .toLowerCase() // Convert text to lowercase
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .replace(/[^a-z0-9_]/g, ""); // Remove non-alphanumeric characters except underscores
  }
  function generateSlug2(text) {
    return text
      .toLowerCase() // Convert text to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
  }

  return (
    <div>
      <div>
        <div className="flex flex-row justify-between px-4 h-12 mt-6">
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold mb-2">
              Enter Resell Information
            </h2>
            <div className="border-b-2 border-red-500 w-16 mb-6"></div>
          </div>

          <button
            onClick={handleRentalClick}
            className="px-5 py-2.5 bg-black text-white rounded-lg shadow-md focus:shadow-none duration-100 ring-offset-2 ring-indigo-600 focus:ring-2"
          >
            Add Resell Data
          </button>
        </div>
      </div>
      <ToastContainer position="top-right" />

      <SpinnerDotted
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 1,
          transform: "translate(-50%, -50%)",
        }}
        size={70}
        thickness={140}
        speed={140}
        color="#36ad47"
        enabled={spinnerStatus}
      />
      <div className=" p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* <div>
      <label className="block text-sm font-bold mb-2">Are you a?</label>
      <div className="flex space-x-2">
        <button className="border border-zinc-400 py-2 px-4 rounded">
          Owner
        </button>
        <button className="border border-zinc-400 py-2 px-4 rounded">
          Broker
        </button>
      </div>
    </div> */}

          {/* <div>
      <label className="block text-sm font-bold mb-2">
        Enter your Email
      </label>
      <input
        type="email"
        placeholder="Email *"
        className="w-full border border-zinc-300 p-2 rounded"
      />
    </div> */}

          <div>
            <label className="block text-sm font-bold mb-2">
              Property Name
            </label>
            <input
              type="text"
              onChange={(e) =>
                handleRentalData("property_name", e.target.value)
              }
              placeholder="Enter a property name *"
              className="w-full border border-zinc-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">
              Property Price in cr.
            </label>
            <input
              type="number"
              onChange={(e) =>
                handleRentalData("property_price", e.target.value)
              }
              placeholder="Enter a property price *"
              className="w-full border border-zinc-300 p-2 rounded"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Monthly Rent or Price/sq.ft
            </label>
            <input
              type="number"
              className="w-full p-2 border border-zinc-300 rounded-md"
              onChange={(e) => handleRentalData("monthly_rent", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">
              Property Type
            </label>
            <div className="flex space-x-2">
              <button
                onClick={() => handleRentalData("property_type", "Apartment")}
                className={`${
                  rentalData.property_type === "Apartment"
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Apartment
              </button>
              <button
                onClick={() => handleRentalData("property_type", "Villa")}
                className={`${
                  rentalData.property_type === "Villa"
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Villa
              </button>
              <button
                onClick={() => handleRentalData("property_type", "Penthouse")}
                className={`${
                  rentalData.property_type === "Penthouse"
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Penthouse
              </button>
            </div>
          </div>

          {/* <div>
      <label className="block text-sm font-bold mb-2">
        Property Type
      </label>
      <div className="flex space-x-2 mb-2">
        <button className="border border-red-500 text-red-500 py-2 px-4 rounded">
          Apartment
        </button>
        <button className="border border-zinc-400 py-2 px-4 rounded">
          Villa
        </button>
        <button className="border border-zinc-400 py-2 px-4 rounded">
          Plot
        </button>
      </div>
      <button className="border border-zinc-400 py-2 px-4 rounded">
        Independent House
      </button>
    </div> */}

          <div>
            <label className="block text-sm font-bold mb-2">
              Residential/Commercial
            </label>
            <div className="flex space-x-2">
              <button
                onClick={() => handleRentalData("is_residential", true)}
                className={`${
                  rentalData.is_residential === true
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Residential
              </button>
              <button
                onClick={() => handleRentalData("is_residential", false)}
                className={`${
                  rentalData.is_residential === false
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Commercial
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-bold mb-2">Which city?</label>
            <div className="flex space-x-2">
              <button
                onClick={() => handleRentalData("city", "Bengaluru")}
                className={`${
                  rentalData.city === "Bengaluru"
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Bengaluru
              </button>
              <button
                onClick={() => handleRentalData("city", "Bhubaneswar")}
                className={`${
                  rentalData.city === "Bhubaneswar"
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Bhubaneswar
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-bold mb-2">Location</label>
            <input
              type="text"
              onChange={(e) => handleRentalData("location", e.target.value)}
              placeholder="Enter a locality name *"
              className="w-full border border-zinc-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Postal Code</label>
            <input
              type="number"
              onChange={(e) => handleRentalData("postal_code", e.target.value)}
              placeholder="Postal code *"
              className="w-full border border-zinc-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Latitude</label>
            <input
              type="number"
              onChange={(e) => handleRentalData("latitude", e.target.value)}
              placeholder="Enter latitude *"
              className="w-full border border-zinc-300 p-2 rounded"
            />
          </div>

          <div>
            <label className="block text-sm font-bold mb-2">Longitude</label>
            <input
              type="number"
              onChange={(e) => handleRentalData("longitude", e.target.value)}
              placeholder="Enter longitude *"
              className="w-full border border-zinc-300 p-2 rounded"
            />
          </div>

          <div>
            <label className="block text-sm font-bold mb-2">Address</label>
            <textarea
              placeholder="Address *"
              onChange={(e) => handleRentalData("address", e.target.value)}
              className="w-full border border-zinc-300 p-2 rounded"
            ></textarea>
          </div>
        </div>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* <div>
      <label className="block font-semibold mb-2">
        Property Status
      </label>
      <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
        Ready to move in
      </button>
    </div> */}

          <div>
            <label className="block font-semibold mb-2">Bedroom(s)</label>
            <div className="flex space-x-2">
              {["1BHK", "2BHK", "3BHK", "4BHK", "5BHK"].map((item, index) => (
                <div
                  onClick={() => handleRentalData("bedroom", item)}
                  className={`${
                    rentalData.bedroom === item
                      ? "bg-red-500 text-white"
                      : "border-zinc-400"
                  } border  py-2 px-4 rounded`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div>
            <label className="block font-semibold mb-2">Bathroom(s)</label>
            <div className="flex space-x-2">
              {[1, 2, 3, 4, 5].map((item, index) => (
                <div
                  onClick={() => handleRentalData("bath_rooms", item)}
                  className={`${
                    rentalData.bath_rooms === item
                      ? "bg-red-500 text-white"
                      : "border-zinc-400"
                  } border  py-2 px-4 rounded`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div>
            <label className="block font-semibold mb-2">Balconies</label>
            <div className="flex space-x-2">
              {[0, 1, 2, 3, 4, 5].map((item, index) => (
                <div
                  onClick={() => handleRentalData("balconies", item)}
                  className={`${
                    rentalData.balconies === item
                      ? "bg-red-500 text-white"
                      : "border-zinc-400"
                  } border  py-2 px-4 rounded`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div>
            <label className="block font-semibold mb-2">Door Facing</label>
            <div className="flex space-x-2">
              {["East", "West", "North", "South"].map((item, index) => (
                <div
                  onClick={() => handleRentalData("door_facing", item)}
                  className={`${
                    rentalData.door_facing === item
                      ? "bg-red-500 text-white"
                      : "border-zinc-400"
                  } border  py-2 px-4 rounded`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div>
            <label className="block font-semibold mb-2">Property Status</label>
            <div className="flex space-x-2">
              {["Ready to Move", "Under Construction"].map((item, index) => (
                <div
                  onClick={() => handleRentalData("property_status", item)}
                  className={`${
                    rentalData.property_status === item
                      ? "bg-red-500 text-white"
                      : "border-zinc-400"
                  } border  py-2 px-4 rounded`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div>
            <label className="block font-semibold mb-2">
              Furnishing Status
            </label>
            <div className="flex space-x-2">
              {["Semi furnished", "Furnished", "Unfurnished"].map(
                (item, index) => (
                  <div
                    onClick={() => handleRentalData("furnishing_status", item)}
                    className={`${
                      rentalData.furnishing_status === item
                        ? "bg-red-500 text-white"
                        : "border-zinc-400"
                    } border  py-2 px-4 rounded`}
                  >
                    {item}
                  </div>
                )
              )}
            </div>
          </div>
          <div>
            <label className="block font-semibold mb-2">Parking</label>
            <div className="flex space-x-2">
              {["Two Wheeler", "Four Wheeler", "Both"].map((item, index) => (
                <div
                  onClick={() => handleRentalData("parking", item)}
                  className={`${
                    rentalData.parking === item
                      ? "bg-red-500 text-white"
                      : "border-zinc-400"
                  } border  py-2 px-4 rounded`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div>
            <label className="block font-semibold mb-2">Covered Parking</label>
            <div className="flex space-x-2">
              {[0, 1, 2, 3, 4, 5].map((item, index) => (
                <div
                  onClick={() => handleRentalData("covered_parking", item)}
                  className={`${
                    rentalData.covered_parking === item
                      ? "bg-red-500 text-white"
                      : "border-zinc-400"
                  } border  py-2 px-4 rounded`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div>
            <label className="block font-semibold mb-2">Open Parking</label>
            <div className="flex space-x-2">
              {[0, 1, 2, 3, 4, 5].map((item, index) => (
                <div
                  onClick={() => handleRentalData("open_parking", item)}
                  className={`${
                    rentalData.open_parking === item
                      ? "bg-red-500 text-white"
                      : "border-zinc-400"
                  } border  py-2 px-4 rounded`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">
              Gated Security
            </label>
            <div className="flex space-x-2">
              <div
                onClick={() => handleRentalData("is_gated_security", true)}
                className={`${
                  rentalData.is_gated_security === true
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Yes
              </div>
              <div
                onClick={() => handleRentalData("is_gated_security", false)}
                className={`${
                  rentalData.is_gated_security === false
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                No
              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">
              Non-Veg Allowed
            </label>
            <div className="flex space-x-2">
              <div
                onClick={() => handleRentalData("is_nonveg_allowed", true)}
                className={`${
                  rentalData.is_nonveg_allowed === true
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                yes
              </div>
              <div
                onClick={() => handleRentalData("is_nonveg_allowed", false)}
                className={`${
                  rentalData.is_nonveg_allowed === false
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                No
              </div>
            </div>
          </div>
          {/* <div>
      <label className="block font-semibold mb-2">Amenities</label>
      <div className="flex space-x-2">
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          Swimming Pool
        </button>
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          Gym
        </button>
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          Jogging Track
        </button>
      </div>
    </div>
    <div>
      <label className="block font-semibold mb-2">Facilities</label>
      <div className="flex flex-wrap gap-2">
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          CCTV
        </button>
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          Parking
        </button>
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          Sewage Water Treatment
        </button>
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          Rainwater Harvesting
        </button>
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          Power Supply
        </button>
        <button className="border border-pink-600 text-pink-600 px-4 py-2 rounded">
          Power Backup
        </button>
      </div>
    </div> */}
          <div>
            <label className="block font-semibold mb-2">
              Property Age (in years)
            </label>
            <div className="flex space-x-2">
              <input
                type="number"
                onChange={(e) =>
                  handleRentalData("property_age", e.target.value)
                }
                className="border border-zinc-400 px-4 py-2 rounded w-full"
                placeholder="Years"
              />
              {/* <select className="border border-zinc-400 px-4 py-2 rounded w-full">
          <option>0</option>
        </select> */}
            </div>
          </div>

          <div>
            <label className="block font-semibold mb-2">
              Property Dimension (in SQ. FT)
            </label>
            <div className="flex space-x-2">
              <input
                type="number"
                onChange={(e) =>
                  handleRentalData("property_dimension", e.target.value)
                }
                className="border border-zinc-400 px-4 py-2 rounded w-full"
                placeholder="Property dimension"
              />
              {/* <select className="border border-zinc-400 px-4 py-2 rounded w-full">
          <option>sq.feet</option>
        </select> */}
            </div>
          </div>
          <div>
            <label className="block font-semibold mb-2">
              Property Amenities
            </label>
            <div className="flex space-x-2">
              <CreatableSelect
                isMulti
                placeholder="Select Property Amenities....."
                onChange={handleSelectAminityRR}
                options={
                  availableFilters !== null
                    ? availableFilters.property_amenity.map((option) => ({
                        label: option.name,
                        value: option.name,
                        id: option.id,
                        name: option.name,
                        category: option.category,
                        icon: option.icon,
                      }))
                    : []
                }
              />
            </div>
          </div>

          <div style={{ flex: 1, marginRight: "10px" }}>
            <label className="block font-semibold mb-2">Property Builder</label>
            <CreatableSelect
              // isMulti
              styles={{ flex: 1 }}
              // value={{
              //   label: data.property_builder,
              //   value: data.property_builder,
              // }}
              placeholder="Select Property Builder....."
              onChange={(e) => handleRentalData("property_builder", e.value)}
              options={
                availableFilters !== null
                  ? availableFilters.property_builder.map((option) => ({
                      label: option.name,
                      value: option.name,
                    }))
                  : []
              }
            />
          </div>
        </div>
      </div>

      <div className="p-6">
        <form className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Total Floor *
            </label>
            <input
              type="number"
              className="w-full p-2 border border-zinc-300 rounded-md"
              onChange={(e) => handleRentalData("total_floor", e.target.value)}
            />
          </div>
          <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Which Floor *
            </label>
            <input
              type="number"
              className="w-full p-2 border border-zinc-300 rounded-md"
              onChange={(e) => handleRentalData("your_floor", e.target.value)}
            />
          </div>
          {/* <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Tenant Type
            </label>
            <div className="flex space-x-2">
              <div
                onClick={() => handleRentalData("tenent_type", "Bachelor")}
                className={`${
                  rentalData.tenent_type === "Bachelor"
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Bachelor
              </div>
              <div
                onClick={() => handleRentalData("tenent_type", "Family")}
                className={`${
                  rentalData.tenent_type === "Family"
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Family
              </div>
              <div
                onClick={() =>
                  handleRentalData("tenent_type", "Bachelor/Family")
                }
                className={`${
                  rentalData.tenent_type === "Bachelor/Family"
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Both
              </div>
            </div>
          </div> */}

       

          {/* <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Maintenance Charge *
            </label>
            <input
              type="number"
              className="w-full p-2 border border-zinc-300 rounded-md"
              onChange={(e) =>
                handleRentalData("maintenance_charge", e.target.value)
              }
            />
          </div>
          <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Security Deposit *
            </label>
            <input
              type="number"
              className="w-full p-2 border border-zinc-300 rounded-md"
              onChange={(e) =>
                handleRentalData("security_deposit", e.target.value)
              }
            />
          </div> */}

          <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Available From *
            </label>
            <input
              type="date"
              className="w-full p-2 border border-zinc-300 rounded-md"
              onChange={(e) =>
                handleRentalData("available_from", e.target.value)
              }
            />
          </div>

          <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Do You Charge Brokerage
            </label>
            <div className="flex space-x-2">
              <div
                onClick={() => handleRentalData("is_broker", true)}
                className={`${
                  rentalData.is_broker === true
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                Yes
              </div>
              <div
                onClick={() => handleRentalData("is_broker", false)}
                className={`${
                  rentalData.is_broker === false
                    ? "bg-red-500 text-white"
                    : "border-zinc-400"
                } border  py-2 px-4 rounded`}
              >
                No
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <label className="block text-zinc-700 dark:text-zinc-300 mt-2">
              Brokerage in % *
            </label>
            <input
              type="number"
              className="w-full p-2 border border-zinc-300 rounded-md"
              onChange={(e) => handleRentalData("brokerage", e.target.value)}
            />
          </div>
          <div className="col-span-1 md:col-span-3">
            <label className="block text-zinc-700 dark:text-zinc-300">
              Write About your Property
            </label>
            <textarea
              onChange={(e) => handleRentalData("about", e.target.value)}
              className="w-full p-2 border border-zinc-300 rounded-md"
            ></textarea>
          </div>
        </form>
      </div>

      <div style={{ display: "flex" }}>
      <ReorderableImageList
          idx={2}
          name="Property Images"
          folder={
            rentalData.property_name !== ""
              ? generateSlug(rentalData.property_name)
              : "default"
          }
          isMultiple={true}
          accept="image/*"
          onDataReceived={handleDataReceivedRR}
        />
        <ReorderableImageList
          idx={1}
          name="Property Cover Image"
          isMultiple={false}
          folder={
            rentalData.property_name !== ""
              ? generateSlug(rentalData.property_name)
              : "default"
          }
          accept="image/*"
          onDataReceived={handleDataReceivedRR}
        />
   
      </div>

      <div style={{ display: "flex" }}>
        <ReorderableImageList
          idx={3}
          name="Property Master Plan"
          isMultiple={false}
          folder={
            rentalData.property_name !== ""
              ? generateSlug(rentalData.property_name)
              : "default"
          }
          accept="image/*"
          onDataReceived={handleDataReceivedRR}
        />
        <ReorderableImageList
          idx={6}
          name="Property Brochure"
          isMultiple={false}
          folder={
            rentalData.property_name !== ""
              ? generateSlug(rentalData.property_name)
              : "default"
          }
          accept=".pdf"
          onDataReceived={handleDataReceivedRR}
        />
        {/* <ReorderableImageList
          idx={5}
          name="Property Video"
          isMultiple={false}
          folder={
            rentalData.property_name !== ""
              ? generateSlug(rentalData.property_name)
              : "default"
          }
          accept="video/mp4"
          onDataReceived={handleDataReceivedRR}
        /> */}
        {/* <ReorderableImageList
          idx={4}
          name="Property Floor Plan"
          isMultiple={false}
          folder={
            rentalData.property_name !== ""
              ? generateSlug(rentalData.property_name)
              : "default"
          }
          accept="image/*"
          onDataReceived={handleDataReceivedRR}
        /> */}
      </div>

      <div style={{ display: "flex", maxWidth: "97%" }}>
        <div
          style={{
            flex: 1,
            width: "47%",
            backgroundColor: "white",
            marginLeft: "20px",
          }}
        >
          <label style={{ marginLeft: "20px", marginTop: "20px", flex: 1 }}>
            Property Floor-Plan
          </label>
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: 15,
            }}
          >
            <label style={{ flex: 1, marginTop: 10 }}>Add Floor Plan</label>
            <IconButton onClick={handleFloor}>
              <IoAddCircle size={30} />
            </IconButton>
          </div>

          <ol>
            {floorPlan.map((floor, index) => (
              <li key={index}>
                <IconButton onClick={() => deleteByIndex(index)}>
                  <AiTwotoneDelete />
                </IconButton>

                <IconButton onClick={() => toggleExpand(index)}>
                  {expandedItems[index] ? (
                    <IoMdArrowDropdown />
                  ) : (
                    <MdOutlineExpandMore />
                  )}
                </IconButton>
                {expandedItems[index] && (
                  <div
                    key={floor.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      backgroundColor: "white",
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: 5,
                      marginRight: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,
                        height: 150,
                        width: 150,
                        // justifyContent: "center",
                      }}
                    >
                      {!floorPlan[index].image && (
                        <div>
                          <label style={{ marginBottom: "10px", flex: 1 }}>
                            Floor Image
                          </label>
                          <div>
                            <label
                              htmlFor="fileInput"
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: 5,
                              }}
                            >
                              Choose File
                            </label>
                            <input
                              id="fileInput"
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              onChange={(e) =>
                                handleFloorPlanChange(index, "imageadd", e)
                              }
                              style={{
                                display: "none",
                                borderRadius: 10,
                                border: "1px solid #ccc",
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {floorPlan[index].image && (
                        <div style={{ alignItems: "center" }}>
                          <div
                            style={{
                              border: "1px solid lightgrey",
                              borderRadius: "5px",
                              padding: "5px",
                              position: "relative",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                handleFloorPlanChange(index, "imageremove", e)
                              }
                            >
                              <MdCancel size={25} />
                            </span>
                            <img
                              src={
                                IMAGE_PATH +
                                floorPlan[index].image.fieldname +
                                "/" +
                                floorPlan[index].image.filename
                              }
                              alt="Selected"
                              style={{
                                width: "120px",
                                height: "130px",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 150,
                        width: 170,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Floor Size
                        </label>
                        <TextField
                          id="propertyName"
                          //  value={floor.id}
                          placeholder="1200"
                          //value={floor.size}

                          helperText="Floor size in sqft"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleFloorPlanChange(index, "size", e.target.value)
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{
                            style: { fontSize: "15px" },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 150,
                        width: 170,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Floor Price
                        </label>
                        <TextField
                          id="propertyName"
                          placeholder="1.2"
                          helperText="Floor price in cr."
                          // Hint text
                          variant="outlined"
                          //value={floor.price}

                          onChange={(e) =>
                            handleFloorPlanChange(
                              index,
                              "price",
                              e.target.value
                            )
                          }
                          fullWidth
                          // style={{ height: 20 }}
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{
                            style: { fontSize: "15px" },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 150,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: "10px", flex: 1 }}>
                          Floor Type
                        </label>
                        <CreatableSelect
                          // isMulti
                          styles={{ flex: 1 }}
                          placeholder="Select Type"
                          //value={{
                          //   label: floor.type,
                          //   value: floor.type,
                          // }}
                          onChange={(selectedOption) => {
                            handleFloorPlanChange(
                              index,
                              "type",
                              selectedOption.value
                            );
                          }}
                          options={
                            availableFilters !== null
                              ? availableFilters.property_bhk.map((option) => ({
                                  label: option.name,
                                  value: option.name,
                                }))
                              : []
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ol>
        </div>
        <div style={{ width: "47%", marginRight: 20, marginLeft: 20 }}>
          <label style={{ marginBottom: "10px", flex: 1 }}>
            Property video
          </label>
          <TextField
            id="propertyVideo"
            placeholder="Video URL" // Hint text
            variant="outlined"
            // value={data.property_video}

            onChange={(e) => handleRentalData("video", e.target.value)}
            fullWidth
            style={{ backgroundColor: "white" }}
            inputProps={{ style: { height: 9, width: "22vw" } }}
            InputLabelProps={{ style: { fontSize: "15px" } }}
          />
        </div>
      </div>

      <div
        style={{
          // backgroundColor: "white",
          // height:35,
          width: "97%",
          display: "flex",
          alignItems: "flex-start",
          margin: 20,
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1, padding: 20, backgroundColor: "white" }}>
          <label style={{ marginBottom: "10px", flex: 1 }}>
            Nearby Landmarks
          </label>
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: 15,
            }}
          >
            <label style={{ flex: 1, marginTop: 10 }}>Add Landmarks</label>
            <IconButton onClick={handleRoadRR}>
              <IoAddCircle size={30} />
            </IconButton>
          </div>

          <ol>
            {roadsandLandmarksRR.map((road, index) => (
              <li key={index}>
                <div className="flex flex-row">
                   <IconButton onClick={() => deleteByIndexRoadRR(index)}>
                    <AiTwotoneDelete />
                  </IconButton>

                  <IconButton onClick={() => toggleExpandRoadRR(index)}>
                    {expandedItemsRoadRR[index] ? (
                      <IoMdArrowDropdown />
                    ) : (
                      <MdOutlineExpandMore />
                    )}
                  </IconButton>
                </div>

                {expandedItemsRoadRR[index] && (
                  <div
                    key={road.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",

                      backgroundColor: "white",
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: 5,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          backgroundColor: "white",
                          padding: 10,

                          height: 100,
                          width: "12vw",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ flex: 1, marginRight: "10px" }}>
                          <label style={{ marginBottom: 10, flex: 1 }}>
                            Landmark Name
                          </label>
                          <TextField
                            id="propertyName"
                            ////value={road.id}
                            placeholder="Hotel Moon Star"
                            helperText="Road/Landmark name"
                            onChange={(e) =>
                              handleRoadChangeRR(index, "name", e.target.value)
                            }
                            // Hint text
                            variant="outlined"
                            fullWidth
                            style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                            inputProps={{ style: { height: 8 } }}
                            InputLabelProps={{
                              style: { fontSize: "15px" },
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          backgroundColor: "white",
                          padding: 10,

                          height: 100,
                          width: "12vw",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ flex: 1, marginRight: "10px" }}>
                          <label style={{ marginBottom: 10, flex: 1 }}>
                            Locality
                          </label>
                          <TextField
                            id="propertyName"
                            placeholder="Kanakapura Road"
                            helperText="Locality Name"
                            // Hint text
                            onChange={(e) =>
                              handleRoadChangeRR(
                                index,
                                "locality",
                                e.target.value
                              )
                            }
                            variant="outlined"
                            fullWidth
                            // style={{ height: 20 }}
                            inputProps={{ style: { height: 8 } }}
                            InputLabelProps={{
                              style: { fontSize: "15px" },
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          margin: "0px 10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label style={{ marginBottom: 10, flex: 1 }}>
                            Category
                          </label>
                          <CreatableSelect
                            // isMulti
                            styles={{ flex: 1, width: 400 }}
                            placeholder="Select Category"
                            onInputChange={(value) => value.toUpperCase()}
                            onChange={(selectedOption) => {
                              handleRoadChangeRR(
                                index,
                                "category",
                                selectedOption.value
                              );
                            }}
                            options={landmarks_categories}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "white",
                              padding: 10,

                              height: 100,
                              width: "12vw",
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ flex: 1, marginRight: "10px" }}>
                              <label style={{ marginBottom: 10, flex: 1 }}>
                                Latitude
                              </label>
                              <TextField
                                id="propertyName"
                                ////value={road.id}
                                placeholder="12.9568611"
                                helperText="Ex-12.9568611"
                                // Hint text
                                variant="outlined"
                                onChange={(e) =>
                                  handleRoadChangeRR(
                                    index,
                                    "lat",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                                inputProps={{ style: { height: 8 } }}
                                InputLabelProps={{
                                  style: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "white",
                              padding: 10,

                              height: 100,
                              width: "12vw",
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ flex: 1, marginRight: "10px" }}>
                              <label style={{ marginBottom: 10, flex: 1 }}>
                                Longitude
                              </label>
                              <TextField
                                id="propertyName"
                                placeholder="77.5330066"
                                helperText="Ex-77.5330066"
                                // Hint text
                                variant="outlined"
                                onChange={(e) =>
                                  handleRoadChangeRR(
                                    index,
                                    "lon",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                // style={{ height: 20 }}
                                inputProps={{ style: { height: 8 } }}
                                InputLabelProps={{
                                  style: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "white",
                              padding: 10,

                              height: 100,
                              width: "12vw",
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ flex: 1, marginRight: "10px" }}>
                              <label style={{ marginBottom: 10, flex: 1 }}>
                                Distance from project
                              </label>
                              <TextField
                                id="propertyName"
                                // //value={road.id}
                                placeholder="6"
                                helperText="In KM"
                                // Hint text
                                variant="outlined"
                                onChange={(e) =>
                                  handleRoadChangeRR(
                                    index,
                                    "distance",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                                inputProps={{ style: { height: 8 } }}
                                InputLabelProps={{
                                  style: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "center",
                          backgroundColor: "white",

                          height: 200,
                          width: 182,

                          // justifyContent: "center",
                        }}
                      >
                        {!roadsandLandmarksRR[index].image && (
                          <div style={{ marginLeft: 5 }}>
                            <label style={{ marginBottom: "10px", flex: 1 }}>
                              Image
                            </label>
                            <div>
                              <label
                                htmlFor="fileInput"
                                style={{
                                  cursor: "pointer",
                                  display: "inline-block",
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  borderRadius: 5,
                                  width: 175,
                                  // backgroundColor:"black",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Select Image File
                              </label>
                              <input
                                id="fileInput"
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={(e) =>
                                  handleRoadChangeRR(index, "imageadd", e)
                                }
                                style={{
                                  display: "none",
                                  borderRadius: 10,
                                  border: "1px solid #ccc",
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {roadsandLandmarksRR[index].image && (
                          <div style={{ alignItems: "center" }}>
                            <div
                              style={{
                                border: "1px solid lightgrey",
                                borderRadius: "5px",
                                padding: "5px",
                                position: "relative",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  handleRoadChangeRR(index, "imageremove", e)
                                }
                              >
                                <MdCancel size={25} />
                              </span>
                              <img
                                src={
                                  IMAGE_PATH +
                                  roadsandLandmarksRR[index].image.fieldname +
                                  "/" +
                                  roadsandLandmarksRR[index].image.filename
                                }
                                alt="Error"
                                style={{
                                  width: "170px",
                                  height: "190px",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ol>
        </div>

        <div style={{ width: 20, backgroundColor: "black" }}></div>

        <div style={{ flex: 1, padding: 20, backgroundColor: "white" }}>
          <label style={{ marginBottom: "10px", flex: 1 }}>
            Frequently Asked Questions
          </label>
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: 15,
            }}
          >
            <label style={{ flex: 1, marginTop: 10 }}>Add FAQ</label>
            <IconButton onClick={handleFAQRR}>
              <IoAddCircle size={30} />
            </IconButton>
          </div>

          <ol>
            {faqsRR.map((faq, index) => (
              <li key={index}>
                <div className="flex flex-row">
                   <IconButton onClick={() => deleteByIndexFAQRR(index)}>
                    <AiTwotoneDelete />
                  </IconButton>

                  <IconButton onClick={() => toggleExpandFAQRR(index)}>
                    {expandedItemsFAQRR[index] ? (
                      <IoMdArrowDropdown />
                    ) : (
                      <MdOutlineExpandMore />
                    )}
                  </IconButton>
                </div>

                {expandedItemsFAQRR[index] && (
                  <div
                    key={faq.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      backgroundColor: "white",
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: 5,
                    }}
                  >
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <label style={{ marginBottom: 10, flex: 1 }}>
                        Enter Question
                      </label>
                      <TextField
                        id="propertyName"
                        // //value={faq.id}
                        placeholder="Your question"
                        helperText="Try to add a genuine question"
                        // Hint text
                        variant="outlined"
                        onChange={(e) =>
                          handleFAQChangeRR(index, "question", e.target.value)
                        }
                        fullWidth
                        style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                        inputProps={{ style: { height: 8 } }}
                        InputLabelProps={{ style: { fontSize: "15px" } }}
                      />
                    </div>
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <label style={{ marginBottom: 10, flex: 1 }}>
                        Enter Answer
                      </label>
                      <TextField
                        id="propertyName"
                        placeholder="Your answer"
                        helperText="Try to add a genuine answer"
                        // Hint text
                        variant="outlined"
                        onChange={(e) =>
                          handleFAQChangeRR(index, "answer", e.target.value)
                        }
                        fullWidth
                        // style={{ height: 20 }}
                        inputProps={{ style: { height: 8 } }}
                        InputLabelProps={{ style: { fontSize: "15px" } }}
                      />
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default AddRentNresale;
