import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const FilterModal = () => {
  const [promise, setPromise] = useState(null);
  const [lead_type, setLeadType] = useState([]);
  const [status, setStatus] = useState([]);
 

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve({
      lead_type: lead_type,
      status: status,
      
    });
    handleClose();
  };

  const handleType = (element) => {
    const arr = toggleElement(lead_type, element);
    setLeadType(arr);
  };
  const handleStatus = (element) => {
    const arr = toggleElement(status, element);
    setStatus(arr);
  };
 
  function toggleElement(array, element) {
    const index = array.indexOf(element);

    if (index === -1) {
      // Element is not present, so insert it
      array.push(element);
    } else {
      // Element is present, so remove it
      array.splice(index, 1);
    }

    return [...array]; // Make sure to return a new array to trigger re-render
  }

  const ConfirmationDialog = () => (
    <Dialog open={promise !== null} onClose={handleClose} fullWidth>
      <DialogTitle style={{ fontWeight: "700" }}>Apply Filters</DialogTitle>
      <DialogContent>
        <div>
          {/* <div
            style={{
              color: "black", 
            //  padding: "2px",
              fontSize:10
            }}
          >
            Note: Selected filters will be applied during search. 
          </div> */}
          <h6>Lead Type</h6>
          <div style={{ display: "flex", fontSize: 15 }}>
            <div
              style={{
                backgroundColor: lead_type.includes("1") ? "black" : "white",
                color: lead_type.includes("1") ? "white" : "black",
                borderRadius: 10,
                border: lead_type.includes("1")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={() => handleType("1")}
            >
              New Home
            </div>
            <div
              style={{
                backgroundColor: lead_type.includes("2") ? "black" : "white",
                color: lead_type.includes("2") ? "white" : "black",
                borderRadius: 10,
                border: lead_type.includes("2")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                cursor: "pointer",
                marginRight: 10,

              }}
              onClick={() => handleType("2")}
            >
              Home Loan
            </div>
            <div
              style={{
                 backgroundColor: lead_type.includes("3") ? "black" : "white",
                color: lead_type.includes("3") ? "white" : "black",
                borderRadius: 10,
                border: lead_type.includes("3")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                cursor: "pointer",
                marginRight: 10,

              }}
              onClick={() => handleType("3")}
            >
              Resale/Rental
            </div>
            <div
              style={{
                backgroundColor: lead_type.includes("4") ? "black" : "white",
                color: lead_type.includes("4") ? "white" : "black",
                borderRadius: 10,
                border: lead_type.includes("4")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                cursor: "pointer",
                marginRight: 10,

              }}
              onClick={() => handleType("4")}
            >
              Interior
            </div>
            <div
              style={{
                backgroundColor: lead_type.includes("5") ? "black" : "white",
                color: lead_type.includes("5") ? "white" : "black",
                borderRadius: 10,
                border: lead_type.includes("5")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                cursor: "pointer",
              }}
              onClick={() => handleType("5")}
            >
              Construction
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6>Lead Status</h6>
          <div style={{ display: "flex", fontSize: 15 }}>
            <div
              style={{
                backgroundColor: status.includes("1") ? "black" : "white",
                color: status.includes("1") ? "white" : "black",
                borderRadius: 10,
                border: status.includes("1")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={() => handleStatus("1")}
            >
              Active
            </div>
            <div
              style={{
                backgroundColor: status.includes("2") ? "black" : "white",
                color: status.includes("2") ? "white" : "black",
                borderRadius: 10,
                border: status.includes("2")
                  ? "0px solid #d8d0d0"
                  : ".5px solid #d8d0d0",
                padding: "2px 10px",
                cursor: "pointer",
              }}
              onClick={() => handleStatus("2")}
            >
              Closed
            </div>
          </div>
        </div>
     
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Apply</Button>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};

export default FilterModal;
