import { BASE_URL } from "../constant";
import BlogsContext from "../context/BlogsContext/BlogsContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BlogsState = (propsx) => {
  const [blogs, setBlogs] = useState([]);
 
 
  const getBlogs = async (props) => {
    // API Call

    try {
      const response = await fetch(`${BASE_URL}/blog/homznoffiz/get`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);

        const parsedData = json.data.map((item) => ({
          ...item,
          image: JSON.parse(item.image), // Convert property_bhk string to JSON
        }));
        //   console.log(parsedData);
        setBlogs(parsedData);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const createBlogs = async (props) => {
    const { title, description, image,author,content } = props;
    // {
    //     "name":"Farm House",
    //     "description":"",
    //     "icon":""
    // }

    try {
      const response = await fetch(`${BASE_URL}/blog/homznoffiz/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          title: title,
          description: description,
          author: author,
          content: content,
          image: image === null ? null : JSON.stringify(image),
        }),
      });

      if (response.status === 200) {
        getBlogs();
        toast.success("Asset Builder created successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        console.log(response.error);
        toast.error("Couldn't Add asset Builder, Try again later");
      }
    } catch (e) {
      console.log(e);

      toast.error("Couldn't Add asset Builder, Try again later");
    }
  };

  const updateBlog = async (props) => {
    const {id, title, description, image,author,content } = props;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${BASE_URL}/blog/homznoffiz/update/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
                title: title,
                description: description,
                author: author,
                content: content,
                image: image === null ? null : JSON.stringify(image),
              }),
          }
        );

        if (response.status === 200) {
          toast.success("Asset Builder updated successfully");

          getBlogs();
          //console.log(users);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update, Try again later");
        reject(error);
      }
    });
  };

  const deleteBlog = async (props) => {
    const { id } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/blog/homznoffiz/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        // const json = response.json();
        getBlogs();
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete, Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete, Try again later");

      // console.log(e.message);
    }
  };

  return (
    <BlogsContext.Provider
      value={{
       
        blogs,
        getBlogs,
        createBlogs,
        updateBlog,
        deleteBlog

      }}
    >
      {propsx.children}
    </BlogsContext.Provider>
  );
};

export default BlogsState;
