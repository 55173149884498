import React, { useState, useContext, useEffect } from "react";
import DonutChartUser from "../DonutChart";
import { MdOutlineCancel } from "react-icons/md";
import userContext from "../../context/UsersContext/UsersContext";
import { IMAGE_PATH } from "../../constant";

import {
  Switch,
  Card,
  Typography,
  IconButton,
  // TextField,
} from "@mui/material";
import UserImage from "../../../src/assets/images/user.png"; // Path to your image file
import UserImagex from "../../../src/assets/images/user.jpg"; // Path to your image file

const Modal = ({ isOpen, onClose, user }) => {
  const [userStatus, setUserStatus] = useState(true);
  const [kycStatus, setKYCStatus] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const context = useContext(userContext);
  const {
    leadsOverview,
    subscriptionOverview,
    teamMembersAndManager,
    UpdateUserStatus,
    UpdateKYCStatus,
  } = context;

  useEffect(() => {
    setUserStatus(user.status);
    setKYCStatus(user.kyc_verified);
    // eslint-disable-next-line
  }, [user]);

  const handleChange = (checked) => {};

  const handleStatusChange = async (event) => {
    // console.log(checked);
    setUserStatus(event.target.checked);

    const stat = await UpdateUserStatus({
      id: user.id,
      status: user.status ? 0 : 1,
    });
    if (stat) {
      window.location.reload();

      //  console.log(stat);
    }
  };
  const handleKYCChange = async (event) => {
    // setUserStatus(event.target.checked);
    setKYCStatus(event.target.checked);

    const stat = await UpdateKYCStatus({
      id: user.id,
      kyc_verified: user.kyc_verified ? 0 : 1,
    });
    if (stat) {
      window.location.reload();

      //  console.log(stat);
    }
  };
  const handleSelectedUser = (user) => {
    setSelectedUser(user);
    // console.log(user);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex:2,

      }}
    >
      <div
        style={{
          position: "relative",

          margin: "auto",
          width: "50%" /* Adjust the width as needed */,
          maxWidth: "50vw" /* Max width to limit the modal size */,
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 10,
            overflowY: "auto",
          }}
        >
          <h5>User Overview</h5>
          <label>ID: {user.id}</label>
          <div
            style={{
              position: "relative",

              display: "flex",
              borderRadius: 10,
              justifyContent: "space-between",
              marginTop: 15,
              //backgroundColor:"black",
            }}
          >
            <Card
              style={{
                borderRadius: 10,
                height: teamMembersAndManager !== null ? "21vw" : "20vw",
                width: "15vw",
                padding: 10,
              }}
              elevation={5}
            >
              <img
                style={{
                  borderRadius: 10,
                  // margin:10,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={user.image === "" ? UserImagex : IMAGE_PATH+  user.image}
                alt="error"
              />
            </Card>
            <div
              style={{
                marginLeft: 15,
                display: "flex",
                //justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  minWidth: "25vw",
                  justifyContent: "space-between",
                  paddingBottom: 15,
                  // alignItems:"center"
                }}
              >
                <div style={{ height: "100%" }}>
                  {leadsOverview.length > 0 ? (
                    <DonutChartUser
                      data={{
                        title: "Leads Overview",
                        value:
                          leadsOverview && leadsOverview[5]
                            ? leadsOverview[5].total_records
                            : 0,
                        labels: [
                          "New Home",
                          "Home Loan",
                          "Resale",
                          "Interior",
                          "Construction",
                        ],
                        colors: [
                          "#1B59F8",
                          "#3966db",
                          "#3456aa",
                          "#5168a0",
                          "#a4adc4",
                        ],
                        data: [
                          leadsOverview[0].total_records !== 0
                            ? leadsOverview[0].total_records
                            : 1,
                          leadsOverview[1].total_records !== 0
                            ? leadsOverview[1].total_records
                            : 1,
                          leadsOverview[2].total_records !== 0
                            ? leadsOverview[2].total_records
                            : 1,
                          leadsOverview[3].total_records !== 0
                            ? leadsOverview[2].total_records
                            : 1,
                          leadsOverview[4].total_records !== 0
                            ? leadsOverview[3].total_records
                            : 1,
                        ],
                      }}
                    />
                  ) : null}
                </div>
                <div style={{ height: "100%", marginLeft: 20 }}>
                  {subscriptionOverview.length > 0 ? (
                    <DonutChartUser
                      data={{
                        title: "Subscription Overview",
                        value:
                          subscriptionOverview && subscriptionOverview[3]
                            ? subscriptionOverview[3].total_records
                            : 0,
                        labels: ["Silver", "Gold", "Diamond"],
                        colors: ["#1B59F8", "#3966db", "#3456aa"],
                        data: [
                          subscriptionOverview[0].total_records !== 0
                            ? subscriptionOverview[0].total_records
                            : 1,
                          subscriptionOverview[1].total_records !== 0
                            ? subscriptionOverview[1].total_records
                            : 1,
                          subscriptionOverview[2].total_records !== 0
                            ? subscriptionOverview[2].total_records
                            : 1,
                        ],
                      }}
                    />
                  ) : null}
                </div>
              </div>

              {teamMembersAndManager !== null ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 5,
                    marginLeft: 20,
                    //marginTop: 15,
                  }}
                >
                  <ul
                    style={{
                      display: "flex",
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {teamMembersAndManager.members.map((user, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelectedUser(user)}
                        style={{
                          width: 40,
                          height: 40,
                          marginLeft: -20,
                          overflow: "hidden",
                          borderRadius: "50%",
                          backgroundColor: "grey",
                        }}
                      >
                        <img
                          src={user.image === "" ? UserImage : user.image}
                          alt={index}
                          style={{
                            width: "100%",
                            height: "100%",
                            // height: "auto",
                            borderRadius: "50%",
                            objectFit: "cover",

                            transition: "transform 0.3s ease",
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.transform = "scale(1.5)")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.transform = "scale(1)")
                          }
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop:5,
                  marginBottom:10,
                }}
              >
                {/* <label
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Team Details
                </label> */}
                <label>{teamMembersAndManager!=null?teamMembersAndManager.members.length:0} Colleague(s) found</label>
              </div>
              <div
                style={{
                  //  width: "30vw",
                  height: "12vh",
                  padding: 10,
                  borderRadius: 10 /* Adjust border radius as needed */,
                  border: ".5px solid #d8d0d0",
                  // backgroundColor:"black"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      fontFamily: "Montesarat",
                      marginLeft: 10,
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {selectedUser !== null
                        ? selectedUser.name
                        : user.is_manager
                        ? user.name + "(You)"
                        : teamMembersAndManager !== null
                        ? teamMembersAndManager.manager[0].name + "(Manager)"
                        : ""}
                    </label>

                    <label>
                      {selectedUser !== null
                        ? selectedUser.phone
                        : user.is_manager
                        ? user.phone
                        : teamMembersAndManager !== null
                        ? teamMembersAndManager.manager[0].phone
                        : ""}
                    </label>
                    <label>
                      {selectedUser !== null
                        ? selectedUser.email
                        : user.is_manager
                        ? user.email
                        : teamMembersAndManager !== null
                        ? teamMembersAndManager.manager[0].email
                        : ""}
                    </label>
                  </div>
                  <Card
                    style={{
                      borderRadius: 10,
                      height: "4vw",
                      width: "4vw",
                      padding: 10,
                      marginTop: 5,
                    }}
                    elevation={5}
                  >
                    <img
                      style={{
                        borderRadius: 10,
                        // margin:10,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={
                        selectedUser !== null && selectedUser.image !== ""
                          ? IMAGE_PATH + selectedUser.image
                          : user.is_manager && user.is_manager !== ""
                          ? IMAGE_PATH + user.image
                          : teamMembersAndManager !== null &&
                            teamMembersAndManager.manager[0].image !== ""
                          ? IMAGE_PATH + teamMembersAndManager.manager[0].image
                          : UserImagex
                      }
                      alt="user"
                    />
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <h5 style={{ marginTop: 20 }}>User Controls</h5>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              // key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "23vw",
                borderRadius: 10,
                padding: 10,
                border: ".5px solid #d8d0d0",

                //  backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <Typography style={{ fontWeight: 700 }}>User Type</Typography>
                <Typography style={{ fontSize: 13 }}>
                  Can't be changed from admin side. Enabled if Manager
                </Typography>
              </div>

              <Switch
                onChange={handleChange}
                checked={user.is_manager ? user.is_manager : false}
              />
            </div>
            <div
              // key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "23vw",
                borderRadius: 10,
                padding: 10,
                border: ".5px solid #d8d0d0",

                //  backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <Typography style={{ fontWeight: 700 }}>User Status</Typography>
                <Typography style={{ fontSize: 13 }}>
                  Dissable it if you want to block the user from all app
                </Typography>
              </div>

              <Switch onChange={handleStatusChange} checked={userStatus} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 15,
            }}
          >
            <div
              // key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "23vw",
                borderRadius: 10,
                padding: 10,
                border: ".5px solid #d8d0d0",

                //  backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <Typography style={{ fontWeight: 700 }}>
                  User Subscription
                </Typography>
                <Typography style={{ fontSize: 13 }}>
                  User subscription expired on 22/10/2023
                </Typography>
              </div>

              <Switch
                onChange={handleChange}
                checked={user.subscription ? user.subscription : false}
              />
            </div>
            <div
              // key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "23vw",
                borderRadius: 10,
                padding: 10,
                border: ".5px solid #d8d0d0",

                //  backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <Typography style={{ fontWeight: 700 }}>User KYC</Typography>
                <Typography style={{ fontSize: 13 }}>
                  User verification completed: 100%
                </Typography>
              </div>

              <Switch onChange={handleKYCChange} checked={kycStatus} />
            </div>
          </div>
        </div>
        <Card
          onClick={onClose}
          elevation={5}
          style={{
            width: 30,
            height: 30,

            // backgroundColor: "black",
            cursor: "pointer",
            borderRadius: 10,
            // color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",

            top: 35,
            right: 20,
            fontWeight: "bold",
          }}
        >
          <IconButton size="large" color="black">
            <MdOutlineCancel color="black" />
          </IconButton>
        </Card>
      </div>
    </div>
  );
};

export default Modal;
