import { BASE_URL } from "../constant";
import AssetContext from "../context/AssetsContext/AssetContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AssetState = (propsx) => {
  
  const [assets, setAssets] = useState([]);
  const [totalAssets, setTotalAssets] = useState(0);
  const [statistics, setStatistics] = useState(null);
  const [availableFilters, setAvailableFilters] = useState(null);
  const [amenities, setAmenities] = useState([]);

  const getAssetStatistics = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/asset/getassetstat`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        setStatistics(json.data);
        //console.log(statistics);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAssetAmenities = async (props) => {

    const { ids } = props;

    const queryParams = {
      ids: ids,
    };

    try {
      const response = await fetch(
        `${BASE_URL}/filter/homznoffiz/amenity/get?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // const parsedData = json.data.map((item) => ({
        //   ...item,
        // }));

        if (json.data.length > 0) {

          // const groupByCategory = (amenities) => {
          //   return amenities.reduce(
          //     (acc, amenity) => {
          //       const { category } = amenity;
          //       if (!acc[category]) {
          //         acc[category] = [];
          //       }
          //       acc[category].push(amenity);
          //       if (!acc.list.includes(category)) {
          //         acc.list.push(category);
          //       }
          //       return acc;
          //     },
          //     { list: [] }
          //   );
          // };
          // const groupedAmenities = groupByCategory(json.data);

           setAmenities(json.data);

        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };


  const getAssets = async (props) => {
    const { page } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = { limit: limit, offset: offset };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/asset/getallx?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        const parsedData = json.data.map((item) => ({
          ...item,
          property_bhks: JSON.parse(item.property_bhks), // Convert property_bhk string to JSON
          property_images: JSON.parse(item.property_images),  
          story_images: JSON.parse(item.story_images),  
          marketing_materials: JSON.parse(item.marketing_materials),  
          floor_plans: JSON.parse(item.floor_plans), // Convert property_bhk string to JSON
          landmarks: JSON.parse(item.landmarks), // Convert property_bhk string to JSON
          faqs: JSON.parse(item.faqs), // Convert property_bhk string to JSON
          aminities: JSON.parse(item.aminities), // Convert property_bhk string to JSON
          rating_details: JSON.parse(item.rating_details), // Convert property_bhk string to JSON
          specification_rooms: JSON.parse(item.specification_rooms), // Convert property_bhk string to JSON
        }));
        //   console.log(parsedData);
        setAssets(parsedData);
        setTotalAssets(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getSearchAssets = async (props) => {
    const {
      page,
      property_type,
      property_status,
      property_location,
      property_builder,
      property_bhks,

      status,
      property_starting_price,
      property_maximum_price,
      property_name,
    } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      property_type: property_type,
      property_status: property_status,
      property_location: property_location,
      property_builder: property_builder,

      property_bhks: property_bhks,
      status: status,
      property_starting_price: property_starting_price,
      property_maximum_price: property_maximum_price,
      property_name: property_name,

      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/asset/search/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        const parsedData = json.data.map((item) => ({
          ...item,
          property_bhks: JSON.parse(item.property_bhks), // Convert property_bhk string to JSON
          property_images: JSON.parse(item.property_images),  
          story_images: JSON.parse(item.story_images),  
          marketing_materials: JSON.parse(item.marketing_materials), 
          floor_plans: JSON.parse(item.floor_plans), // Convert property_bhk string to JSON
          landmarks: JSON.parse(item.landmarks), // Convert property_bhk string to JSON
          faqs: JSON.parse(item.faqs), // Convert property_bhk string to JSON
          aminities: JSON.parse(item.aminities), // Convert property_bhk string to JSON
          rating_details: JSON.parse(item.rating_details), // Convert property_bhk string to JSON
          specification_rooms: JSON.parse(item.specification_rooms), // Convert property_bhk string to JSON

        }));
        //console.log(parsedData);
        setAssets(parsedData);
        setTotalAssets(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAvailableFilters = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/filter/getfilterx`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();

        setAvailableFilters(json.data);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const addAssetx = async (props) => {
    const { data } = props;

 
    try {
      const response = await fetch(`${BASE_URL}/asset/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ data }),
      });

      if (response.status === 200) {
        getAssets({ page: 1 });
        toast.success("Asset Created successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't Add asset, Try again later");
      }
    } catch (e) {
      toast.error("Couldn't Add asset, Try again later");
    }
  };

  const addResaleRentalAssetx = async (props) => {
    const { data } = props;

    console.log(data);

    try {
      const response = await fetch(`${BASE_URL}/asset/rentalResale/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ data }),
      });

      if (response.status === 200) {
        getAssets({ page: 1 });
        toast.success("Rental/Resale Asset Created successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't Add asset, Try again later");
      }
    } catch (e) {
      toast.error("Couldn't Add asset, Try again later");
    }
  };

  const deleteAssets = async (props) => {
    const { ids, page } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/asset/bulk/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ ids: ids }),
      });
      if (response.status === 200) {
        // const json = response.json();
        getAssets({ page: page });
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete builder(s), Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete builder(s), Try again later");

      // console.log(e.message);
    }
  };

  const UpdateAnAsset = async (props) => {
    const { id, data } = props;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/asset/update/${id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ data }),
        });

        if (response.status === 200) {
          toast.success("Asset updated successfully");
          
          getAssets({page:1});
  
          resolve(true);
        }else if(response.status===403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update user's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update user's status, Try again later");
        reject(error);
      }
    });
  };

  return (
    <AssetContext.Provider
      value={{
        assets,
        totalAssets,
        getAssets,

        getSearchAssets,

        getAssetStatistics,
        statistics,

        addAssetx,
        addResaleRentalAssetx,

        deleteAssets,

        getAvailableFilters,
        availableFilters,
        amenities,
        getAssetAmenities,
        UpdateAnAsset,
      }}
    >
      {propsx.children}
    </AssetContext.Provider>
  );
};
export default AssetState;
