import React, { useState, useContext, useEffect } from "react";
import DonutChartUser from "../DonutChart";
import { MdOutlineCancel } from "react-icons/md";
import builderContext from "../../context/BuildersContext/BuilderContext";
import { IMAGE_PATH } from "../../constant";

import {
  Switch,
  Card,
  Typography,
  IconButton,

  // TextField,
} from "@mui/material";
 import UserImagex from "../../../src/assets/images/user.jpg"; // Path to your image file

const Modal = ({ isOpen, onClose, user }) => {
  const [userStatus, setUserStatus] = useState(true);
  const [kycStatus, setKYCStatus] = useState(true);
   const context = useContext(builderContext);
  const {
    sitevisitsOverview,
    invoiceOverview,
    subscriptionOverview,
    
    UpdateBuilderStatus,
    UpdateKYCStatus,
  } = context;

  useEffect(() => {
    setUserStatus(user.status);
    setKYCStatus(user.kyc_verified);
    // eslint-disable-next-line
  }, [user]);

  const handleChange = (checked) => {};

  const handleStatusChange = async (event) => {
    // console.log(checked);
    setUserStatus(event.target.checked);

    const stat = await UpdateBuilderStatus({
      id: user.id,
      status: user.status ? 0 : 1,
    });
    if (stat) {
      window.location.reload();

      //  console.log(stat);
    }
  };
  const handleKYCChange = async (event) => {
    // setUserStatus(event.target.checked);
    setKYCStatus(event.target.checked);

    const stat = await UpdateKYCStatus({
      id: user.id,
      kyc_verified: user.kyc_verified ? 0 : 1,
    });
    if (stat) {
      window.location.reload();

      //  console.log(stat);
    }
  };

 

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex:2,
      }}
    >
      <div
        style={{
          position: "relative",

          margin: "auto",
          width: "50%" /* Adjust the width as needed */,
          maxWidth: "50vw" /* Max width to limit the modal size */,
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 10,
            overflowY: "auto",
          }}
        >
          <h5>User Overview</h5>
          <label>ID: {user.id}</label>
          <div
            style={{
              position: "relative",

              display: "flex",
              borderRadius: 10,
              justifyContent: "space-between",
              marginTop: 15,
              //backgroundColor:"black",
            }}
          >
            <Card
              style={{
                borderRadius: 10,
                height:  "21vw",
                width: "16vw",
                padding: 10,
              }}
              elevation={5}
            >
              <img
                style={{
                  borderRadius: 10,
                  // margin:10,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={user.image_url === "" ? UserImagex : IMAGE_PATH + user.image_url}
                alt="userxxx"
              />
            </Card>
            <div
              style={{
                marginLeft: 15,
                display: "flex",
                //justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  minWidth: "25vw",
                  justifyContent: "space-between",
                  paddingBottom: 15,
                  // alignItems:"center"
                }}
              >
                <div style={{ height: "100%" }}>
                  {sitevisitsOverview.length > 0 ? (
                    <DonutChartUser
                      data={{
                        title: "Site Visits Overview",
                        value:
                        sitevisitsOverview && sitevisitsOverview[0]
                            ? sitevisitsOverview[0].total_records
                            : 0 ,
                        labels: [
                          "Pending",
                          "Accepted",
                          "Completed",
                          "Cancelled",
                         ],
                        colors: [
                          "#1B59F8",
                          "#3966db",
                          "#3456aa",
                          "#5168a0",
                         ],
                        data: [
                          sitevisitsOverview[0].total_records !== 0
                            ? sitevisitsOverview[0].status_1
                            : 1,
                            sitevisitsOverview[0].total_records !== 0
                            ? sitevisitsOverview[0].status_2
                            : 1,
                            sitevisitsOverview[0].total_records !== 0
                            ? sitevisitsOverview[0].status_3
                            : 1,
                            sitevisitsOverview[0].total_records !== 0
                            ? sitevisitsOverview[0].status_4
                            : 1,
                         
                        ],
                      }}
                    />
                  ) : null}
                </div>
                <div style={{ height: "100%", marginLeft: 20 }}>
                  {subscriptionOverview.length > 0 ? (
                    <DonutChartUser
                      data={{
                        title: "Subscription Overview",
                        value:
                          subscriptionOverview && subscriptionOverview[3]
                            ? subscriptionOverview[3].total_records
                            : 0,
                        labels: ["Silver", "Gold", "Diamond"],
                        colors: ["#1B59F8", "#3966db", "#3456aa"],
                        data: [
                          subscriptionOverview[0].total_records !== 0
                            ? subscriptionOverview[0].total_records
                            : 1,
                          subscriptionOverview[1].total_records !== 0
                            ? subscriptionOverview[1].total_records
                            : 1,
                          subscriptionOverview[2].total_records !== 0
                            ? subscriptionOverview[2].total_records
                            : 1,
                        ],
                      }}
                    />
                  ) : null}
                </div>
              </div>
 
              <div style={{ height: "100%" }}>
                  {invoiceOverview.length > 0 ? (
                    <DonutChartUser
                      data={{
                        title: "Invoices Overview",
                        value:
                        invoiceOverview && invoiceOverview[0]
                            ? invoiceOverview[0].total_records
                            : 0,
                        labels: [
                          `Pending (${invoiceOverview[0].status_1})`,
                          `Paid (${invoiceOverview[0].status_2})`,
                          `Rejected (${invoiceOverview[0].status_3})`,
                         ],
                        colors: [
                          "#1B59F8",
                          "#3966db",
                           "#5168a0",
                         ],
                        data: [
                          invoiceOverview[0].total_records !== 0
                            ? invoiceOverview[0].status_1
                            : 1,
                            invoiceOverview[0].total_records !== 0
                            ? invoiceOverview[0].status_2
                            : 1,
                            invoiceOverview[0].total_records !== 0
                            ? invoiceOverview[0].status_3
                            : 1
                          
                         
                        ],
                      }}
                    />
                  ) : null}
                </div>
           
            </div>
          </div>
          <h5 style={{ marginTop: 20 }}>User Controls</h5>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
           
            <div
              // key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "23vw",
                borderRadius: 10,
                padding: 10,
                border: ".5px solid #d8d0d0",

                //  backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <Typography style={{ fontWeight: 700 }}>User Status</Typography>
                <Typography style={{ fontSize: 13 }}>
                  Dissable it if you want to block the user from all app
                </Typography>
              </div>

              <Switch onChange={handleStatusChange} checked={userStatus} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 15,
            }}
          >
            <div
              // key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "23vw",
                borderRadius: 10,
                padding: 10,
                border: ".5px solid #d8d0d0",

                //  backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <Typography style={{ fontWeight: 700 }}>
                  User Subscription
                </Typography>
                <Typography style={{ fontSize: 13 }}>
                  User subscription expired on 22/10/2023
                </Typography>
              </div>

              <Switch
                onChange={handleChange}
                checked={user.subscription ? user.subscription : false}
              />
            </div>
            <div
              // key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "23vw",
                borderRadius: 10,
                padding: 10,
                border: ".5px solid #d8d0d0",

                //  backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <Typography style={{ fontWeight: 700 }}>User KYC</Typography>
                <Typography style={{ fontSize: 13 }}>
                  User verification completed: 100%
                </Typography>
              </div>

              <Switch onChange={handleKYCChange} checked={kycStatus} />
            </div>
          </div>
        </div>
        <Card
          onClick={onClose}
          elevation={5}
          style={{
            width: 30,
            height: 30,

            // backgroundColor: "black",
            cursor: "pointer",
            borderRadius: 10,
            // color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",

            top: 35,
            right: 20,
            fontWeight: "bold",
          }}
        >
          <IconButton size="large" color="black">
            <MdOutlineCancel color="black" />
          </IconButton>
        </Card>
      </div>
    </div>
  );
};

export default Modal;
