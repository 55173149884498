import React, { useState, useContext, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import ReorderableImageList from "./ImagesProv.jsx";
import {
  MdOutlineAddHomeWork,
  MdOutlineExpandMore,
  MdCancel,
} from "react-icons/md";

import AddRentNresale from "../ProjectsResell/AddRentNresale.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoAddCircle } from "react-icons/io5";
import { IoIosRemove, IoMdArrowDropdown } from "react-icons/io";
import { SpinnerDotted } from "spinners-react";
import assetContext from "../../context/AssetsContext/AssetContext.js";
import { TextField, IconButton, Switch } from "@mui/material";
import { BASE_URL, IMAGE_PATH } from "../../constant";
import { toast } from "react-toastify";
import * as Tabs from "@radix-ui/react-tabs";

import { AiTwotoneDelete } from "react-icons/ai";


const AddAsset = () => {
  const tabItems = [
    "Homznoffiz",
    "ResellProperties",
    "RentNresale",
    "ResaleHomes",
    "Tarry",
  ];

  const [projectTab, setProjectTab] = useState("Homznoffiz");

  const handleTabClick = (value) => {
    setProjectTab(value);
  };
  const validateAssets = require("./validateAssets");

  const context = useContext(assetContext);
  const { getAvailableFilters, availableFilters, addAssetx } = context;

  const [spinnerStatus, SetSpinnerStatus] = useState(false);

  const [propertyImages, setPropertyImages] = useState([]);
  const [storyImages, setStoryImages] = useState([]);
  const [marketiingMaterials, setMarketiingMaterials] = useState([]);

  const handleClick = async () => {
    SetSpinnerStatus(true);
    // console.log(data);

    //const dat = await setAllJSON( );
    // .then((message) => {
    //   console.log(message); // State updated successfully

    // })
    // .catch((error) => {
    //   console.error(error); // Handle error
    // });
    const errors = validateAssets(data);

    // Check for errors
    if (errors.length === 0) {
      toast.success("Validation successful!");

      // No errors, proceed with sending data to frontend
      addAssetx({ data });
    } else {
      errors.map((error, index) => {
        toast.error(error);
      }); // Validation failed, handle errors
      //console.log("Validation failed. Errors:", errors);
    }

    SetSpinnerStatus(false);

    // navigate("/dashboard/assets/asset_all");
  };

  const handleChange = (event) => {
    //setChecked(event.target.checked);
    setData((prevItem) => ({
      ...prevItem,
      is_exclusive: event.target.checked,
    }));
    // console.log(event.target.checked);
  };

  const specifications = [
    { id: 1, value: "Vitrified Tiles", label: "Vitrified Tiles" },
    { id: 2, value: "Oil Bound Distemper", label: "Oil Bound Distemper" },
    { id: 3, value: "Premium Bath Fittings", label: "Premium Bath Fittings" },
    { id: 4, value: "Acrylic Emulsion", label: "Acrylic Emulsion" },
  ];
  const landmarks_categories = [
    { id: 1, value: "Education", label: "Education" },
    { id: 2, value: "Transport", label: "Transport" },
    { id: 3, value: "Healthcare", label: "Healthcare" },
    { id: 4, value: "Businesshub", label: "Businesshub" },
    { id: 5, value: "Restaurant", label: "Restaurant" },
  ];

  const templandmarks = [
    {
      id: 1,
      value: "KIIT University",
      label: "KIIT University",
      name: "KIIT University",
      locality: "Prasanti Vihar, Patia",
      category: "Education",
      image: {
        fieldname: "profilex",
        originalname: "kiit-22.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719380604806_ckp3gwyjvi.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719380604806_ckp3gwyjvi.jpg",
        size: 60671,
      },
      lat: 20.3615988,
      lon: 85.8155921,
      distance: "",
    },
    {
      id: 2,
      value: "IIT Bhubaneswar",
      label: "IIT Bhubaneswar",
      name: "IIT Bhubaneswar",
      locality: "Kansapada, Odisha 752057",
      category: "Education",
      image: {
        fieldname: "profilex",
        originalname: "iit-11-26.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719380604803_nsfj29403bs.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719380604803_nsfj29403bs.jpg",
        size: 67737,
      },
      lat: 20.14901,
      lon: 85.6644143,
      distance: "",
    },
    {
      id: 3,
      value: "Odisha University of Technology and Research",
      label: "Odisha University of Technology and Research",
      name: "Odisha University of Technology and Research",
      locality: "Kalinga Nagar, Ghatikia",
      category: "Education",
      image: {
        fieldname: "profilex",
        originalname: "Odisha University of Technology and Research.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719380604803_3urw2nbs4tc.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719380604803_3urw2nbs4tc.jpg",
        size: 50652,
      },
      lat: 20.275845,
      lon: 85.7740641,
      distance: "",
    },
    {
      id: 4,
      value: "Utkal University",
      label: "Utkal University",
      name: "Utkal University",
      locality: "Vani Vihar, Bhubaneswar",
      category: "Education",
      image: {
        fieldname: "profilex",
        originalname: "Utkal University-06.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719380604798_i6fcpxturjq.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719380604798_i6fcpxturjq.jpg",
        size: 78299,
      },
      lat: 20.2969816,
      lon: 85.8379154,
      distance: "",
    },
    {
      id: 5,
      value: "Bhubaneswar International School",
      label: "Bhubaneswar International School",
      name: "Bhubaneswar International School",
      locality: "Chandaka Industrial Complex, Patia",
      category: "Education",
      image: {
        fieldname: "profilex",
        originalname: "Bhubaneswar International School.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719380604797_iyh39cp0s99.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719380604797_iyh39cp0s99.jpg",
        size: 40312,
      },
      lat: 20.3411558,
      lon: 85.8134754,
      distance: "",
    },
    {
      id: 6,
      value: "Bhubaneswar Institute of Industrial Technology(BIIT)",
      label: "Bhubaneswar Institute of Industrial Technology(BIIT)",
      name: "Bhubaneswar Institute of Industrial Technology(BIIT)",
      locality: "Republic of India, Jatni - Bhubaneswar Rd, Janla, Ogalapada",
      category: "Education",
      image: {
        fieldname: "profilex",
        originalname: "bit.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719380604794_gpuqfwcugy9.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719380604794_gpuqfwcugy9.jpg",
        size: 31092,
      },
      lat: 20.215301,
      lon: 85.7091676,
      distance: "",
    },
    {
      id: 7,
      value: "C. V. Raman Global University",
      label: "C. V. Raman Global University",
      name: "C. V. Raman Global University",
      locality: "Bidyanagar, Mahura, Janla, Bhubaneswar",
      category: "Education",
      image: {
        fieldname: "profilex",
        originalname: "cv ramna global university.png",
        encoding: "7bit",
        mimetype: "image/png",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719380604788_2e4r73cqpov.png",
        path: "/root/repos/agent/upload/default/profilex_1719380604788_2e4r73cqpov.png",
        size: 196666,
      },
      lat: 20.2192655,
      lon: 85.7332179,
      distance: "",
    },
    {
      id: 8,
      value: "Biju Patnaik International Airport",
      label: "Biju Patnaik International Airport",
      name: "Biju Patnaik International Airport",
      locality: "Airport Rd, Aerodrome Area",
      category: "Transport",
      image: {
        fieldname: "profilex",
        originalname: "airport.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719381804492_6g4868u0a5f.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719381804492_6g4868u0a5f.jpg",
        size: 50472,
      },
      lat: 20.2506834,
      lon: 85.8119639,
      distance: "",
    },
    {
      id: 9,
      value: "Railway station Bhubaneswar, New FOB",
      label: "Railway station Bhubaneswar, New FOB",
      name: "Railway station Bhubaneswar, New FOB",
      locality: "7R8V+66M, Master Canteen Chowk, Ashok Nagar",
      category: "Transport",
      image: {
        fieldname: "profilex",
        originalname: "railwaystation.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719381804493_o13drefko9t.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719381804493_o13drefko9t.jpg",
        size: 85962,
      },
      lat: 20.2653395,
      lon: 85.8403906,
      distance: "",
    },
    {
      id: 10,
      value: "Khandagiri Square",
      label: "Khandagiri Square",
      name: "Khandagiri Square",
      locality: "Master Canteen Chowk, Ashok Nagar",
      category: "Transport",
      image: {
        fieldname: "profilex",
        originalname: "khandgiri square.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719381804491_n1zf6t2t8od.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719381804491_n1zf6t2t8od.jpg",
        size: 49155,
      },
      lat: 20.2592603,
      lon: 85.7866403,
      distance: "",
    },
    {
      id: 11,
      value: "SUM Hospital",
      label: "SUM Hospital",
      name: "SUM Hospital",
      locality: "K8 Kalinga Nagar, Kalinganagar",
      category: "Healthcare",
      image: {
        fieldname: "profilex",
        originalname: "sumhospital.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382240321_aun78df1b7.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382240321_aun78df1b7.jpg",
        size: 55724,
      },
      lat: 20.283962,
      lon: 85.7676303,
      distance: "",
    },
    {
      id: 12,
      value:
        "Manipal Hospitals, Bhubaneswar | Best Multispeciality Hospital in Bhubaneswar",
      label:
        "Manipal Hospitals, Bhubaneswar | Best Multispeciality Hospital in Bhubaneswar",
      name: "Manipal Hospitals, Bhubaneswar | Best Multispeciality Hospital in Bhubaneswar",
      locality:
        "Plot No.1 Besides, Satyasai Enclave Rd, Khandagiri, Bhubaneswar, Shankarpur",
      category: "Healthcare",
      image: {
        fieldname: "profilex",
        originalname: "manipal-hospital-07.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382240322_vyswvnq59q9.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382240322_vyswvnq59q9.jpg",
        size: 50450,
      },
      lat: 20.2601318,
      lon: 85.774939,
      distance: "",
    },
    {
      id: 13,
      value: "Apollo Hospitals",
      label: "Apollo Hospitals",
      name: "Apollo Hospitals",
      locality:
        "Unit 15, plot no 251, Sainik School Rd, Doordarshan Colony, Gajapati Nagar",
      category: "Healthcare",
      image: {
        fieldname: "profilex",
        originalname: "apollohospital.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382240320_7z6b9w78lt7.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382240320_7z6b9w78lt7.jpg",
        size: 45615,
      },
      lat: 20.3057727,
      lon: 85.8294191,
      distance: "",
    },
    {
      id: 14,
      value: "AIIMS hospital bhubaneswar",
      label: "AIIMS hospital bhubaneswar",
      name: "AIIMS hospital bhubaneswar",
      locality: "Sijua, Patrapada, Bhubaneswar",
      category: "Healthcare",
      image: {
        fieldname: "profilex",
        originalname: "aiims.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382240320_dkkf5f6rqu.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382240320_dkkf5f6rqu.jpg",
        size: 43723,
      },
      lat: 20.2311685,
      lon: 85.7726724,
      distance: "",
    },
    {
      id: 15,
      value: "AMRI Hospitals - Information Centre & Superspecialty Clinic",
      label: "AMRI Hospitals - Information Centre & Superspecialty Clinic",
      name: "AMRI Hospitals - Information Centre & Superspecialty Clinic",
      locality: "PLOT NO 557, near BMC Bhawani Mall, Saheed Nagar",
      category: "Healthcare",
      image: {
        fieldname: "profilex",
        originalname: "amri-07-26.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382240320_ut9txm4jmqo.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382240320_ut9txm4jmqo.jpg",
        size: 63277,
      },
      lat: 20.2880376,
      lon: 85.8441471,
      distance: "",
    },
    {
      id: 16,
      value: "Capgemini Technology Services India Limited",
      label: "Capgemini Technology Services India Limited",
      name: "Capgemini Technology Services India Limited",
      locality: "JSS Software Park, Infocity, Chandrasekharpur",
      category: "Businesshub",
      image: {
        fieldname: "profilex",
        originalname: "capgimini_101248.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382829017_wzfxlvu46tr.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382829017_wzfxlvu46tr.jpg",
        size: 37660,
      },
      lat: 20.3415281,
      lon: 85.8031097,
      distance: "",
    },
    {
      id: 17,
      value: "Deloitte Bhubaneswar (ଡେଲ‌ଏଟ୍)",
      label: "Deloitte Bhubaneswar (ଡେଲ‌ଏଟ୍)",
      name: "Deloitte Bhubaneswar (ଡେଲ‌ଏଟ୍)",
      locality: "BMC Tower, UNIT- 9, Bhubaneswar",
      category: "Businesshub",
      image: {
        fieldname: "profilex",
        originalname: "delloite12-06.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382829018_19qf4osmz3i.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382829018_19qf4osmz3i.jpg",
        size: 60737,
      },
      lat: 20.2831249,
      lon: 85.8403269,
      distance: "",
    },
    {
      id: 18,
      value: "Accenture",
      label: "Accenture",
      name: "Accenture",
      locality: "Bhoi Nagar, Acharya Vihar - jaydev Vihar Rd, Squar",
      category: "Businesshub",
      image: {
        fieldname: "profilex",
        originalname: "accenture.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382829020_bog7bl0enn.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382829020_bog7bl0enn.jpg",
        size: 48959,
      },
      lat: 20.2945018,
      lon: 85.831881,
      distance: "",
    },
    {
      id: 19,
      value: "Infosys Bhubaneswar STP",
      label: "Infosys Bhubaneswar STP",
      name: "Infosys Bhubaneswar STP",
      locality: "Infocity, Chandrasekharpur",
      category: "Businesshub",
      image: {
        fieldname: "profilex",
        originalname: "infosys26.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382829021_9md1b77wne.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382829021_9md1b77wne.jpg",
        size: 61248,
      },
      lat: 20.3411869,
      lon: 85.80058,
      distance: "",
    },
    {
      id: 20,
      value: "Wipro Technologies",
      label: "Wipro Technologies",
      name: "Wipro Technologies",
      locality:
        "IDCO IT SEZ, Plot No. E/8, Wipro Technologies Rd, Infocity, Chandrasekharpur",
      category: "Businesshub",
      image: {
        fieldname: "profilex",
        originalname: "wipro05-28.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719382829023_fky6un5im05.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719382829023_fky6un5im05.jpg",
        size: 48563,
      },
      lat: 20.3432907,
      lon: 85.8008546,
      distance: "",
    },
    {
      id: 21,
      value: "Club on the Roof",
      label: "Club on the Roof",
      name: "Club on the Roof",
      locality: "The Presidency, 1471/1, Nayapalli, IRC Village, Nayapalli",
      category: "Restaurant",
      image: {
        fieldname: "profilex",
        originalname: "club on the roof restaurant.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719383651766_mtbnc28dda.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719383651766_mtbnc28dda.jpg",
        size: 49690,
      },
      lat: 20.2882631,
      lon: 85.8093784,
      distance: "",
    },
    {
      id: 22,
      value: "Green Restro N Lounge",
      label: "Green Restro N Lounge",
      name: "Green Restro N Lounge",
      locality: "4th Floor, DS Tower, KIIT Rd, Patia",
      category: "Restaurant",
      image: {
        fieldname: "profilex",
        originalname: "green restro and lounge restaurant.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719383651766_tw2u12ly24a.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719383651766_tw2u12ly24a.jpg",
        size: 102618,
      },
      lat: 20.3532139,
      lon: 85.8225201,
      distance: "",
    },
    {
      id: 23,
      value: "Richard's Kitchen and Coffee Bar",
      label: "Richard's Kitchen and Coffee Bar",
      name: "Richard's Kitchen and Coffee Bar",
      locality:
        "4th Floor, Rooftop of Bata Showroom, Plot No. A/2, Maruthi Villa, KIIT Rd, Patia",
      category: "Restaurant",
      image: {
        fieldname: "profilex",
        originalname: "richards kitcjen and cofee bar17.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719383651768_no7nh6r63fi.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719383651768_no7nh6r63fi.jpg",
        size: 54452,
      },
      lat: 20.3533988,
      lon: 85.823349,
      distance: "",
    },
    {
      id: 24,
      value: "High Me Up - Club & Sky Lounge",
      label: "High Me Up - Club & Sky Lounge",
      name: "High Me Up - Club & Sky Lounge",
      locality:
        "N 5/537, Indradhanu Market Rd, Block N5, IRC Village, Nayapalli",
      category: "Restaurant",
      image: {
        fieldname: "profilex",
        originalname: "high me up restaurant-28.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719383651768_ns6fo1he27j.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719383651768_ns6fo1he27j.jpg",
        size: 61293,
      },
      lat: 20.2947085,
      lon: 85.8112423,
      distance: "",
    },
    {
      id: 25,
      value: "9th Bistro",
      label: "9th Bistro",
      name: "9th Bistro",
      locality: "Hotel Sheetal, Gridco Colony, Saheed Nagar",
      category: "Restaurant",
      image: {
        fieldname: "profilex",
        originalname: "9th bistov restorent.jpg",
        encoding: "7bit",
        mimetype: "image/jpeg",
        destination: "/root/repos/agent/upload/default",
        filename: "profilex_1719383651768_ech2mj08uag.jpg",
        path: "/root/repos/agent/upload/default/profilex_1719383651768_ech2mj08uag.jpg",
        size: 59635,
      },
      lat: 20.2960246,
      lon: 85.8368387,
      distance: "",
    },
  ];

  const [roomSpecification, setRoomSpecification] = useState({
    master_bedroom_flooring: "",
    living_area_flooring: "",
    other_bedroom_flooring: "",
    walls: "",
    bathroom: "",
    kitchen: "",
  });

  const handleSpecificationChange = (key, value) => {
    setRoomSpecification((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  const [rating_details, setRatingDetails] = useState({
    sports_and_outdoor: "0",
    green_area: "0",
    club_house: "0",
    fittings_and_furnishing: "0",
    specification: "0",
    connectivity: "0",
    livability: "0",
    lifestle: "0",
    value_for_money: "0",
  });

  const handleRatingChange = (key, value) => {
    setRatingDetails((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  const [bhk, setBhk] = useState([]);

  const handleSelectBHK = (newValue, actionMeta) => {
    // Check the action performed
    if (actionMeta.action === "select-option") {
      setBhk((prevItems) => [
        ...prevItems,
        {
          id: actionMeta.option.id,
          name: actionMeta.option.name,
        },
      ]);
    } else if (actionMeta.action === "remove-value") {
      setBhk(bhk.filter((item) => item.id !== actionMeta.removedValue.id));
      //console.log("Option removed - ID:", actionMeta.removedValue.id);
    }
    // You can access all selected information from the newValue array
  };

  const [aminity, setAminity] = useState([]);

  const handleSelectAminity = (newValue, actionMeta) => {
    // Check the action performed
    if (actionMeta.action === "select-option") {
      setAminity((prevItems) => [
        ...prevItems,
        actionMeta.option.id,
        // {
        //   id: actionMeta.option.id,
        //   name: actionMeta.option.name,
        //   category: actionMeta.option.category,
        //   icon: actionMeta.option.icon,
        // },
      ]);
    } else if (actionMeta.action === "remove-value") {
      // console.log(actionMeta.removedValue);
      //    console.log(aminity);
      setAminity(aminity.filter((item) => item !== actionMeta.removedValue.id));
      //console.log("Option removed - ID:", actionMeta.removedValue.id);
    }
    // You can access all selected information from the newValue array
  };

  const [expandedItems, setExpandedItems] = useState({});
  const [floorPlan, setFlorPlan] = useState([]);

  const toggleExpand = (index) => {
    setExpandedItems({
      ...expandedItems,
      [index]: !expandedItems[index],
    });
  };

  const deleteByIndex = (index) => {
    setFlorPlan((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
    // console.log(bhk);
  };

  const handleFloor = () => {
    let x = Math.floor(Math.random() * 100 + 1);
    const ok = {
      id: x,
      image: "",
      size: "",
      price: 0,
      type: "",
    };
    setFlorPlan(floorPlan.concat(ok));
    // console.log(floorPlan.length);
  };

  const handleFloorPlanChange = async (index, key, value) => {
    if (key === "imageadd" || key === "imageremove") {
      if (key === "imageadd") {
        try {
          if (value.target.files.length > 0) {
            const formData = new FormData();
            formData.append("profilex", value.target.files[0]);

            const queryParams = {
              folderName:
                data.property_name !== ""
                  ? generateSlug(data.property_name)
                  : "default",
            };

            const response = await fetch(
              `${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`,
              {
                method: "POST",
                headers: {
                  // "Content-Type": "application/json",
                  // Accept: "application/json",
                },
                body: formData,
              }
            );
            if (response.status === 200) {
              const json = await response.json();

              const selectedFile = value.target.files[0];
              const reader = new FileReader();

              reader.onload = (value) => {
                setFlorPlan((prevList) => {
                  const newList = [...prevList];
                  newList[index]["image"] = json.data[0];
                  return newList;
                });
              };

              if (selectedFile) {
                reader.readAsDataURL(selectedFile);
              }
              //toast.success("Media uploded successfully");
            } else {
              // toast.error("Couldn't upload media(s), Try again later");
              console.log(response.status);
            }
          }
        } catch (e) {
          console.log(e);
          // toast.error("Couldn't upload media(s), Try again later");
        }
      } else {
        try {
          //  roadsandLandmarks[index].image
          const queryParams = { filePath: floorPlan[index].image.path };

          const response = await fetch(
            `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response.status === 200) {
            setFlorPlan((prevList) => {
              const newList = [...prevList];
              newList[index]["image"] = "";
              return newList;
            });
          } else {
            // toast.error("Couldn't upload media(s), Try again later");
          }
        } catch (e) {
          console.log(e);
          // toast.error("Couldn't upload media(s), Try again later");
        }
      }
    } else {
      setFlorPlan((prevList) => {
        const newList = [...prevList];
        newList[index][key] = value;
        return newList;
      });
    }

    // console.log(floorPlan);
  };

  const [expandedItemsRoad, setExpandedItemsRoad] = useState({});
  const [roadsandLandmarks, setRoadsAndLandmark] = useState([]);

  const toggleExpandRoad = (index) => {
    setExpandedItemsRoad({
      ...expandedItemsRoad,
      [index]: !expandedItemsRoad[index],
    });
  };

  const deleteByIndexRoad = (index) => {
    setRoadsAndLandmark((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleRoad = () => {
    let x = Math.floor(Math.random() * 100 + 1);
    const ok = {
      id: x,
      image: "",
      name: "",
      locality: "",
      category: "",
      lat: 0.0,
      lon: 0.0,
      distance: "",
    };
    setRoadsAndLandmark(roadsandLandmarks.concat(ok));
    // console.log(roadsandLandmarks.length);
  };

  const handleRoadChange = async (index, key, value) => {
    if (key === "imageadd" || key === "imageremove") {
      if (key === "imageadd") {
        try {
          if (value.target.files.length > 0) {
            const formData = new FormData();
            formData.append("profilex", value.target.files[0]);

            const queryParams = {
              folderName:
                data.property_name !== ""
                  ? generateSlug(data.property_name)
                  : "default",
            };

            const response = await fetch(
              `${BASE_URL}/uploadx?${new URLSearchParams(queryParams)}`,
              {
                method: "POST",
                headers: {
                  // "Content-Type": "application/json",
                  // Accept: "application/json",
                },
                body: formData,
              }
            );
            if (response.status === 200) {
              const json = await response.json();

              const selectedFile = value.target.files[0];
              const reader = new FileReader();

              reader.onload = (value) => {
                setRoadsAndLandmark((prevList) => {
                  const newList = [...prevList];
                  newList[index]["image"] = json.data[0];
                  return newList;
                });
              };

              if (selectedFile) {
                reader.readAsDataURL(selectedFile);
              }
              //toast.success("Media uploded successfully");
            } else {
              // toast.error("Couldn't upload media(s), Try again later");
              console.log(response.status);
            }
          }
        } catch (e) {
          console.log(e);
          // toast.error("Couldn't upload media(s), Try again later");
        }
      } else {
        try {
          //  roadsandLandmarks[index].image
          const queryParams = { filePath: roadsandLandmarks[index].image.path };

          const response = await fetch(
            `${BASE_URL}/delete-file?${new URLSearchParams(queryParams)}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response.status === 200) {
            setRoadsAndLandmark((prevList) => {
              const newList = [...prevList];
              newList[index]["image"] = "";
              return newList;
            });
          } else {
            // toast.error("Couldn't upload media(s), Try again later");
          }
        } catch (e) {
          console.log(e);
          // toast.error("Couldn't upload media(s), Try again later");
        }
      }
    } else {
      setRoadsAndLandmark((prevList) => {
        const newList = [...prevList];
        newList[index][key] = value;
        return newList;
      });
    }

    // console.log(roadsandLandmarks);
  };
  const handleTempRoadChange = async (index, data) => {
    setRoadsAndLandmark((prevList) => {
      const newList = [...prevList];
      newList[index]["name"] = data.name;
      newList[index]["locality"] = data.locality;
      newList[index]["category"] = data.category;
      newList[index]["image"] = data.image;
      newList[index]["lat"] = data.lat;
      newList[index]["lon"] = data.lon;
      return newList;
    });

    // console.log(roadsandLandmarks);
  };

  const [expandedItemsFAQ, setExpandedItemsFAQ] = useState({});
  const [faqs, setFAQ] = useState([]);

  const toggleExpandFAQ = (index) => {
    setExpandedItemsFAQ({
      ...expandedItemsFAQ,
      [index]: !expandedItemsFAQ[index],
    });
  };

  const deleteByIndexFAQ = (index) => {
    setFAQ((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleFAQ = () => {
    let x = Math.floor(Math.random() * 100 + 1);
    const ok = {
      id: x,
      question: "",
      answer: "",
    };
    setFAQ(faqs.concat(ok));
    console.log(faqs.length);
  };

  const handleFAQChange = (index, key, value) => {
    setFAQ((prevList) => {
      const newList = [...prevList];
      newList[index][key] = value;
      return newList;
    });
  };

  const handleData = (key, value) => {
    if (key === "property_name") {
      setData((prevItem) => ({
        ...prevItem,
        property_name: value,
        slug: generateSlug2(value),
      }));
    } else {
      setData((prevItem) => ({
        ...prevItem,
        [key]: value,
      }));
    }
  };

  const setAllJSON = () => {
    setData((prevItem) => ({
      ...prevItem,
      property_bhks: JSON.stringify({ bhks: bhk }),
      floor_plans: JSON.stringify({ plans: floorPlan }),
      aminities: JSON.stringify({ aminities: aminity }),
      faqs: JSON.stringify({ faqs: faqs }),
      rating_details: JSON.stringify(rating_details),
      specification_rooms: JSON.stringify(roomSpecification),
      landmarks: JSON.stringify({ landmarks: roadsandLandmarks }),
      story_images: JSON.stringify({ images: storyImages }),
      property_images: JSON.stringify({ images: propertyImages }),
      marketing_materials: JSON.stringify({ data: marketiingMaterials }),
    }));
  };

  const [data, setData] = useState({
    property_name: "",
    slug: "",
    status: 1,
    property_type: "",
    property_status: "",
    property_city: "",
    property_location: "",
    property_builder: "",
    property_starting_price: 0.0,
    property_maximum_price: 0.0,
    brochure: "", //String
    story_images: JSON.stringify({}),
    cover_image: "", //String
    admin_id: JSON.parse(localStorage.getItem("me")).id,
    project_rera: "",
    homznoffiz_rera: "",
    is_exclusive: 0,
    description: "",
    latitude: 0.0,
    longitude: 0.0,
    property_units: 0,
    property_size: 0,
    property_locality: "",
    property_bhks: JSON.stringify({}),
    property_images: JSON.stringify({}),
    marketing_materials: JSON.stringify({}),
    property_video: "", //String
    floor_plans: JSON.stringify({}),
    aminities: JSON.stringify({}),
    connecting_roads: JSON.stringify({}),
    faqs: JSON.stringify({}),
    rating_details: JSON.stringify({}),
    specification_rooms: JSON.stringify({}),
    agents: JSON.stringify({}),
    landmarks: JSON.stringify({}),
    master_plan: "",
  });

  const handleDataReceived = (data) => {
    if (data.id === 1) {
      const parsedData = data.data.map((item) => ({
        url: item.fieldname + "/" + item.filename,
      }));
      setPropertyImages(parsedData);
    } else if (data.id === 2) {
      const parsedData = data.data.map((item) => ({
        url: item.fieldname + "/" + item.filename,
      }));
      setStoryImages(parsedData);
    } else if (data.id === 3) {
      handleData(
        "cover_image",
        data.data.length > 0
          ? data.data[0].fieldname + "/" + data.data[0].filename
          : ""
      );
      //handleData("admin_id", JSON.parse(localStorage.getItem("me")).id);
    } else if (data.id === 4) {
      const parsedData = data.data.map((item) => ({
        url: item.fieldname + "/" + item.filename,
      }));
      setMarketiingMaterials(parsedData);
    } else if (data.id === 5) {
      handleData(
        "brochure",
        data.data.length > 0
          ? data.data[0].fieldname + "/" + data.data[0].filename
          : ""
      );
    } else if (data.id === 6) {
      handleData(
        "property_video",
        data.data.length > 0
          ? data.data[0].fieldname + "/" + data.data[0].filename
          : ""
      );
    } else if (data.id === 7) {
      handleData(
        "master_plan",
        data.data.length > 0
          ? data.data[0].fieldname + "/" + data.data[0].filename
          : ""
      );
    } else {
      console.log("Data in ParentComponentxx:", data);
    }
  };

  useEffect(() => {
    getAvailableFilters();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAvailableFilters();
    setAllJSON();
    // eslint-disable-next-line
  }, [
    bhk,
    floorPlan,
    aminity,
    faqs,
    rating_details,
    roomSpecification,
    roadsandLandmarks,
    storyImages,
    propertyImages,
    marketiingMaterials,
  ]);

  function generateSlug(text) {
    return text
      .toLowerCase() // Convert text to lowercase
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .replace(/[^a-z0-9_]/g, ""); // Remove non-alphanumeric characters except underscores
  }
  function generateSlug2(text) {
    return text
      .toLowerCase() // Convert text to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
  }

  function stringToDoubleWithDecimal(input) {
    let doubleValue = parseFloat(input);
    return parseFloat(doubleValue.toFixed(3)); // Convert to float with one decimal place
  }

  function trimCoordinate(coordinate, precision = 8) {
    // Trim the coordinate to the specified precision
    const trimmedCoordinate = parseFloat(
      parseFloat(coordinate).toFixed(precision)
    );

    return trimmedCoordinate;
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(196, 196, 196, 0.15)",
          margin: 0,

          // paddingLeft:100,
          // paddingRight:100,
          maxHeight: "98vh", // Set maximum height to screen height
          overflowY: "auto", // Enable vertical scrolling if content exceeds height
          border: "1px solid #ccc",
          borderRadius: "10px",
          maxWidth: "100vw",
        }}
      >
        <ToastContainer position="top-right" />

        <SpinnerDotted
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 1,
            transform: "translate(-50%, -50%)",
          }}
          size={70}
          thickness={140}
          speed={140}
          color="#36ad47"
          enabled={spinnerStatus}
        />
        <div className="sticky top-2 z-30">
          <Tabs.Root
            defaultValue="Homznoffiz"
            className="max-w-screen-2xl mx-auto px-4 md:px-8 bg-white border border-gray-300 rounded-md shadow-md bg-white py-4"
          >
            <Tabs.List
              className="w-full flex flex-wrap gap-x-3 overflow-x-auto text-sm sticky top-16 bg-white z-30 border-b justify-start"
              aria-label="Manage your account"
            >
              {tabItems.map((item, idx) => (
                <Tabs.Trigger
                  key={idx}
                  className="group outline-none py-1.5 border-b-2 border-transparent text-gray-500 data-[state=active]:border-indigo-600 data-[state=active]:text-indigo-600"
                  value={item}
                  onClick={() => handleTabClick(item)}
                >
                  <div className="py-1.5 px-3 rounded-lg duration-150 group-hover:text-indigo-600 group-hover:bg-gray-50 group-active:bg-gray-100 font-medium">
                    {item}
                  </div>
                </Tabs.Trigger>
              ))}
            </Tabs.List>
            {/* {tabItems.map((item, idx) => (
            <Tabs.Content key={idx} className="py-6" value={item}>
              <p className="text-xs leading-normal">
                This is <b>{item}</b> Tab
              </p>
            </Tabs.Content>
          ))} */}
          </Tabs.Root>
        </div>
        {projectTab === "Homznoffiz" ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 15,
                marginTop: 5,
              }}
            >
              <div style={{ fontSize: 25, fontWeight: 600 }}>
                Enter project details
              </div>
              <div className="browse_asset">
                <div
                  style={{
                    paddingLeft: 10,
                    marginLeft: 15,
                    marginRight: 15,
                    padding: "5px 35px",
                    backgroundColor: "#00073D",
                    color: "white",
                    fontSize: 18,
                    alignContent: "center",
                    justifyContent: "center",
                    borderRadius: 7,
                  }}
                  onClick={handleClick}
                >
                  <IconButton type="submit" edge="start">
                    <MdOutlineAddHomeWork color="white" />
                  </IconButton>
                  Add Asset
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "20px",
                marginTop: 15,
                // height: 35,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Name
                </label>
                <TextField
                  id="propertyName"
                  placeholder="Name" // Hint text
                  variant="outlined"
                  ////value={data.property_name}

                  onChange={(e) => handleData("property_name", e.target.value)}
                  fullWidth
                  style={{ backgroundColor: "white" }}
                  inputProps={{ style: { height: 9, width: "22vw" } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>

              <div style={{ marginLeft: 20 }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property RERA
                </label>

                <TextField
                  id="propertyRera"
                  placeholder="RERA" // Hint text
                  variant="outlined"
                  ////value={data.project_rera}

                  onChange={(e) => handleData("project_rera", e.target.value)}
                  fullWidth
                  style={{ backgroundColor: "white" }}
                  inputProps={{ style: { height: 9, width: "22vw" } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>

              <div style={{ marginLeft: 20, marginRight: 100 }}>
                <div style={{ marginBottom: "10px" }}>Exclusive Property</div>
                <Switch
                  checked={data.is_exclusive}
                  onChange={handleChange}
                  inputProps={
                    {
                      //  "aria-label": "toggle"
                    }
                  }
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 5,
                //height:35,
                maxWidth: "80vw",

                //  backgroundColor: "black",
                marginLeft: 20,
                marginRight: 20,

                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 10 }}>
                <div style={{ marginBottom: "10px" }}>
                  <label htmlFor="property Description">
                    Property Description
                  </label>
                </div>
                <TextField
                  id="propertyDescription"
                  variant="outlined"
                  ////value={data.description}

                  onChange={(e) => handleData("description", e.target.value)}
                  fullWidth
                  multiline
                  placeholder="Enter property description here" // Hint text
                  rows={10}
                  style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                  inputProps={{ style: { height: "160px" } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>
              <div style={{ flex: 1 }}> </div>
              <div style={{ flex: 4 }}>
                <div>
                  <label style={{ marginBottom: "10px", flex: 1 }}>
                    Property Latitude
                  </label>

                  <TextField
                    id="propertyRera"
                    placeholder="12.2222222"
                    // helperText="Project's latitude"
                    // Hint text
                    ////value={data.latitude}

                    variant="outlined"
                    onChange={(e) =>
                      handleData("latitude", trimCoordinate(e.target.value))
                    }
                    fullWidth
                    style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                    inputProps={{ style: { height: 8 } }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>

                <div>
                  <label
                    style={{ marginTop: "10px", marginBottom: "10px", flex: 1 }}
                  >
                    Property Longitude
                  </label>

                  <TextField
                    id="propertyRera"
                    placeholder="27.2222222"
                    // helperText="Project's longitude"
                    // Hint text
                    ////value={data.longitude}

                    variant="outlined"
                    onChange={(e) =>
                      handleData("longitude", trimCoordinate(e.target.value))
                    }
                    fullWidth
                    style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                    inputProps={{ style: { height: 8 } }}
                    InputLabelProps={{ style: { fontSize: "15px" } }}
                  />
                </div>
              </div>
              <div style={{ flex: 2 }}></div>
            </div>
            <div
              style={{
                padding: "20px",
                marginTop: 15,
                //height:35,

                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Starting Price
                </label>
                <TextField
                  id="propertyName"
                  placeholder="1"
                  helperText="Project's minimum cost in Crore"
                  // Hint text
                  variant="outlined"
                  //value={data.property_starting_price}

                  onChange={(e) =>
                    handleData(
                      "property_starting_price",
                      stringToDoubleWithDecimal(e.target.value)
                    )
                  }
                  fullWidth
                  style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                  inputProps={{ style: { height: 8 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>

              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Maximum Price
                </label>

                <TextField
                  id="propertyRera"
                  placeholder="3.6"
                  //value={data.property_maximum_price}

                  helperText="Project's maximum cost in Crore"
                  // Hint text
                  variant="outlined"
                  onChange={(e) =>
                    handleData(
                      "property_maximum_price",
                      stringToDoubleWithDecimal(e.target.value)
                    )
                  }
                  fullWidth
                  style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                  inputProps={{ style: { height: 8 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Units
                </label>

                <TextField
                  id="propertyRera"
                  placeholder="110"
                  helperText="Project's total units in number"
                  // Hint text
                  //value={data.property_units}

                  variant="outlined"
                  onChange={(e) =>
                    handleData(
                      "property_units",
                      stringToDoubleWithDecimal(e.target.value)
                    )
                  }
                  fullWidth
                  style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                  inputProps={{ style: { height: 8 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Size
                </label>

                <TextField
                  id="propertyRera"
                  placeholder="4" // Hint text
                  //value={data.property_size}

                  variant="outlined"
                  onChange={(e) =>
                    handleData(
                      "property_size",
                      stringToDoubleWithDecimal(e.target.value)
                    )
                  }
                  helperText="Project's size in Acre"
                  fullWidth
                  style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                  inputProps={{ style: { height: 8 } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>
            </div>

            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                marginTop: 15,
                // height:35,

                width: "100%",
                display: "flex",
                alignItems: "center",
                //  justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Type
                </label>
                <CreatableSelect
                  // isMulti
                  //value={{ label: data.property_type, value: data.property_type }}

                  styles={{ flex: 1, width: 400, color: "black" }}
                  placeholder="Select Property Type....."
                  onChange={(e) => handleData("property_type", e.value)}
                  options={
                    availableFilters !== null
                      ? availableFilters.property_type.map((option) => ({
                          label: option.name,
                          value: option.name,
                        }))
                      : []
                  }
                />
              </div>
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Status
                </label>
                <CreatableSelect
                  // isMulti
                  styles={{ flex: 1 }}
                  placeholder="Select Property Status....."
                  //value={{
                  //   label: data.property_status,
                  //   value: data.property_status,
                  // }}
                  onChange={(e) => handleData("property_status", e.value)}
                  options={
                    availableFilters !== null
                      ? availableFilters.property_status.map((option) => ({
                          label: option.name,
                          value: option.name,
                        }))
                      : []
                  }
                />
              </div>
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Builder
                </label>
                <CreatableSelect
                  // isMulti
                  styles={{ flex: 1 }}
                  // value={{
                  //   label: data.property_builder,
                  //   value: data.property_builder,
                  // }}
                  placeholder="Select Property Builder....."
                  onChange={(e) => handleData("property_builder", e.value)}
                  options={
                    availableFilters !== null
                      ? availableFilters.property_builder.map((option) => ({
                          label: option.name,
                          value: option.name,
                        }))
                      : []
                  }
                />
              </div>
            </div>
            <div
              style={{
                padding: "20px",
                marginTop: 15,
                // height:35,
                width: "100%",
                display: "flex",
                alignItems: "center",
                //  justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Location
                </label>
                <CreatableSelect
                  // isMulti
                  styles={{ flex: 1, width: 400 }}
                  // value={{
                  //   label: data.property_location,
                  //   value: data.property_location,
                  // }}
                  placeholder="Select Property Location....."
                  onChange={(e) => handleData("property_location", e.value)}
                  options={
                    availableFilters !== null
                      ? availableFilters.property_location.map((option) => ({
                          label: option.name,
                          value: option.name,
                        }))
                      : []
                  }
                />
              </div>
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Locality
                </label>
                <CreatableSelect
                  // isMulti
                  styles={{ flex: 1 }}
                  // value={{
                  //   label: data.property_locality,
                  //   value: data.property_locality,
                  // }}
                  placeholder="Select Property Locality....."
                  onChange={(e) => handleData("property_locality", e.value)}
                  options={
                    availableFilters !== null
                      ? availableFilters.property_locality.map((option) => ({
                          label: option.name,
                          value: option.name,
                        }))
                      : []
                  }
                />
              </div>
              <div style={{ flex: 1, marginRight: "10px" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property BHK
                </label>
                <CreatableSelect
                  isMulti
                  styles={{ flex: 1 }}
                  placeholder="Select Property BHK....."
                  onChange={handleSelectBHK} // Call handleChange when selection changes
                  options={
                    availableFilters !== null
                      ? availableFilters.property_bhk.map((option) => ({
                          label: option.name,
                          value: option.name,
                          id: option.id,
                          name: option.name,
                        }))
                      : []
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <ReorderableImageList
                idx={1}
                name="Property Images"
                folder={
                  data.property_name !== ""
                    ? generateSlug(data.property_name)
                    : "default"
                }
                isMultiple={true}
                accept="image/*"
                onDataReceived={handleDataReceived}
              />
              <ReorderableImageList
                idx={2}
                name="Property Story Images"
                isMultiple={true}
                folder={
                  data.property_name !== ""
                    ? generateSlug(data.property_name)
                    : "default"
                }
                accept="image/*"
                onDataReceived={handleDataReceived}
              />
            </div>

            <div style={{ display: "flex" }}>
              <ReorderableImageList
                idx={3}
                name="Property Cover Image"
                isMultiple={false}
                folder={
                  data.property_name !== ""
                    ? generateSlug(data.property_name)
                    : "default"
                }
                accept="image/*"
                onDataReceived={handleDataReceived}
              />
              <ReorderableImageList
                idx={4}
                name="Property Marketing Materials(optional)"
                isMultiple={true}
                folder={
                  data.property_name !== ""
                    ? generateSlug(data.property_name)
                    : "default"
                }
                accept="image/*"
                onDataReceived={handleDataReceived}
              />
            </div>

            <div style={{ display: "flex" }}>
              <ReorderableImageList
                idx={5}
                name="Property Brochure"
                isMultiple={false}
                folder={
                  data.property_name !== ""
                    ? generateSlug(data.property_name)
                    : "default"
                }
                accept=".pdf"
                onDataReceived={handleDataReceived}
              />
              <ReorderableImageList
                idx={7}
                name="Property Master Plan"
                isMultiple={false}
                folder={
                  data.property_name !== ""
                    ? generateSlug(data.property_name)
                    : "default"
                }
                accept="image/*"
                onDataReceived={handleDataReceived}
              />
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ width: "47%", marginRight: 20, marginLeft: 20 }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property video
                </label>
                <TextField
                  id="propertyVideo"
                  placeholder="Video URL" // Hint text
                  variant="outlined"
                  // value={data.property_video}

                  onChange={(e) => handleData("property_video", e.target.value)}
                  fullWidth
                  style={{ backgroundColor: "white" }}
                  inputProps={{ style: { height: 9, width: "22vw" } }}
                  InputLabelProps={{ style: { fontSize: "15px" } }}
                />
              </div>

              {/* <ReorderableImageList
            idx={6}
            name="Property Video(optional)"
            isMultiple={false}
            folder={
              data.property_name !== ""
                ? generateSlug(data.property_name)
                : "default"
            }
            accept="video/mp4"
            onDataReceived={handleDataReceived}
          /> */}
            </div>

            <div
              style={{
                padding: "20px",
                marginTop: 15,
                // height:35,
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                //  justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  flex: 1,
                  marginRight: "10px",
                  backgroundColor: "white",
                }}
              >
                <label
                  style={{ marginLeft: "20px", marginTop: "20px", flex: 1 }}
                >
                  Property Floor-Plan
                </label>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#ffffff",
                    padding: 15,
                  }}
                >
                  <label style={{ flex: 1, marginTop: 10 }}>
                    Add Floor Plan
                  </label>
                  <IconButton onClick={handleFloor}>
                    <IoAddCircle size={30} />
                  </IconButton>
                </div>

                <ol>
                  {floorPlan.map((floor, index) => (
                    <li key={index}>
                      <IconButton onClick={() => deleteByIndex(index)}>
                        <AiTwotoneDelete />
                      </IconButton>

                      <IconButton onClick={() => toggleExpand(index)}>
                        {expandedItems[index] ? (
                          <IoMdArrowDropdown />
                        ) : (
                          <MdOutlineExpandMore />
                        )}
                      </IconButton>
                      {expandedItems[index] && (
                        <div
                          key={floor.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            backgroundColor: "white",
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            padding: 5,
                            marginRight: 20,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // alignItems: "center",
                              backgroundColor: "white",
                              padding: 10,
                              height: 150,
                              width: 150,
                              // justifyContent: "center",
                            }}
                          >
                            {!floorPlan[index].image && (
                              <div>
                                <label
                                  style={{ marginBottom: "10px", flex: 1 }}
                                >
                                  Floor Image
                                </label>
                                <div>
                                  <label
                                    htmlFor="fileInput"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      padding: "10px",
                                      border: "1px solid #ccc",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Choose File
                                  </label>
                                  <input
                                    id="fileInput"
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    onChange={(e) =>
                                      handleFloorPlanChange(
                                        index,
                                        "imageadd",
                                        e
                                      )
                                    }
                                    style={{
                                      display: "none",
                                      borderRadius: 10,
                                      border: "1px solid #ccc",
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {floorPlan[index].image && (
                              <div style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    border: "1px solid lightgrey",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    position: "relative",
                                  }}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      handleFloorPlanChange(
                                        index,
                                        "imageremove",
                                        e
                                      )
                                    }
                                  >
                                    <MdCancel size={25} />
                                  </span>
                                  <img
                                    src={
                                      IMAGE_PATH +
                                      floorPlan[index].image.fieldname +
                                      "/" +
                                      floorPlan[index].image.filename
                                    }
                                    alt="Selected"
                                    style={{
                                      width: "120px",
                                      height: "130px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "white",
                              padding: 10,

                              height: 150,
                              width: 170,
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ flex: 1, marginRight: "10px" }}>
                              <label style={{ marginBottom: 10, flex: 1 }}>
                                Floor Size
                              </label>
                              <TextField
                                id="propertyName"
                                //  value={floor.id}
                                placeholder="1200"
                                //value={floor.size}

                                helperText="Floor size in sqft"
                                // Hint text
                                variant="outlined"
                                onChange={(e) =>
                                  handleFloorPlanChange(
                                    index,
                                    "size",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                                inputProps={{ style: { height: 8 } }}
                                InputLabelProps={{
                                  style: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "white",
                              padding: 10,

                              height: 150,
                              width: 170,
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ flex: 1, marginRight: "10px" }}>
                              <label style={{ marginBottom: 10, flex: 1 }}>
                                Floor Price
                              </label>
                              <TextField
                                id="propertyName"
                                placeholder="1.2"
                                helperText="Floor price in cr."
                                // Hint text
                                variant="outlined"
                                //value={floor.price}

                                onChange={(e) =>
                                  handleFloorPlanChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                // style={{ height: 20 }}
                                inputProps={{ style: { height: 8 } }}
                                InputLabelProps={{
                                  style: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "white",
                              padding: 10,

                              height: 150,
                              width: 200,
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ flex: 1, marginRight: "10px" }}>
                              <label style={{ marginBottom: "10px", flex: 1 }}>
                                Floor Type
                              </label>
                              <CreatableSelect
                                // isMulti
                                styles={{ flex: 1 }}
                                placeholder="Select Type"
                                //value={{
                                //   label: floor.type,
                                //   value: floor.type,
                                // }}
                                onChange={(selectedOption) => {
                                  handleFloorPlanChange(
                                    index,
                                    "type",
                                    selectedOption.value
                                  );
                                }}
                                options={
                                  availableFilters !== null
                                    ? availableFilters.property_bhk.map(
                                        (option) => ({
                                          label: option.name,
                                          value: option.name,
                                        })
                                      )
                                    : []
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </div>

              <div
                style={{
                  flex: 1,
                  marginRight: "10px",
                  backgroundColor: "white",
                  padding: "20px",
                }}
              >
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Property Amenities
                </label>
                <CreatableSelect
                  isMulti
                  styles={{ flex: 1 }}
                  placeholder="Select Property Amenities....."
                  onChange={handleSelectAminity} // Call handleChange when selection changes
                  // value={aminity}
                  //onChange={(e) => console.log(e.value.id)}
                  options={
                    availableFilters !== null
                      ? availableFilters.property_amenity.map((option) => ({
                          label: option.name,
                          value: option.name,
                          id: option.id,
                          name: option.name,
                          category: option.category,
                          icon: option.icon,
                        }))
                      : []
                  }
                />
              </div>
            </div>

            <div
              style={{
                // backgroundColor: "white",
                // height:35,
                width: "97%",
                display: "flex",
                alignItems: "flex-start",
                margin: 20,
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 1, padding: 20, backgroundColor: "white" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Connecting Roads and Landmarks
                </label>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#ffffff",
                    padding: 15,
                  }}
                >
                  <label style={{ flex: 1, marginTop: 10 }}>
                    Add Landmarks
                  </label>
                  <IconButton onClick={handleRoad}>
                    <IoAddCircle size={30} />
                  </IconButton>
                </div>

                <ol>
                  {roadsandLandmarks.map((road, index) => (
                    <li key={index}>
                      <IconButton onClick={() => deleteByIndexRoad(index)}>
                        <AiTwotoneDelete />
                      </IconButton>

                      <IconButton onClick={() => toggleExpandRoad(index)}>
                        {expandedItemsRoad[index] ? (
                          <IoMdArrowDropdown />
                        ) : (
                          <MdOutlineExpandMore />
                        )}
                      </IconButton>
                      {expandedItemsRoad[index] && (
                        <div
                          key={road.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "column",
                            backgroundColor: "white",
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            padding: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              padding: 5,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                backgroundColor: "white",
                                padding: 10,

                                height: 100,
                                width: "12vw",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ flex: 1, marginRight: "10px" }}>
                                <label style={{ marginBottom: 10, flex: 1 }}>
                                  Landmark Name
                                </label>

                                <TextField
                                  id="propertyName"
                                  ////value={road.id}
                                  value={road.name}
                                  placeholder="Hotel Moon Star"
                                  helperText="Road/Landmark name"
                                  onChange={(e) =>
                                    handleRoadChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  // Hint text
                                  variant="outlined"
                                  fullWidth
                                  style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                                  inputProps={{ style: { height: 8 } }}
                                  InputLabelProps={{
                                    style: { fontSize: "15px" },
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                backgroundColor: "white",
                                padding: 10,

                                height: 100,
                                width: "12vw",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ flex: 1, marginRight: "10px" }}>
                                <label style={{ marginBottom: 10, flex: 1 }}>
                                  Locality
                                </label>
                                <TextField
                                  id="propertyName"
                                  placeholder="Kanakapura Road"
                                  helperText="Locality Name"
                                  value={road.locality}
                                  // Hint text
                                  onChange={(e) =>
                                    handleRoadChange(
                                      index,
                                      "locality",
                                      e.target.value
                                    )
                                  }
                                  variant="outlined"
                                  fullWidth
                                  // style={{ height: 20 }}
                                  inputProps={{ style: { height: 8 } }}
                                  InputLabelProps={{
                                    style: { fontSize: "15px" },
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                margin: "0px 10px",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label style={{ marginBottom: 10, flex: 1 }}>
                                  Category
                                </label>
                                <CreatableSelect
                                  // isMulti
                                  styles={{ flex: 1, width: 400 }}
                                  placeholder="Select Category"
                                  value={{
                                    id: road.category.id,
                                    value: road.category,
                                    label: road.category,
                                  }}
                                  onInputChange={(value) => value.toUpperCase()}
                                  onChange={(selectedOption) => {
                                    handleRoadChange(
                                      index,
                                      "category",
                                      selectedOption.value
                                    );
                                  }}
                                  options={landmarks_categories}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", justifyContent: "start" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  backgroundColor: "white",
                                  borderRadius: "5px",
                                  padding: 5,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    padding: 10,

                                    height: 100,
                                    width: "12vw",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div style={{ flex: 1, marginRight: "10px" }}>
                                    <label
                                      style={{ marginBottom: 10, flex: 1 }}
                                    >
                                      Latitude
                                    </label>
                                    <TextField
                                      id="propertyName"
                                      value={road.lat}
                                      ////value={road.id}
                                      placeholder="12.9568611"
                                      helperText="Ex-12.9568611"
                                      // Hint text
                                      variant="outlined"
                                      onChange={(e) =>
                                        handleRoadChange(
                                          index,
                                          "lat",
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                      style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                                      inputProps={{ style: { height: 8 } }}
                                      InputLabelProps={{
                                        style: { fontSize: "15px" },
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    padding: 10,

                                    height: 100,
                                    width: "12vw",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div style={{ flex: 1, marginRight: "10px" }}>
                                    <label
                                      style={{ marginBottom: 10, flex: 1 }}
                                    >
                                      Longitude
                                    </label>
                                    <TextField
                                      id="propertyName"
                                      placeholder="77.5330066"
                                      helperText="Ex-77.5330066"
                                      // Hint text
                                      value={road.lon}
                                      variant="outlined"
                                      onChange={(e) =>
                                        handleRoadChange(
                                          index,
                                          "lon",
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                      // style={{ height: 20 }}
                                      inputProps={{ style: { height: 8 } }}
                                      InputLabelProps={{
                                        style: { fontSize: "15px" },
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  backgroundColor: "white",
                                  borderRadius: "5px",
                                  padding: 5,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    padding: 10,

                                    height: 100,
                                    width: "12vw",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div style={{ flex: 1, marginRight: "10px" }}>
                                    <label
                                      style={{ marginBottom: 10, flex: 1 }}
                                    >
                                      Distance from project
                                    </label>
                                    <TextField
                                      id="propertyName"
                                      // //value={road.id}
                                      placeholder="6"
                                      helperText="In KM"
                                      // Hint text
                                      //value={road.distance}

                                      variant="outlined"
                                      onChange={(e) =>
                                        handleRoadChange(
                                          index,
                                          "distance",
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                      style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                                      inputProps={{ style: { height: 8 } }}
                                      InputLabelProps={{
                                        style: { fontSize: "15px" },
                                      }}
                                    />
                                  </div>
                                </div>

                                {data.property_location === "Bhubaneswar" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      backgroundColor: "white",
                                      padding: 10,

                                      height: 100,
                                      width: "12vw",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{ flex: 1, marginRight: "10px" }}
                                    >
                                      <label
                                        style={{ marginBottom: 10, flex: 1 }}
                                      >
                                        Shoose Shortcuts
                                      </label>
                                      <CreatableSelect
                                        // isMulti
                                        styles={{ flex: 1, width: 400 }}
                                        placeholder="Select Name"
                                        // value={{
                                        //   id: road.category.id,
                                        //   value: road.category,
                                        //   label: road.category,
                                        // }}
                                        onInputChange={(value) =>
                                          value.toUpperCase()
                                        }
                                        onChange={(selectedOption) => {
                                          handleTempRoadChange(
                                            index,
                                            selectedOption
                                          );
                                        }}
                                        options={templandmarks}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                // alignItems: "center",
                                backgroundColor: "white",

                                height: 200,
                                width: 182,

                                // justifyContent: "center",
                              }}
                            >
                              {!roadsandLandmarks[index].image && (
                                <div style={{ marginLeft: 5 }}>
                                  <label
                                    style={{ marginBottom: "10px", flex: 1 }}
                                  >
                                    Image
                                  </label>
                                  <div>
                                    <label
                                      htmlFor="fileInput"
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: 5,
                                        width: 175,
                                        // backgroundColor:"black",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      Select Image File
                                    </label>
                                    <input
                                      id="fileInput"
                                      type="file"
                                      accept=".jpg,.jpeg,.png"
                                      onChange={(e) =>
                                        handleRoadChange(index, "imageadd", e)
                                      }
                                      style={{
                                        display: "none",
                                        borderRadius: 10,
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {roadsandLandmarks[index].image && (
                                <div style={{ alignItems: "center" }}>
                                  <div
                                    style={{
                                      border: "1px solid lightgrey",
                                      borderRadius: "5px",
                                      padding: "5px",
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleRoadChange(
                                          index,
                                          "imageremove",
                                          e
                                        )
                                      }
                                    >
                                      <MdCancel size={25} />
                                    </span>
                                    <img
                                      src={
                                        IMAGE_PATH +
                                        roadsandLandmarks[index].image
                                          .fieldname +
                                        "/" +
                                        roadsandLandmarks[index].image.filename
                                      }
                                      alt="Error"
                                      style={{
                                        width: "170px",
                                        height: "190px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </div>

              <div style={{ width: 20, backgroundColor: "black" }}></div>

              <div style={{ flex: 1, padding: 20, backgroundColor: "white" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Frequently Asked Questions
                </label>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#ffffff",
                    padding: 15,
                  }}
                >
                  <label style={{ flex: 1, marginTop: 10 }}>Add FAQ</label>
                  <IconButton onClick={handleFAQ}>
                    <IoAddCircle size={30} />
                  </IconButton>
                </div>

                <ol>
                  {faqs.map((faq, index) => (
                    <li key={index}>
                      <IconButton onClick={() => deleteByIndexFAQ(index)}>
                        <AiTwotoneDelete />
                      </IconButton>

                      <IconButton onClick={() => toggleExpandFAQ(index)}>
                        {expandedItemsFAQ[index] ? (
                          <IoMdArrowDropdown />
                        ) : (
                          <MdOutlineExpandMore />
                        )}
                      </IconButton>
                      {expandedItemsFAQ[index] && (
                        <div
                          key={faq.id}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            backgroundColor: "white",
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            padding: 5,
                          }}
                        >
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label style={{ marginBottom: 10, flex: 1 }}>
                              Enter Question
                            </label>
                            <TextField
                              id="propertyName"
                              // //value={faq.id}
                              placeholder="Your question"
                              helperText="Try to add a genuine question"
                              // Hint text
                              variant="outlined"
                              //value={faq.question}

                              onChange={(e) =>
                                handleFAQChange(
                                  index,
                                  "question",
                                  e.target.value
                                )
                              }
                              fullWidth
                              style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                              inputProps={{ style: { height: 8 } }}
                              InputLabelProps={{ style: { fontSize: "15px" } }}
                            />
                          </div>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label style={{ marginBottom: 10, flex: 1 }}>
                              Enter Answer
                            </label>
                            <TextField
                              id="propertyName"
                              placeholder="Your answer"
                              helperText="Try to add a genuine answer"
                              // Hint text
                              variant="outlined"
                              //value={faq.answer}

                              onChange={(e) =>
                                handleFAQChange(index, "answer", e.target.value)
                              }
                              fullWidth
                              // style={{ height: 20 }}
                              inputProps={{ style: { height: 8 } }}
                              InputLabelProps={{ style: { fontSize: "15px" } }}
                            />
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            </div>

            <div
              style={{
                // backgroundColor: "white",
                // height:35,
                width: "97%",
                display: "flex",
                alignItems: "flex-start",
                margin: 20,
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 1, padding: 20, backgroundColor: "white" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Rating Details
                </label>

                <div
                  style={{
                    display: "flex",
                    //  justifyContent: "space-around",
                    backgroundColor: "white",
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    flexDirection: "column",
                    padding: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Sports & Outdoor
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="4.2"
                          helperText="Rate this from 5"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleRatingChange(
                              "sports_and_outdoor",
                              e.target.value
                            )
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Green Area
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="4.2"
                          helperText="Rate this from 5"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleRatingChange("green_area", e.target.value)
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                    <div
                      style = {{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,
                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Club House
                        </label>
                        <TextField
                          id="propertyName"
                          placeholder="4.2"
                          helperText="Rate this from 5"
                          onChange={(e) =>
                            handleRatingChange("club_house", e.target.value)
                          }
                          // Hint text
                          variant="outlined"
                          fullWidth
                          // style={{ height: 20 }}
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Fittings & Furnishing
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="4.2"
                          helperText="Rate this from 5"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleRatingChange(
                              "fittings_and_furnishing",
                              e.target.value
                            )
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Specifications
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="4.2"
                          helperText="Rate this from 5"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleRatingChange("specification", e.target.value)
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Connectivity
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="8"
                          helperText="Rate this from 10"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleRatingChange("connectivity", e.target.value)
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Livability
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="8"
                          helperText="Rate this from 10"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleRatingChange("livability", e.target.value)
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Lifestyle
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="8"
                          helperText="Rate this from 10"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleRatingChange("lifestle", e.target.value)
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{ style: { height: 8 } }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: 10,

                        height: 100,
                        width: 200,
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <label style={{ marginBottom: 10, flex: 1 }}>
                          Value for Money
                        </label>
                        <TextField
                          id="propertyName"
                          // //value={road.id}
                          placeholder="8"
                          helperText="Rate this from 10"
                          // Hint text
                          variant="outlined"
                          onChange={(e) =>
                            handleRatingChange(
                              "value_for_money",
                              e.target.value
                            )
                          }
                          fullWidth
                          style={{ backgroundColor: "white" }} // Adjust the number of rows as needed
                          inputProps={{
                            style: { height: 8 },
                          }}
                          InputLabelProps={{ style: { fontSize: "15px" } }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: 20, backgroundColor: "black" }}></div>

              <div style={{ flex: 1, padding: 20, backgroundColor: "white" }}>
                <label style={{ marginBottom: "10px", flex: 1 }}>
                  Specifications of Rooms
                </label>
                <div
                  // key={faq.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    backgroundColor: "white",
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    padding: 5,
                  }}
                >
                  <div style={{ display: "flex", flex: 1, margin: "5px 10px" }}>
                    <div style={{ flex: 1 }}>
                      <label style={{ marginBottom: 10, flex: 1 }}>
                        Master Bedroom-Flooring
                      </label>
                      <CreatableSelect
                        // isMulti
                        styles={{ flex: 1, width: 400 }}
                        placeholder="Master Bedroom-Flooring Type....."
                        onChange={(e) =>
                          handleSpecificationChange(
                            "master_bedroom_flooring",
                            e.value
                          )
                        }
                        options={specifications}
                      />
                    </div>
                    <div style={{ width: 20 }}></div>
                    <div style={{ flex: 1 }}>
                      <label style={{ marginBottom: 10, flex: 1 }}>
                        Living Area-Flooring
                      </label>
                      <CreatableSelect
                        // isMulti
                        styles={{ flex: 1, width: 400 }}
                        placeholder="Living Area-Flooring Type....."
                        onChange={(e) =>
                          handleSpecificationChange(
                            "living_area_flooring",
                            e.value
                          )
                        }
                        options={specifications}
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", flex: 1, margin: "30px 10px" }}
                  >
                    <div style={{ flex: 1 }}>
                      <label style={{ marginBottom: 10, flex: 1 }}>
                        Other Bedrooms-Flooring
                      </label>
                      <CreatableSelect
                        // isMulti
                        styles={{ flex: 1, width: 400 }}
                        placeholder="Other Bedrooms-Flooring Type....."
                        onChange={(e) =>
                          handleSpecificationChange(
                            "other_bedroom_flooring",
                            e.value
                          )
                        }
                        options={specifications}
                      />
                    </div>
                    <div style={{ width: 20 }}></div>
                    <div style={{ flex: 1 }}>
                      <label style={{ marginBottom: 10, flex: 1 }}>Walls</label>
                      <CreatableSelect
                        // isMulti
                        styles={{ flex: 1, width: 400 }}
                        placeholder="Walls Type....."
                        onChange={(e) =>
                          handleSpecificationChange("walls", e.value)
                        }
                        options={specifications}
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", flex: 1, margin: "20px 10px" }}
                  >
                    <div style={{ flex: 1 }}>
                      <label style={{ marginBottom: 10, flex: 1 }}>
                        Bathroom
                      </label>
                      <CreatableSelect
                        // isMulti
                        styles={{ flex: 1, width: 400 }}
                        placeholder="Bathroom Type....."
                        onChange={(e) =>
                          handleSpecificationChange("bathroom", e.value)
                        }
                        options={specifications}
                      />
                    </div>
                    <div style={{ width: 20 }}></div>
                    <div style={{ flex: 1 }}>
                      <label style={{ marginBottom: 10, flex: 1 }}>
                        Kitchen
                      </label>
                      <CreatableSelect
                        // isMulti
                        styles={{ flex: 1, width: 400 }}
                        placeholder="Kitchen Type....."
                        onChange={(e) =>
                          handleSpecificationChange("kitchen", e.value)
                        }
                        options={specifications}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : projectTab === "ResellProperties" ? (
          <AddRentNresale />
        ) : null}
      </div>
    </>
  );
};

export default AddAsset;
