import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const FilterModal = () => {
  const [promise, setPromise] = useState(null);
  const [file_type, setFileType] = useState([]);

  const types = [".png", ".jpg", ".svg", ".mp3", ".mp4", ".pdf"];
  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve({
      file_type: file_type,
    });
    handleClose();
  };

  const handleType = (element) => {
    const arr = toggleElement(file_type, element);
    setFileType(arr);
  };

  function toggleElement(array, element) {
    const index = array.indexOf(element);

    if (index === -1) {
      // Element is not present, so insert it
      array.push(element);
    } else {
      // Element is present, so remove it
      array.splice(index, 1);
    }

    return [...array]; // Make sure to return a new array to trigger re-render
  }

  const ConfirmationDialog = () => (
    <Dialog open={promise !== null} onClose={handleClose} fullWidth>
      <DialogTitle style={{ fontWeight: "700" }}>
        Apply File's Filters
      </DialogTitle>
      <DialogContent>
        <div>
          {/* <div
            style={{
              color: "black", 
            //  padding: "2px",
              fontSize:10
            }}
          >
            Note: Selected filters will be applied during search. 
          </div> */}
          <h6>File Types</h6>
          <div style={{ display: "flex", fontSize: 15 }}>
            {types.map((type, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: file_type.includes(type) ? "black" : "white",
                  color: file_type.includes(type) ? "white" : "black",
                  borderRadius: 10,
                  border: file_type.includes(type)
                    ? "0px solid #d8d0d0"
                    : ".5px solid #d8d0d0",
                  padding: "2px 10px",
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleType(type)}
              >
                {type}
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Apply</Button>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};

export default FilterModal;
