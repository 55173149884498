import { BASE_URL } from "../constant";
import AssetLandmarkContext from "../context/AssetsContext/AssetLandmarks";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AssetLandmarkState = (propsx) => {
  const [assetsLandmarks, setAssetsLandmarks] = useState([]);
 
  

  const getAssetsLandmarks = async (props) => {
    // API Call

    try {
      const response = await fetch(`${BASE_URL}/filter/plandmark/get`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
          console.log(json.data);

        const parsedData = json.data.map((item) => ({
          ...item,
          image: JSON.parse(item.image), // Convert property_bhk string to JSON
        }));
        //   console.log(parsedData);
        setAssetsLandmarks(parsedData);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  const getSearchAssetsLandmarks = async ({name}) => {
    // API Call

    try {
      const response = await fetch(`${BASE_URL}/filter/plandmark/search/get/${name}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);

        const parsedData = json.data.map((item) => ({
          ...item,
          image: JSON.parse(item.image),  
        }));
        //   console.log(parsedData);
        setAssetsLandmarks(parsedData);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        setAssetsLandmarks([]);
        toast.error("Couldn't found any record");

        // console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      setAssetsLandmarks([]);

      toast.error("Couldn't found any record");
 
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const createAssetLandmark = async (props) => {
    const { 
    
      image,
      name,
      locality,
      category,
      lat,
      lon,
      distance,
    } = props;
    // {
    //     "name":"Farm House",
    //     "description":"",
    //     "icon":""
    // }

    try {
      const response = await fetch(`${BASE_URL}/filter/plandmark/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: name,
          locality: locality,
          category: category,
          lat: lat,
          lon: lon,
          distance: distance,
          image: image === null ? null : JSON.stringify(image),
        }),
      });

      if (response.status === 200) {
        getAssetsLandmarks();
        toast.success("Landmark created successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        console.log(response.error);
        toast.error("Couldn't Add Landmark, Try again later");
      }
    } catch (e) {
      console.log(e);

      toast.error("Couldn't  add Landmark, Try again later");
    }
  };

  const updateAssetLandmark = async (props) => {
    const {
      id,
      image,
      name,
      locality,
      category,
      lat,
      lon,
      distance, } = props;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${BASE_URL}/filter/plandmark/update/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              name: name,
              locality: locality,
              category: category,
              lat: lat,
              lon: lon,
              distance: distance,
              image: image === null ? null : JSON.stringify(image),
            }),
          }
        );

        if (response.status === 200) {
          toast.success("Landmark updated successfully");

          getAssetsLandmarks();
          //console.log(users);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update, Try again later");
        reject(error);
      }
    });
  };

  const deleteAssetsLandmark = async (props) => {
    const { id } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/filter/plandmark/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        // const json = response.json();
        getAssetsLandmarks();
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete, Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete, Try again later");

      // console.log(e.message);
    }
  };

  return (
    <AssetLandmarkContext.Provider
      value={{
 
        assetsLandmarks,
        getAssetsLandmarks,
        getSearchAssetsLandmarks,

        createAssetLandmark,
        updateAssetLandmark,
        deleteAssetsLandmark,
      }}
    >
      {propsx.children}
    </AssetLandmarkContext.Provider>
  );
};
export default AssetLandmarkState;





