import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { AiFillDelete } from "react-icons/ai";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import DemoImage from "./demodoc.png"; // Path to your image file
import Switch from "react-switch";

const AllKYCUsers = () => {
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  // let navigate = useNavigate();

  const [checked, setChecked] = useState(true);

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const [showLead, setShowLead] = useState({
    id: "1",
    uid: "12",
    account: "Agent",
    name: "SIDHARTH SEKHAR Mahanta",
    email: "sidhrtaha758013@gmail.com",
    phone: "+918114617238",
    pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
    aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
    gst: { front: DemoImage, doc: "GST34234353" },
    rera: { front: DemoImage, doc: "RERA34234353" },
    cin: { front: DemoImage, doc: "CIN34234353" },

    status: "Verified",
    date: "21-02-2024 11:50AM",
  });

  const handleShowLead = (lead) => {
    console.log(lead.id);
    setShowLead(lead);

    // You can add logic here to fetch data for the selected page
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // You can add logic here to fetch data for the selected page
  };

  const list = [
    {
      id: "1",
      uid: "12",
      account: "Agent",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "90%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "2",
      uid: "122",
      account: "Manager",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "75%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "3",
      uid: "123",
      account: "Manager",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "75%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "4",
      uid: "124",
      account: "Agent",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "100%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "5",
      uid: "125",
      account: "Agent",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "100%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "6",
      uid: "126",
      account: "Agent",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "70%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "7",
      uid: "127",
      account: "Agent",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "80%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "8",
      uid: "128",
      account: "Manager",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "100%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "9",
      uid: "129",
      account: "Agent",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "70%",
      date: "21-02-2024 11:50AM",
    },
    {
      id: "10",
      uid: "120",
      account: "Agent",

      name: "SIDHARTH SEKHAR Mahanta",
      email: "sidhrtaha758013@gmail.com",
      phone: "+918114617238",
      pan: { front: DemoImage, back: DemoImage, doc: "EKXPM6789J" },
      aadhar: { front: DemoImage, back: DemoImage, doc: "445678899087" },
      gst: { front: DemoImage, doc: "GST34234353" },
      rera: { front: DemoImage, doc: "RERA34234353" },
      cin: { front: DemoImage, doc: "CIN34234353" },

      status: "100%",
      date: "21-02-2024 11:50AM",
    },
  ];

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };
  // const checkedItems = checked.length
  //   ? checked.reduce((total, item) => {
  //       return total + ", " + item;
  //     })
  //   : "";

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // onSearch(query);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,

            // maxWidth: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              // backgroundColor: "white",
              justifyContent: "space-around",
              paddingTop: 15,
              //alignItems:"center"
            }}
          >
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  Total Users
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>15555</label>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  Total Verified Users
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>1555</label>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                minWidth: "15vw",
                maxWidth: "15vw",

                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <Typography
                  variant="h7"
                  style={{ color: "white", fontWeight: 600 }}
                >
                  KYC Unverified Users
                </Typography>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <label>999</label>
                </div>
              </CardContent>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 15,
              marginTop: 15,
            }}
          >
            <div
              style={{
                fontSize: 18,
              }}
            >
              Total Users (15555)
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <form
                className="form_search"
                onSubmit={handleSubmit}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  className="serach_asset"
                  variant="outlined"
                  placeholder="Search users..."
                  value={query}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton type="submit" edge="start">
                        <CiSearch />
                      </IconButton>
                    ),
                    style: {
                      height: "32px",
                      minWidth: "30vw",

                      // width: "500px", // Adjust the height as needed
                      flexGrow: 1, // Allow the input to grow to fill available space
                    },
                  }}
                  fullWidth
                />
              </form>
              <div
                style={{
                  paddingLeft: 10,
                  marginLeft: 15,
                  marginRight: 15,
                  backgroundColor: "rgba(192, 192, 192,0.4 )",
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: 7,
                }}
              >
                <IconButton type="submit" edge="start">
                  <AiFillDelete color="red" />
                </IconButton>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 15,
              fontFamily: "monospace",
              fontWeight: 550,
              textAlign: "left",
              maxWidth: "100%",
              paddingTop: 10,
              paddingLeft: 20,
              color: "grey",
              alignItems: "center",
              justifySelf: "start",
              height: 35,
            }}
          >
            {/* <input
              value="all"
              type="checkbox"
              style={{
                alignContent: "start",
                backgroundColor: "#000",
                marginBottom: "30px",
              }}
            /> */}

            <div
              style={{
                width: "2vw",
                height: 50,
                fontWeight: 600,
                marginLeft: 10,
               // alignContent: "center",
                // backgroundColor:"black"
              }}
            >
              ID
            </div>
            <div
              style={{
                width: "6vw",
                height: 50,
                maxLines: 1,

                // backgroundColor: "black",
              }}
            >
              Account
            </div>
            <div
              style={{
                width: "7vw",
                height: 50,
                maxLines: 1,
                marginLeft: 5,
                // backgroundColor: "black",
              }}
            >
              Phone
            </div>
            <div
              style={{
                width: "8vw",
                height: 50,
                maxLines: 1,
                // backgroundColor: "black",
              }}
            >
              Email
            </div>
            <div
              style={{
                width: "8vw",
                height: 50,
                maxLines: 1,
                marginLeft: 30,

                // backgroundColor: "black",
              }}
            >
              PAN
            </div>
            <div
              style={{
                width: "10vw",
                height: 50,
                maxLines: 1,
                //    / backgroundColor: "black",
              }}
            >
              Aadhar/Voter/DL
            </div>

            <div
              style={{
                width: "5vw",
                height: 50,
                maxLines: 1,

                // backgroundColor: "black",
              }}
            >
              Status
            </div>
            <div
              style={{
                width: "7vw",
                height: 50,
                maxLines: 1,
                // backgroundColor: "black",
              }}
            >
              Date
            </div>
          </div>
          <div
            style={{
              height: "65vh",
              overflowY: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              marginBottom: 10,
            }}
          >
            {list.map((item) => (
              <div
                key={item.id}
                onClick={() => handleShowLead(item)}
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",

                  flexWrap: "wrap",
                   color: "#000",
                  fontWeight: 530,
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  maxLines: 1,
                  borderBottom: ".5px solid #d8d0d0",

                  // paddingTop: 10,
                  marginTop: 5,
                }}
              >
                <input
                  value={item.id}
                  type="checkbox"
                  onChange={handleCheck}
                  style={{
                    alignContent: "start",
                    backgroundColor: "#000",
                    marginLeft: "10px",
                    // marginBottom: "30px",
                  }}
                />

                <div
                  style={{
                    width: "2vw",
                    height: 50,
                    fontWeight: 600,
                    marginLeft: 10,
                    alignContent: "center",
                  //  / paddingTop: "15px",
                  }}
                >
                  {item.id}
                </div>

                <div
                  style={{
                    width: "6vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.account}
                </div>
                <div
                  style={{
                    width: "7vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.phone}
                </div>
                <div
                  style={{
                    width: "8vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.email}
                </div>
                <div
                  style={{
                    width: "8vw",
                    height: 50,
                    marginLeft: 30,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.pan.doc}
                </div>
                <div
                  style={{
                    width: "10vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",

                    display: "inline-block",
                    whiteSpace: "nowrap",
                    //backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.aadhar.doc}
                </div>

                <div
                  style={{
                    width: "5vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",

                    color: item.status === "100%" ? "green" : "red",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.status}
                </div>
                <div
                  style={{
                    width: "7vw",
                    height: 50,
                    maxLines: 1,
                    paddingTop: "15px",
                    marginRight: 25,
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    // backgroundColor: "black",
                    overflow: "hidden",
                  }}
                >
                  {item.date}
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div
              style={{
                marginTop: 15,
                marginLeft: 15,
              }}
            >
              Showing 15 out of 100
            </div>
            <div>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageClick(pageNumber)}
                    style={{
                      margin: "5px",
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                    disabled={pageNumber === currentPage}
                  >
                    {pageNumber}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
        <div>
          <div>
            <Card
              style={{
                minWidth: "20vw",
                maxWidth: "22vw",
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    ID: #{showLead.id}
                  </Typography>
                  <Typography
                    variant="h7"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    {showLead.status}
                  </Typography>
                </div>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography>Phone</Typography>
                  <Typography>{showLead.phone}</Typography>
                </div>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography>Email</Typography>
                  <Typography>{showLead.email}</Typography>
                </div>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography>Account type</Typography>
                  <Typography>{showLead.account}</Typography>
                </div>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              style={{
                minWidth: "20vw",
                maxWidth: "22vw",
                maxHeight: "70vh",
                margin: "auto",
                marginTop: 10,
                marginLeft: 15,
                backgroundColor: "#00073D",
                borderRadius: 15,
              }}
            >
              <CardContent>
                <div
                  // key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    color: "white",
                  }}
                >
                  <Typography
                    variant="h7"
                    style={{ color: "white", fontWeight: 600 }}
                  >
                    KYC Document
                  </Typography>
                </div>
                <div
                  style={{
                    height: "70vh",
                    overflowY: "auto",
                    marginBottom: 10,
                    // backgroundColor:"white"
                  }}
                >
                  <div
                    style={{
                      borderRadius: 5,
                      border: "1px solid grey",
                      padding: 10,
                      marginTop: 10,
                    }}
                  >
                    <div
                      // key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                        //  backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          color: "white",
                        }}
                      >
                        <Typography style={{ fontWeight: 700 }}>
                          Aadhaar ID
                        </Typography>
                        <Typography>{showLead.aadhar.doc}</Typography>
                      </div>

                      <Switch onChange={handleChange} checked={checked} />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: 15,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={showLead.aadhar.front}
                        alt=""
                        width={"45%"}
                        style={{
                          marginRight: 10,
                          borderRadius: 5,
                        }}
                      />
                      <img
                        src={showLead.aadhar.front}
                        alt=""

                        width={"45%"}
                        style={{
                          marginLeft: 10,
                          borderRadius: 5,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: 5,
                      border: "1px solid grey",
                      padding: 10,
                      marginTop: 10,
                    }}
                  >
                    <div
                      // key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                        //  backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          color: "white",
                        }}
                      >
                        <Typography style={{ fontWeight: 700 }}>
                          PAN ID
                        </Typography>
                        <Typography>{showLead.pan.doc}</Typography>
                      </div>

                      <Switch onChange={handleChange} checked={checked} />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: 15,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={showLead.pan.front}
                        alt=""

                        width={"45%"}
                        style={{
                          marginRight: 10,
                          borderRadius: 5,
                        }}
                      />
                      <img
                        src={showLead.pan.front}
                        alt=""

                        width={"45%"}
                        style={{
                          marginLeft: 10,
                          borderRadius: 5,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllKYCUsers;
